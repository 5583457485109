import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  TabView,
  ItemAdder,
  InputText,
  CustomButton,
  SearchValue,
  BreadCrumb,
  TitleBar,
} from "../../../components";
import { BreadcrumbProcess, ProcessTab } from "../../../config/constants";

import "./palletInfo.scss";

const PalletInformation = () => {
  const [bulkPacking, setBulkPacking] = useState([{}]);
  const [packingDetails, setPackingDetails] = useState([{}]);
  const [palletDetails, setPalletDetails] = useState([{}]);
  const [packingMethod, setPackingMethod] = useState([{}]);

  const itemAddHandlePackingMethod = () => {
    setBulkPacking([...bulkPacking, {}]);
  };
  const itemRemoveHandlePackingMethod = (index: number) => {
    bulkPacking.splice(index, 1);
    setBulkPacking([...bulkPacking]);
  };

  const itemAddHandleNewBulk = () => {
    setPackingDetails([...packingDetails, {}]);
  };
  const itemRemoveHandleNewBulk = (index: number) => {
    packingDetails.splice(index, 1);
    setPackingDetails([...packingDetails]);
  };

  const itemAddHandleNewPallet = () => {
    setPalletDetails([...palletDetails, {}]);
  };
  const itemRemoveHandleNewPallet = (index: number) => {
    palletDetails.splice(index, 1);
    setPalletDetails([...palletDetails]);
  };

  const itemAddHandleNewPackingMethod = () => {
    setPackingMethod([...packingMethod, {}]);
  };
  const itemRemoveHandleNewPackingMethod = (index: number) => {
    packingMethod.splice(index, 1);
    setPackingMethod([...packingMethod]);
  };

  const {
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {};

  return (
    <>
      <TitleBar titleName={"Processing Pallet"} />
      <Grid>
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <SearchValue title={"Product Name"} />
        </Grid.Column>
      </Grid>

      <TabView computer={2} tabData={ProcessTab} defaultSelect={3}>
        <p className="palletTitle">Add Bulk packaging</p>

        {bulkPacking.map((item, index) => {
          return (
            <ItemAdder
              itemAddHandle={itemAddHandlePackingMethod}
              itemRemoveHandle={itemRemoveHandlePackingMethod}
              items={bulkPacking}
              currentIndex={index}
            >
              <Grid>
                <Grid.Column computer={7} tablet={7} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors.ingredientName}
                    labelName={"Unit Count"}
                    placeholder="Unit Count"
                    name="unitCount"
                  />
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors.ingredientName}
                    labelName={"Content Weight"}
                    placeholder="Content Weight"
                    name="contentWeight"
                  />
                </Grid.Column>
                <Grid.Column computer={5} tablet={5} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors.ingredientName}
                    labelName={"Total weight with packaging"}
                    placeholder="Total weight with packaging"
                    name="weightWithPacking"
                  />
                </Grid.Column>
              </Grid>
              <></>

              {packingDetails.map((item, index) => {
                return (
                  <ItemAdder
                    itemAddHandle={itemAddHandleNewBulk}
                    itemRemoveHandle={itemRemoveHandleNewBulk}
                    items={packingDetails}
                    currentIndex={index}
                    customItemAdderGridWithInputs={
                      "customItemAdderGridWithInputsPallet"
                    }
                    customItemAddButtonMain={"customItemAddButtonMainPallet"}
                  >
                    <Grid>
                      <Grid.Column
                        computer={7}
                        tablet={7}
                        mobile={16}
                        className="paddingRemoveVertical paddingRemoveLeft"
                      >
                        <SearchValue title={"Packaging Name"} />
                      </Grid.Column>
                      <Grid.Column
                        computer={4}
                        tablet={4}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Packaging Material"}
                          placeholder="Packaging Material"
                          name="packingMaterial"
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={5}
                        tablet={5}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Material  Weight"}
                          placeholder="Material  Weight"
                          name="materialWeight"
                        />
                      </Grid.Column>
                    </Grid>
                    <></>
                  </ItemAdder>
                );
              })}

              {palletDetails.map((item, index) => {
                return (
                  <ItemAdder
                    itemAddHandle={itemAddHandleNewPallet}
                    itemRemoveHandle={itemRemoveHandleNewPallet}
                    items={palletDetails}
                    currentIndex={index}
                    customItemAdderGridWithInputs={
                      "customItemAdderGridWithInputsPallet"
                    }
                    customItemAddButtonMain={"customItemAddButtonMainPallet"}
                  >
                    <Grid>
                      <Grid.Column
                        computer={7}
                        tablet={7}
                        mobile={16}
                        className="paddingRemoveVertical paddingRemoveLeft"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Pallet size"}
                          placeholder="Pallet size"
                          name="palletSize"
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={4}
                        tablet={4}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Pallet Type"}
                          placeholder="Pallet Type"
                          name="palletSize"
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={5}
                        tablet={5}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Packages on Pallet"}
                          placeholder="Packages on Pallet"
                          name="packageOnPallet"
                        />
                      </Grid.Column>
                    </Grid>
                    <></>
                  </ItemAdder>
                );
              })}
              <p className="palletSubTitle">Packaging Method</p>
              {packingMethod.map((item, index) => {
                return (
                  <ItemAdder
                    itemAddHandle={itemAddHandleNewPackingMethod}
                    itemRemoveHandle={itemRemoveHandleNewPackingMethod}
                    items={packingMethod}
                    currentIndex={index}
                    customItemAdderGridWithInputs={
                      "customItemAdderGridWithInputsPallet"
                    }
                    customItemAddButtonMain={"customItemAddButtonMainPallet"}
                  >
                    <Grid>
                      <Grid.Column
                        computer={7}
                        tablet={7}
                        mobile={16}
                        className="paddingRemoveVertical paddingRemoveLeft"
                      >
                        <SearchValue title={"Packaging Name"} />
                      </Grid.Column>
                      <Grid.Column
                        computer={4}
                        tablet={4}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Packaging Material"}
                          placeholder="Packaging Material"
                          name="packingMaterial"
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={5}
                        tablet={5}
                        mobile={16}
                        className="paddingRemoveVertical"
                      >
                        <InputText
                          register={register}
                          errors={errors.ingredientName}
                          labelName={"Material  Weight"}
                          placeholder="Material  Weight"
                          name="materialWeight"
                        />
                      </Grid.Column>
                    </Grid>
                    <></>
                  </ItemAdder>
                );
              })}
            </ItemAdder>
          );
        })}
        <div className="palletButtonView">
          <CustomButton
            theme="green"
            title={"Complete  Later"}
            customColumnStyle={"customRecipeButton"}
            type={"submit"}
          />
          <CustomButton theme="green" title={"Next"} type={"submit"} />
        </div>
      </TabView>
    </>
  );
};

export default PalletInformation;
