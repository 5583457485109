import gql from "graphql-tag";

export const GET_PROCESS_LIST = gql`
  query GetProcessesList {
    getProcessesList {
      _id
      productName
      salesUnitName
    }
  }
`;

export const GET_SINGLE_PROCESS = gql`
  query getProcess($processId: String!) {
    getProcess(processId: $processId) {
      _id
      productName
      productId
      salesUnitName
      salesUnitId
      processing {
        _id
        associateIngredeients {
          content
          ingredientId
          ingredientName
          unit
        }
        cipSip {
          chemicalConcentration
          chemicalConsumption
          chemicalUse
          energyConsumption
          frequency
          manufacturer
          modelNumber
          steamFlow
          steamPressure
        }
        capacity
        citricAcid
        electricity
        facilityName
        heat
        input
        output
        machineModelNo
        processNumber
        processingName
        processingType
        sideStream
        water
      }
      receipe {
        _id
        content
        ingredientId
        ingredientName
        prcentage
        unitSize
      }
      storage {
        _id
        areaUtilization
        electricity
        temperature
        warehouseId
        warehouseName
        warehouseSize
        weight
      }
      energyGrid {
        _id
        energyType
        kWh
        percentage
      }
      externalTransport {
        _id
        distance
        from
        to
        transportType
      }
      internalTransport {
        _id
        averageDistance
        transportType
      }
      packaging {
        _id
        meaterialWeight
        packageMaterial
        packageName
      }
      packagingMachine {
        _id
        energy
        packagingMachine
        waste
        capacity
      }
    }
  }
`;

export const UPDATE_PROCESS = gql`
  mutation UpdateProcess(
    $_id: String!
    $productId: String
    $productName: String
    $userCompletedStage: String
    $salesUnitId: String
    $salesUnitName: String
    $processing: [ProcessProcessingsDto!]
    $receipe: [ProcessReceipiesDto!]
    $process_status: String
    $packaging: [ProcessPackagingsDto!]
    $packagingMachine: [ProcessPackagingMachinesDto!]
    $storage: [ProcessStoragesDto!]
    $energyGrid: [ProcessEnergyGridsDto!]
    $internalTransport: [ProcessInternalTransportsDto!]
    $externalTransport: [ProcessExternalTransportsDto!]
  ) {
    UpdateProcess(
      UpdateProcess: {
        _id: $_id
        productId: $productId
        productName: $productName
        salesUnitId: $salesUnitId
        salesUnitName: $salesUnitName
        userCompletedStage: $userCompletedStage
        processing: $processing
        receipe: $receipe
        process_status: $process_status
        packaging: $packaging
        packagingMachine: $packagingMachine
        storage: $storage
        energyGrid: $energyGrid
        internalTransport: $internalTransport
        externalTransport: $externalTransport
      }
    ) {
      _id
      processing {
        _id
        associateIngredeients {
          content
          ingredientId
          ingredientName
          unit
        }
        cipSip {
          chemicalConcentration
          chemicalConsumption
          chemicalUse
          energyConsumption
          frequency
          manufacturer
          modelNumber
          steamFlow
          steamPressure
        }
        capacity
        citricAcid
        electricity
        facilityName
        heat
        input
        output
        machineModelNo
        processNumber
        processingName
        processingType
        sideStream
        water
      }
    }
  }
`;

export const CREATE_PROCESS = gql`
  mutation createProcess(
    $productId: String!
    $productName: String!
    $receipe: [ProcessReceipiesDto!]!
    $salesUnitId: String!
    $salesUnitName: String!
  ) {
    createProcess(
      createProcess: {
        productId: $productId
        productName: $productName
        receipe: $receipe
        salesUnitId: $salesUnitId
        salesUnitName: $salesUnitName
      }
    ) {
      _id
    }
  }
`;
