import {
 GET_CLIENT_PRODUCTS_DATA,
 GET_CLIENT_DATA_BY_ID
} from "../slicers/client";
import { apolloClient } from "../../graphql/client";
import {
 GET_CLIENT_LIST,
 CREATE_CLIENT,
 UPDATE_CLIENT, 
 GET_CLIENT_BY_ID
} from "../../graphql/clientGql/client-graphql";

import { errorView, successMessage } from "../../helpers/ErrorHandler";

export const getClientsWithProductsData = () => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_CLIENT_LIST,
    });

    const clientProductData = response?.data?.getClientList?.map((client: any) => {
        const products = client?.product?.map((productt: any) => {
            return {
                ...productt,
                clientId: client?._id,
                clientName: client?.clientName
            }
        })

        return products
    })
    const updatedClientProductData = clientProductData?.flat(1)
    dispatch(GET_CLIENT_PRODUCTS_DATA(updatedClientProductData));
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const addClient = (data: any) => async (dispatch: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CREATE_CLIENT,
        variables: data,
      });
  
      successMessage("Client added successfully");
      dispatch(getClientsWithProductsData());
    } catch ({ message }: any) {
      errorView(message);
    }
  };

  export const updateClient = (data: any) => async (dispatch: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_CLIENT,
        variables: data,
      });
  
      successMessage("Client updated successfully");
      dispatch(getClientsWithProductsData());
    } catch ({ message }: any) {
      errorView(message);
    }
  };

  export const getClientById = (id: any) => async (dispatch: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_CLIENT_BY_ID,
        variables: {
          clientId: id,
        },
      });
  
      dispatch(GET_CLIENT_DATA_BY_ID(response.data.getClient));
    } catch ({ message }: any) {
      errorView(message);
    }
  };
