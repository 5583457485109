import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProcessState {
  processData?: {
    _id: String;
    productId: String;
    salesUnitId: String;
    salesUnitName: String;
    energyGrid: [
      {
        energyType: String;
        kWh: number;
        percentage: number;
      }
    ];
    externalTransport: [
      {
        _id: String;
        distance: number;
        from: String;
        to: String;
        transportType: String;
      }
    ];
    internalTransport: [
      {
        _id: String;
        averageDistance: number;
        transportType: String;
      }
    ];
    packaging: [
      {
        meaterialWeight: number;
        packageMaterial: String;
        packageName: String;
      }
    ];
    packagingMachine: [
      {
        energy: number;
        packagingMachine: String;
        waste: number;
        capacity: number;
      }
    ];
    processing:
      | [
          {
            _id: String;
            associateIngredeients: [
              {
                content: String;
                ingredientId: String;
                ingredientName: String;
                unit: String;
              }
            ];
            capacity: number;
            cipSip: [
              {
                chemicalConcentration: number;
                chemicalConsumption: number;
                chemicalUse: number;
                energyConsumption: number;
                frequency: number;
                manufacturer: String;
                modelNumber: String;
                steamFlow: number;
                steamPressure: number;
              }
            ];
            citricAcid: String;
            electricity: String;
            facilityId: String;
            facilityName: String;
            heat: String;
            input: String;
            machineModelNo: String;
            output: String;
            processNumber: String;
            processingName: String;
            processingType: String;
            sideStream: String;
            water: String;
          }
        ];
    productName: String;
    receipe: [
      {
        _id: String;
        ingredientId: String;
        ingredientName: String;
        unitSize: 0;
        content: number;
        prcentage: number;
      }
    ];
    storage: [
      {
        _id: String;
        areaUtilization: number;
        electricity: number;
        temperature: String;
        warehouseId: String;
        warehouseName: String;
        warehouseSize: number;
        weight: number;
        storageDays: number;
      }
    ];
  };
  processList?: [];
  addedProcessData?: any;
  processDetails?: any;
  singleProcess?: any;
}

const initialState: ProcessState = {
  processData: {
    _id: "",
    productId: "",
    salesUnitId: "",
    salesUnitName: "",
    energyGrid: [
      {
        energyType: "",
        kWh: 0,
        percentage: 0,
      },
    ],
    externalTransport: [
      {
        _id: "",
        distance: 0,
        from: "",
        to: "",
        transportType: "",
      },
    ],
    internalTransport: [
      {
        _id: "",
        averageDistance: 0,
        transportType: "",
      },
    ],
    packaging: [
      {
        meaterialWeight: 0,
        packageMaterial: "",
        packageName: "",
      },
    ],
    packagingMachine: [
      {
        energy: 0,
        packagingMachine: "",
        waste: 0,
        capacity: 0,
      },
    ],
    processing: [
      {
        _id: "",
        associateIngredeients: [
          {
            content: "",
            ingredientId: "",
            ingredientName: "",
            unit: "",
          },
        ],
        capacity: 0,
        cipSip: [
          {
            chemicalConcentration:0,
            chemicalConsumption:0,
            chemicalUse:0,
            energyConsumption:0,
            frequency:0,
            manufacturer:"",
            modelNumber: "",
            steamFlow:0,
            steamPressure:0,
          },
        ],
        citricAcid: "",
        electricity: "",
        facilityId: "",
        facilityName: "",
        heat: "",
        input: "",
        machineModelNo: "",
        output: "",
        processNumber: "",
        processingName: "",
        processingType: "",
        sideStream: "",
        water: "",
      },
    ],
    productName: "",
    receipe: [
      {
        _id: "",
        ingredientId: "",
        ingredientName: "",
        unitSize: 0,
        content: 0,
        prcentage: 0,
      },
    ],
    storage: [
      {
        _id: "",
        areaUtilization: 0,
        electricity: 0,
        temperature: "",
        warehouseId: "",
        warehouseName: "",
        warehouseSize: 0,
        weight: 0,
        storageDays: 0
      },
    ],
  },

  addedProcessData: [],
  processList: [],
  singleProcess: [],
};

export const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    RESET: () => initialState,
    PROCESS_LIST_DATA: (state, action) => {
      state.processList = action.payload;
    },
    GET_SINGLE_PROCESS_DATA: (state, action) => {
      state.processData = action.payload;
    },
    GET_A_PROCESS: (state, action) => {
      state.singleProcess = action.payload;
    },
    SET_ADDED_PROCESS_DATA: (state, action) => {
      const newData = [...state.addedProcessData, action.payload];
      state.addedProcessData = newData;
    },
    SET_PROCESS_DETAILS: (state, action) => {
      state.processDetails = action.payload;
    },
  },
});

export const {
  RESET,
  GET_SINGLE_PROCESS_DATA,
  SET_ADDED_PROCESS_DATA,
  SET_PROCESS_DETAILS,
  PROCESS_LIST_DATA,
  GET_A_PROCESS,
} = processSlice.actions;
export default processSlice.reducer;
