import { AUTH_TOKEN, LOG_OUT } from "../slicers/auth";
import { apolloClient } from "../../graphql/client";
import { DASHBOARD_LINK, USER_LOGIN } from "../../graphql/auth/auth";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import { saveTokenInLocal } from "../../utils/cacheStorage";
import { removeTokens } from "../../utils/cacheStorage";

export const logout = (navigate?: any) => (dispatch: any) => {
  removeTokens();
  navigate("/auth");
  dispatch(LOG_OUT());
};

export const userLogin = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: USER_LOGIN,
      variables: {
        email: data.userName,
        password: data.password,
      },
    });
    saveTokenInLocal(response.data.UserLogin.AuthenticationResult.IdToken);
    dispatch({
      type: AUTH_TOKEN,
      payload: response.data.UserLogin.AuthenticationResult.IdToken,
    });
    return true;
  } catch ({ message }: any) {
    errorView(message);
    return false;
  }
};

export const getDashboardUrl = () => async (dispatch: any) => {
  let idToken = await localStorage.getItem("IdToken");
  try {
    const response = await apolloClient.query({
      query: DASHBOARD_LINK,
      variables: {
        idToken,
      },
    });
    return response?.data?.getQSDashboardLink?.url;
  } catch ({ message }: any) {
    errorView(message);
    return false;
  }
};
