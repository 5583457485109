import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";

import {
  TabView,
  InputText,
  CustomButton,
  SearchValue,
  ItemAdder,
  TitleBar,
} from "../../../components";
import { ProcessTab } from "../../../config/constants";

import "./storage.scss";
import { isEmpty } from "lodash";
import { useAppDispatch } from "../../../store/hooks";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const Storage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { processData } = useSelector((state: RootState) => state.process);

  useEffect(() => {
    dispatch(getProcessDataById(id));
  }, []);

  let defaultWareHouseDetails = {
    areaUtilization: 0,
    electricity: 0,
    warehouseName: "",
    warehouseSize: 0,
    weight: 0,
    temperature: "",
    storageDays: 0
  };

  const values = useMemo(() => {
    return {
      wareHouse: [...(processData?.storage || []), defaultWareHouseDetails],
      productName: `${processData?.productName} - ${processData?.salesUnitName}`,
    };
  }, [processData]);

  const {
    register,
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: wareHouseFields,
    append: wareHouseAppend,
    remove: wareHouseRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "wareHouse" });

  const warehouseItemAdd = (index: any) => {
    let checkWarehouseName =
      getValues().wareHouse?.[index]?.warehouseName || "";
    if (isEmpty(checkWarehouseName)) {
      setError(`wareHouse.${index}.warehouseName`, {});
    } else {
      wareHouseAppend(defaultWareHouseDetails);
    }
  };

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/process/energy/${processData?._id}`);
      default:
        return navigate(`/process`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();
      let tempWareHouse = getValueData.wareHouse;
      let tempWareHouseIndex = tempWareHouse.length - 1

      if(tempWareHouse?.[tempWareHouseIndex]?.warehouseName === '') {
        tempWareHouse.splice(tempWareHouseIndex, 1);
      }

      let storageData = {
        _id: processData?._id,
        storage: tempWareHouse,
        userCompletedStage: "STORAGE",
      };
      dispatch(updateProcessData(storageData, "Storage")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  return (
    <>
      <TitleBar titleName={"Processing Storage"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>
      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={ProcessTab}
          defaultSelect={3}
          addParams={true}
          paramsData={processData?._id}
        >
          {wareHouseFields.map((item, index) => {
            return (
              <ItemAdder
                itemAddHandle={() => warehouseItemAdd(index)}
                itemRemoveHandle={wareHouseRemove}
                items={wareHouseFields}
                currentIndex={index}
                customItemAdderGridWithInputs={
                  "customItemAdderGridWithInputsPacking"
                }
              >
                <Grid className="storageMain">
                  <Grid.Column
                    computer={5}
                    tablet={2}
                    mobile={16}
                    className="paddingRemoveLeft paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      errors={errors.wareHouse?.[index]?.warehouseName}
                      labelName={"Warehouse Name"}
                      placeholder="Warehouse Name"
                      name={`wareHouse[${index}].warehouseName`}
                      required={true}
                      customInputValidation={true}
                      onChangeFunction={(e: any) => {
                        e.target.value.length > 0
                          ? clearErrors(`wareHouse.${index}.warehouseName`)
                          : setError(`wareHouse.${index}.warehouseName`, {});
                      }}
                      errorMessage={"Warehouse name is required"}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={2}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      errors={errors.wareHouse?.[index]?.temperature}
                      labelName={"Storage Temperature (℃)"}
                      placeholder="Storage Temperature (℃)"
                      name={`wareHouse[${index}].temperature`}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={3}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      labelName={"Warehouse size (m3)"}
                      placeholder="Warehouse size (m3)"
                      name={`wareHouse[${index}].warehouseSize`}
                      type="number"
                      valueAsNumber={true}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={3}
                    mobile={16}
                    className="paddingRemoveLeft paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      labelName={"Weight (kg)"}
                      placeholder="Weight (kg)"
                      name={`wareHouse[${index}].weight`}
                      type="number"
                      valueAsNumber={true}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={3}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      labelName={"Electricity(kWh)"}
                      placeholder="Electricity(kWh)"
                      name={`wareHouse[${index}].electricity`}
                      type="number"
                      valueAsNumber={true}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={3}
                    mobile={16}
                    className="paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      labelName={"Utilization of warehouse %"}
                      placeholder="Utilization of warehouse %"
                      name={`wareHouse[${index}].areaUtilization`}
                      type="number"
                      valueAsNumber={true}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={3}
                    mobile={16}
                    className="paddingRemoveLeft paddingRemoveVertical"
                  >
                    <InputText
                      register={register}
                      labelName={"Storage day(s)"}
                      placeholder="Storage days (day(s))"
                      name={`wareHouse[${index}].storageDays`}
                      type="number"
                      valueAsNumber={true}
                      customInputValidation={true}
                    />
                  </Grid.Column>
                </Grid>
                {index !== (wareHouseFields.length - 1) ? <div className="devider"></div> : <></>}
              </ItemAdder>
            );
          })}

          <div className="storageButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customREnegryButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("late");
              }}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              type={"submit"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Storage;
