import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CompanyState {
  company: any;
  companyDetails?: any;
}

const initialState: CompanyState = {
  companyDetails: null,
  company: [],
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    LOG_OUT: () => initialState,
    SET_COMPANY_DATA: (state, action) => {
      state.company = action.payload;
    },
    SET_COMPANY_DATA_BY_ID: (state, action) => {
      state.companyDetails = action.payload;
    },
  },
});

export const { LOG_OUT, SET_COMPANY_DATA, SET_COMPANY_DATA_BY_ID } =
  company.actions;
export default company.reducer;
