import React, { useState, useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import {
  MainContentLayout,
  TabView,
  CommonTable,
  CustomButton,
  SearchValue,
  TitleView,
  InputText,
  TitleBar,
} from "../../../components";
import {
  EnergyTable,
  Processing,
  ProcessRecipe,
  ProcessTab,
  Packaging,
  Storage,
  Transportation,
  Energy,
} from "../../../config/constants";
import { useForm } from "react-hook-form";

import "./confirmation.scss";
import { useSelector } from "react-redux";
import { getProcessDataById } from "../../../store/actions/process";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import CultivationConfirmModal from "../../cultivation/cultivationConfirmation/cultivationConfirmation";
import { useProcessCalculateData } from "../../../api/process";

const Confirmation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  let { processData } = useSelector((state: RootState) => state.process);

  const { mutate } = useProcessCalculateData();

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getProcessDataById(id));
    setValue("productName", `${processData?.productName} - ${processData?.salesUnitName}` || "");
  }, []);

  const loadProductRecipeData = () => {
    return processData?.receipe.map((data: any, index) => {
      console.log(data);
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.ingredientName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.prcentage}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.unitSize}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadProcessingData = () => {
    return processData?.processing.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.processingType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.input}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.output}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.capacity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadPackagingData = () => {
    return processData?.packaging.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.meaterialWeight}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.packageMaterial}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadStorageData = () => {
    return processData?.storage.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseName}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.warehouseSize}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.temperature}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.electricity}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadEnergyData = () => {
    return processData?.energyGrid.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.energyType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.kWh}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.percentage}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTransportData = () => {
    return processData?.internalTransport.map((data, index: any) => {
      return (
        <Table.Row key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.transportType}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data?.averageDistance}</p>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar titleName={"Processing Impact"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={ProcessTab}
          defaultSelect={6}
          addParams={true}
          paramsData={processData?._id}
        >
          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.receipe ? (
              <>
                <TitleView
                  CustomTitleViewMain="packingMn"
                  title="Product Recipe"
                />
                <Grid>
                  <Grid.Column computer={9} tablet={8} mobile={16}>
                    <CommonTable tableHeaderData={ProcessRecipe}>
                      {loadProductRecipeData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/recipe/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>
          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.processing ? (
              <>
                <TitleView CustomTitleViewMain="packingMn" title="Processing" />

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Processing}>
                      {loadProcessingData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/processing/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>
          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.packaging ? (
              <>
                <TitleView CustomTitleViewMain="packingMn" title="Packing" />

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Packaging}>
                      {loadPackagingData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/packing/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>

          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.internalTransport ? (
              <>
                <TitleView
                  CustomTitleViewMain="packingMn"
                  title="Transport Packaging"
                />

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Transportation}>
                      {loadTransportData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/transportation/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>

          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.energyGrid ? (
              <>
                <TitleView CustomTitleViewMain="packingMn" title="Energy" />

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Energy}>
                      {loadEnergyData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/energy/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>

          <Grid.Column computer={16} tablet={8} mobile={16}>
            {processData?.storage ? (
              <>
                <TitleView CustomTitleViewMain="packingMn" title="Storage" />

                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    mobile={16}
                    className="processDetailsTableView"
                  >
                    <CommonTable tableHeaderData={Storage}>
                      {loadStorageData()}
                    </CommonTable>
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={8} mobile={16}>
                    <CustomButton
                      title="Edit"
                      onClick={() => {
                        navigate(`/process/storage/${processData?._id}`);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </>
            ) : null}
          </Grid.Column>
          <div className="confirmationButtonView">
            <CustomButton
              theme="green"
              title={"Complete"}
              customColumnStyle={"confirmationButton"}
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        </TabView>
      </Grid>
      <CultivationConfirmModal
        viewModal={showModal}
        closeModal={() => setShowModal(false)}
        check={() => {
          setShowModal(false);
        }}
        calculation={() => {
          mutate(processData?._id)
          setShowModal(false);
        }}
        title="Confirm"
        subTitle="Are you sure all details have been included and are correct?"
      />
    </>
  );
};

export default Confirmation;
