import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import {
  TabView,
  InputText,
  CustomButton,
  SearchValue,
  Switch,
  TitleBar,
  DropDown,
} from "../../../components";
import { CultivationTab } from "../../../config/constants";

import "./Cultivar.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllIngredient } from "../../../store/actions/ingredient";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  createCultivation,
  getCultivationDataById,
  resetCultivationData,
  updateCultivationData,
  checkIngredientCultivationData,
} from "../../../store/actions/cultivation";
import { OriginCountries } from "../../../config/originCountryData";
import { useGetIngredientsWithSupplier } from "../../../api/ingredients";
import { productListData } from "../../../config/productListData";

interface HandleValidation {
  checkValidation(): void;
}

const Cultivar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [selectCountry, setSelectCountry] = useState<any>("");
  const [ing, setIng] = useState<any>(null);
  const [sup, setSup] = useState<any>(null);
  const [dataSet, setDataSet] = useState<any>({
    ingredientId: "",
    ingredientName: "",
    supplierId: "",
    supplierName: "",
    farmingType: "Conventional",
    farmingField: "Open Field",
    produceCultivar: "",
    originCountry: "",
    produceType: "",
  });

  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );
  const validIngredient = useRef<HandleValidation>(null);

  const [farmingType, setFarmingType] = useState(
    cultivationData?.farmingType || "Conventional"
  );
  const [farmingField, setFarmingField] = useState(
    cultivationData?.farmingField || "Open Field"
  );
  const [ingSupDetails, setIngSupDetails] = useState([]);
  const [ingredientId, setIngredientId] = useState<any>();

  const {
    data: ingredientListWithSupplier,
    isLoading: isIngredientListWithSupplierLoading,
  } = useGetIngredientsWithSupplier();

  useEffect(() => {
    setIngSupDetails(ingredientListWithSupplier);
  }, [ingredientListWithSupplier]);

  const values = useMemo(() => {
    setSelectCountry(cultivationData?.originCountry);

    return {
      ingredientId: cultivationData?.ingredientId || "",
      ingredientName: cultivationData?.ingredientName || "",
      supplierId: cultivationData?.supplierId || "",
      supplierName: cultivationData?.supplierName || "",
      farmingType: cultivationData?.farmingType || "Conventional",
      farmingField: cultivationData?.farmingField || "Open Field",
      produceCultivar: cultivationData?.produceCultivar || "",
      originCountry: cultivationData?.originCountry || "",
      produceType: cultivationData?.produceType || "",
    };
  }, [cultivationData]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: id != "new" ? dataSet : values,
    values: values,
  });

  useEffect(() => {
    reset();
    dispatch(getAllIngredient());
    if (id != "new") {
      dispatch(getCultivationDataById(id)).then((res) => {
        setIngredientId({
          ingredient_id: res?.data?.getCultivationById.ingredientId ?? "",
          ingredient_name: res?.data?.getCultivationById.ingredientName ?? "",
          supplier_id: res?.data?.getCultivationById.supplierId ?? "",
          supplier_name: res?.data?.getCultivationById.supplierName ?? "",
        });
        setDataSet({
          ingredientId: res?.data?.getCultivationById.ingredientId || "",
          ingredientName: res?.data?.getCultivationById.ingredientName || "",
          supplierId: res?.data?.getCultivationById.supplierId || "",
          supplierName: res?.data?.getCultivationById.supplierName || "",
          farmingType:
            res?.data?.getCultivationById.farmingType || "Conventional",
          farmingField:
            res?.data?.getCultivationById.farmingField || "Open Field",
          produceCultivar: res?.data?.getCultivationById.produceCultivar || "",
          originCountry: res?.data?.getCultivationById.originCountry || "",
          produceType: res?.data?.getCultivationById.produceType || "",
        });
      });
    } else {
      dispatch(resetCultivationData());
      reset();
    }
    return () => {
      reset();
    };
  }, [id]);

  const navigationOption = (status: any, id?: any) => {
    switch (status) {
      case "next":
        return navigate(`/cultivation/field/${id}`);
      default:
        return navigate(`/cultivation`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    let getValueData = getValues();
    if (isEmpty(getValueData.ingredientId)) {
      validIngredient.current?.checkValidation();
    } else {
      if (id == "new") {
        dispatch(createCultivation(getValueData)).then((res) => {
          navigationOption(navigateStatus, res?._id);
        });
      } else {
        let customCultivationData = {
          ...getValueData,
          userCompletedStage: "CULTIVAR",
          _id: cultivationData?._id,
        };
        dispatch(updateCultivationData(customCultivationData, "CULTIVAR")).then(
          (res) => {
            navigationOption(navigateStatus, res?._id);
          }
        );
      }
    }
  };

  const getSearchProduceTypeValue = (data: any) => {
    setValue("produceType", data?.result?.other?.id);
  };

  const getSearchProductValue = (data: any) => {
    setIng(data?.ingredient_name);
    setSup(data?.supplier_name);
    setValue("ingredientId", data?.ingredient_id);
    setValue("ingredientName", data?.ingredient_name);
    setValue("supplierId", data?.supplier_id);
    setValue("supplierName", data?.supplier_name);

    dispatch(
      checkIngredientCultivationData(data?.ingredient_id, data?.supplier_id)
    ).then((res) => {
      if (res?.data?.getCultivationByIngredientId?._id) {
        navigate(
          `/cultivation/cultivar/${res?.data?.getCultivationByIngredientId?._id}`
        );

        dispatch(
          getCultivationDataById(res?.data?.getCultivationByIngredientId?._id)
        );
      } else {
        navigate(`/cultivation/cultivar/new`);
      }
    });
  };
  return (
    <>
      <TitleBar titleName={"Cultivation Register"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <DropDown
            labelName="Ingredient Name*"
            placeholder="Select Ingredient"
            currentData={ingSupDetails}
            defaultValue={ingredientId}
            handleChangeState={(e, data: any) => {
              setIngredientId(data?.value);
              getSearchProductValue(data?.value);
            }}
            onSearchChange={(e, data: any) => {
              const filteredArray = ingredientListWithSupplier?.filter(
                (ingred: any) =>
                  ingred?.text
                    ?.toLowerCase()
                    .includes(data.searchQuery?.toLowerCase())
              );
              setIngSupDetails(filteredArray);
            }}
            loading={isIngredientListWithSupplierLoading}
            search
          />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={CultivationTab}
          defaultSelect={0}
          addParams={true}
          paramsData={cultivationData?._id}
          disable={id == "new" ? true : false}
        >
          <Grid.Column
            computer={16}
            tablet={16}
            mobile={16}
            className="addCultivarIngredientMain"
          >
            <Grid>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <SearchValue
                    searchDataValues={productListData}
                    title={"Produce Type"}
                    checkOther={(status: any) => {}}
                    defaultValue={
                      id != "new" ? dataSet?.produceType : values?.produceType
                    }
                    selectDetails={getSearchProduceTypeValue}
                  />
                </Grid.Column>
                <Grid.Column
                  style={{ marginTop: "15px" }}
                  computer={16}
                  tablet={16}
                  mobile={16}
                >
                  <InputText
                    register={register}
                    labelName={"Produce Cultivar"}
                    placeholder="Produce Cultivar"
                    name="produceCultivar"
                  />
                </Grid.Column>
                <Grid.Column
                  style={{ marginTop: "15px" }}
                  computer={16}
                  tablet={16}
                  mobile={16}
                >
                  <DropDown
                    labelName={"Origin Country"}
                    placeholder="Select origin country"
                    currentData={OriginCountries}
                    defaultValue={selectCountry}
                    handleChangeState={(e: any, { value }: any) => {
                      setValue("originCountry", value);
                      setSelectCountry(value);
                    }}
                    customGridColumn={"customGridColomnFilter"}
                  />
                </Grid.Column>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <div className="switchsMain">
                  <Grid.Column
                    style={{ marginBottom: "25px" }}
                    computer={16}
                    tablet={16}
                    mobile={16}
                  >
                    <label className="switchLabel">Farming Type</label>
                    <Switch
                      onClick={(data: any) => {
                        setValue("farmingType", data);
                        setFarmingType(data);
                      }}
                      titleOne={"Organic"}
                      titleTwo={"Conventional"}
                      titleOneValue={"Organic"}
                      titleTwoValue={"Conventional"}
                      defaultValue={farmingType}
                    />
                  </Grid.Column>

                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    <label className="switchLabel">Field Type</label>
                    <Switch
                      onClick={(data: any) => {
                        setValue("farmingField", data);
                        setFarmingField(data);
                      }}
                      titleOne={"Open field"}
                      titleTwo={"Greenhouse"}
                      titleOneValue={"Open Field"}
                      titleTwoValue={"Green House"}
                      defaultValue={farmingField}
                    />
                  </Grid.Column>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <div className="cultivarButton">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customCultivarButton"}
              onClick={() => {
                onSubmit("late");
              }}
              buttonOutLine={true}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Cultivar;
