import React from "react";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { MENU_SIDE_BAR } from "../../config/constants";
import { ImageView } from "../ImageView/ImageView";
import Logout from "../../assets/images/logout.png";
import { CommonModal } from "../commonModal/CommonModal";
import useWindowDimensions from "../../hooks/windowsSize";

import "./menuBar.scss";
import PermissionsGate from "../PermissionGate";
import { SCOPES } from "../../config/permission-maps";

const ModalMenuBar = ({ visibleModal, setVisibleModal = () => {} }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  var currentPath = location.pathname;
  let mainPath = currentPath.substring(0, currentPath.lastIndexOf("/"));

  let currentAvailablePath = isEmpty(mainPath) ? currentPath : mainPath;
  const { height } = useWindowDimensions();
  return (
    <CommonModal
      modalOpenState={visibleModal}
      modalCloseState={() => setVisibleModal(false)}
      centered={true}
      size="mini"
      customDimmer="customDimmer"
    >
      <div
        style={{
          height: height - 70,
        }}
      >
        <div
          className="mainMenuView"
          style={{
            height: height,
          }}
        >
          {MENU_SIDE_BAR.map((data, mainIndex) => {
            return (
              <div key={mainIndex} className="mainBarView">
                {data.subTitle.map((sub, subIndex) => {
                  return (
                    <PermissionsGate scopes={[SCOPES.canAccessInSideMenu]} feature={sub.name}>
                    <div
                      key={subIndex}
                      className="sideBarIcon"
                      onClick={() => navigate(sub.path)}
                    >
                      <div className="sideBarIconView">
                        <ImageView
                          addRandomId={false}
                          src={
                            currentAvailablePath == sub.path
                              ? sub.activeIcon
                              : sub.icon
                          }
                        />{" "}
                      </div>
                      <div className="sideBarIconText">
                        <p
                          className={
                            currentAvailablePath == sub.path
                              ? "menuTitleViewSelect"
                              : "menuTitleView"
                          }
                        >
                          {sub.titleName}
                        </p>{" "}
                      </div>
                    </div>
                    </PermissionsGate>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </CommonModal>
  );
};

export default ModalMenuBar;
