import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";

import {
  TabView,
  TitleView,
  InputText,
  CustomButton,
  DropDown,
  EmphasizeText,
  TitleBar,
} from "../../../components";
import {
  PROCESSING_TYPES,
  SipContents,
  CultivationTab,
} from "../../../config/constants";
import SipRegisterModal from "./SipContentModal";
import AddIngredientsModal from "./IngredientContentModal";

import "./Processing.scss";
import {
  getCultivationDataById,
  updateCultivationData,
} from "../../../store/actions/cultivation";
import { getIngredientById } from "../../../store/actions/ingredient";
import { isEmpty } from "lodash";
import { errorView } from "../../../helpers/ErrorHandler";

const Processing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewIngredient, setViewIngredient] = useState(false);
  const [viewCipSip, setViewCipSip] = useState(false);
  const [processNumber, setProcessNumber] = useState<any>(0);

  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );

  let defaultProcess = {
    facilityName: "",
    processingName: "",
    machineModelNo: "",
    capacity: 0,
    input: 0,
    output: 0,
    sideStream: 0,
    heat: 0,
    electricity: 0,
    water: 0,
    citricAcid: 0,
    associateIngredeient: [],
    cipSip: [],
    processNumber: 1,
    associateProcess: [],
  };

  const values = useMemo(() => {
    return {
      cultivationDetails: cultivationData?.processing || [defaultProcess],
      ingredientName: cultivationData?.ingredientName,
    };
  }, [cultivationData?.processing, id]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: cultivationField,
    append: processFieldsAppend,
    remove: processFieldsRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "cultivationDetails" });

  useEffect(() => {
    dispatch(getCultivationDataById(id));
  }, [cultivationData?._id]);

  const loadIngredientData = () => {
    let getIngredientData = [
      ...(cultivationField[processNumber]?.associateIngredeient || []),
    ];
    if (getIngredientData.length > 0) {
      return getIngredientData;
    } else {
      let tempIngredient = [
        {
          ingredientName: cultivationData?.ingredientName,
          content: 0,
          unit: "",
          ingredientId: cultivationData?.ingredientId,
        },
      ];

      return tempIngredient;
    }
  };

  const loadCIPData = () => {
    let currentProcessCIP = [...(cultivationField[processNumber].cipSip || [])];
    if (currentProcessCIP.length > 0) {
      let dataObject = currentProcessCIP[0];
      let currentCipData = Object.entries(dataObject).reduce(function (
        acc: any,
        obj: any
      ) {
        let getFindData = SipContents.find((data) => data.typeName == obj[0]);
        acc.push({
          typeName: obj[0],
          value: obj[1],
          unit: getFindData?.unit,
          name: getFindData?.name,
        });
        return acc;
      },
      []);
      return currentCipData;
    } else {
      return SipContents;
    }
  };

  const loadProcessData = () => {
    let tempProcessData = [...(cultivationData?.processing || [])];

    let tempProcess = tempProcessData.map((obj: any) => {
      return {
        name: obj.processingName,
        content: 0,
        unit: "",
      };
    });

    if (cultivationData?.processing || [].length > 0) {
      return tempProcess;
    } else {
      return tempProcess;
    }
  };

  const onSubmit = (data: any, status?: any) => {
    let processData = data.cultivationDetails;

    const dataToCreate = {
      _id: id,
      ingredientId: cultivationData?.ingredientId,
      ingredientName: cultivationData?.ingredientName,
      userCompletedStage: "PROCESSING",
      processing: processData,
    };

    let lastProcessData = processData[processData.length - 1];
    let checkProcessName = isEmpty(lastProcessData.processingName.trim());
    let checkFacilityName = isEmpty(lastProcessData.facilityName.trim());

    if ((checkProcessName || checkFacilityName) && status == "CompleteLater") {
      errorView("Please fill process data");
    } else {
      dispatch(updateCultivationData(dataToCreate, "process")).then((res) => {
        if (status == "new") {
          processFieldsAppend({
            ...defaultProcess,
            processNumber: cultivationField.length + 1,
          });
          setProcessNumber(cultivationField.length);
        } else if (status == "CompleteLater") {
          navigate("/cultivation");
        }
      });
    }
  };

  const getRequiredValue = () => {
    let getFacilityName =
      getValues().cultivationDetails?.[processNumber].facilityName;
    let getProcessingName =
      getValues().cultivationDetails?.[processNumber].processingName;

    return { getFacilityName, getProcessingName };
  };

  return (
    <>
      <>
        <TitleBar titleName={"Cultivation Processing"} />
        <Grid className="backgroundMain">
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <InputText
              register={register}
              labelName={"Ingredient Name"}
              placeholder="Ingredient Name"
              name={`ingredientName`}
              disabled={true}
            />
          </Grid.Column>
        </Grid>

        <Grid className="tabbMain">
          <TabView
            computer={2}
            tabData={CultivationTab}
            defaultSelect={2}
            addParams={true}
            paramsData={cultivationData?._id}
          >
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <EmphasizeText>
                Please follow your exact production process flow
              </EmphasizeText>
            </Grid.Column>
            <Grid style={{ width: "100%" }}>
              <Grid.Column computer={3}>
                <CustomButton
                  theme="green"
                  title="Add New Process"
                  onClick={handleSubmit((data) => {
                    onSubmit(data, "new");
                  })}
                  customColumnStyle="topButtonView customProcessAdd"
                  iconWithText={true}
                  iconName="plus"
                  buttonOutLine={true}
                />
              </Grid.Column>
            </Grid>
            {cultivationField.map((data: any, index: any) => {
              return (
                <Grid.Column computer={2} tablet={3} mobile={8}>
                  <div
                    className={
                      processNumber === index
                        ? "tabViewMainActive"
                        : "tabViewMainInactive"
                    }
                    onClick={() => {
                      setProcessNumber(index);
                    }}
                  >
                    {!isEmpty(data.processingName)
                      ? data.processingName
                      : `Process ${index + 1}`}
                  </div>
                </Grid.Column>
              );
            })}

            <div className="newBack">
              <Grid.Column computer={16} tablet={8} mobile={16}>
                <TitleView
                  CustomTitleViewMain="packingMn"
                  title={
                    cultivationField[processNumber]?.processingName ||
                    `Process ${cultivationField.length}`
                  }
                />
                {cultivationField.map((data: any, index: any) => {
                  let getProcessType = data?.processingType;
                  return processNumber == index ? (
                    <Grid>
                      <Grid.Column computer={4} tablet={7} mobile={16}>
                        <InputText
                          register={register}
                          errors={
                            errors.cultivationDetails?.[processNumber]
                              ?.facilityName
                          }
                          labelName={"Processing Facility"}
                          placeholder="Processing Facility"
                          name={`cultivationDetails.${processNumber}.facilityName`}
                          required={true}
                          errorMessage={"Please enter processing facility"}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={16}>
                        <InputText
                          register={register}
                          errors={
                            errors.cultivationDetails?.[processNumber]
                              ?.processingName
                          }
                          labelName={"Processing Name"}
                          placeholder="Processing Name"
                          name={`cultivationDetails.${processNumber}.processingName`}
                          required={true}
                          errorMessage={"Please enter processing Name"}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <DropDown
                          labelName={"Processing Type"}
                          placeholder="Type"
                          currentData={PROCESSING_TYPES}
                          defaultValue={getProcessType}
                          customGridColumn={"customGridColomnTyp"}
                          handleChangeState={(e: any, { value }: any) => {
                            let { getProcessingName, getFacilityName } =
                              getRequiredValue();
                            let getTempData = data;
                            delete getTempData.id;
                            updateValue(index, {
                              ...getTempData,
                              processingType: value,
                              processingName: getProcessingName,
                              facilityName: getFacilityName,
                            });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={16}>
                        <label className={`inputTextLable`}>
                          Associate ingredients
                        </label>
                        <CustomButton
                          customColumnStyle="processingCustomColumnStyle"
                          customButtonStyle="customBtn"
                          theme="green"
                          title={`+ ${"  "}Add Ingredients`}
                          onClick={() => setViewIngredient(true)}
                        />
                      </Grid.Column>

                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Machine Model Number"}
                          placeholder="Machine Model Number"
                          name={`cultivationDetails[${processNumber}].machineModelNo`}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Capacity(kg/hr)"}
                          placeholder="Capacity(kg/hr)"
                          name={`cultivationDetails[${processNumber}].capacity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Input(kg)"}
                          placeholder="Input(kg)"
                          name={`cultivationDetails[${processNumber}].input`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Output(kg)"}
                          placeholder="Output(kg)"
                          name={`cultivationDetails[${processNumber}].output`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Waste/Side Stream (kg)"}
                          placeholder="Waste/Side Stream per kg"
                          name={`cultivationDetails[${processNumber}].sideStream`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Heat(MJ/kg)"}
                          placeholder="Heat(MJ/kg)"
                          name={`cultivationDetails[${processNumber}].heat`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Electricity(kWh/kg)"}
                          placeholder="Electricity(kWh/kg)"
                          name={`cultivationDetails[${processNumber}].electricity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Water(m3)"}
                          placeholder="Water(m3)"
                          name={`cultivationDetails[${processNumber}].water`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Citric Acid(kg/kg)"}
                          placeholder="Citric Acid(kg/kg)"
                          name={`cultivationDetails[${processNumber}].citricAcid`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={16} mobile={16}>
                        <CustomButton
                          customColumnStyle="processingCustomColumnStyle"
                          theme="green"
                          title="+ CIP / SIP"
                          customButtonStyle="customBtnCIP"
                          onClick={() => setViewCipSip(true)}
                        />
                      </Grid.Column>
                    </Grid>
                  ) : null;
                })}
              </Grid.Column>
            </div>
            <div className="processButtonView">
              <CustomButton
                theme="green"
                title={"Complete  Later"}
                customColumnStyle={"customRecipeButton"}
                type={"submit"}
                onClick={handleSubmit((data) => {
                  onSubmit(data, "CompleteLater");
                })}
                buttonOutLine={true}
              />
              <CustomButton
                theme="green"
                title={"Next"}
                type={"submit"}
                onClick={handleSubmit((data) => {
                  onSubmit(data);
                  navigate(`/cultivation/packaging/${cultivationData?._id}`);
                })}
              />
            </div>
          </TabView>
        </Grid>
      </>
      <AddIngredientsModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
        getIngredientData={(data: any) => {
          let { getProcessingName, getFacilityName } = getRequiredValue();
          let tempCurrentValue: any = { ...cultivationField[processNumber] };
          delete tempCurrentValue?.id;
          updateValue(processNumber, {
            ...tempCurrentValue,
            associateIngredeient: data?.ingredientData,
            processingName: getProcessingName,
            facilityName: getFacilityName,
          });
        }}
        activeProcessData={loadProcessData()}
        ingredientData={loadIngredientData()}
      />
      <SipRegisterModal
        visibleModal={viewCipSip}
        setVisibleModal={() => {
          setViewCipSip(false);
        }}
        getSipData={(data: any) => {
          let { getProcessingName, getFacilityName } = getRequiredValue();
          let tempCurrentValue: any = { ...cultivationField[processNumber] };
          delete tempCurrentValue?.id;
          updateValue(processNumber, {
            ...tempCurrentValue,
            cipSip: data,
            processingName: getProcessingName,
            facilityName: getFacilityName,
          });
        }}
        processCIPData={loadCIPData()}
      />
    </>
  );
};

export default Processing;
