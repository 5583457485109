import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFieldArray, useForm } from "react-hook-form";
import { createProcess } from "../../../store/actions/process";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { getProcessDataById } from "../../../store/actions/process";
//import { getProductById } from "../../../store/actions/product";
import {
  TabView,
  TitleView,
  InputText,
  CustomButton,
  DropDown,
  EmphasizeText,
  TitleBar,
} from "../../../components";
import {
  ProcessTab,
  PROCESSING_TYPES,
  SipContents,
} from "../../../config/constants";
import SipRegisterModal from "./SipContentModal";
import AddIngredientsModal from "./IngredientContentModal";

import "./processing.scss";
import { isEmpty } from "lodash";
import { errorView } from "../../../helpers/ErrorHandler";
import { useGetProductIngredient } from "../../../api/ingredients";

const Processing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewIngredient, setViewIngredient] = useState(false);
  const [viewCipSip, setViewCipSip] = useState(false);
  const [processNumber, setProcessNumber] = useState<any>(0);
  const [productId, setPro] = useState<any>(0);

  let { processData } = useSelector((state: RootState) => state.process);
  let { productDetails } = useSelector((state: RootState) => state.product);

  const {
    data: productIngredientData,
    status,
    refetch: fetchIngredientData,
  } = useGetProductIngredient(processData?.productId);

  let defaultProcess = {
    facilityName: "",
    processingName: "",
    machineModelNo: "",
    capacity: 0,
    input: 0,
    output: 0,
    sideStream: 0,
    heat: 0,
    electricity: 0,
    water: 0,
    citricAcid: 0,
    associateIngredeients: [],
    cipSip: [],
    processNumber: 1,
  };

  const values = useMemo(() => {
    return {
      processDetails: processData?.processing || [defaultProcess],
      productName: `${processData?.productName} - ${processData?.salesUnitName}`,
    };
  }, [processData?.processing, id]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: processFields,
    append: processFieldsAppend,
    remove: processFieldsRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "processDetails" });

 useEffect(() => {
    dispatch(getProcessDataById(id)).then((res: any) => {
      fetchIngredientData(res?.productId)
    });
  }, [processData?._id,id])
  

  const loadIngredientData = () => {
    let getIngredientData = [
      ...(processFields[processNumber]?.associateIngredeients || []),
    ];
    if (getIngredientData.length > 0) {
      return getIngredientData;
    } else {
      let tempIngredient = productIngredientData?.map(
        (obj: any) => {
          return {
            ingredientName: obj?.ingredient_name,
            content: 0,
            unit: obj?.unit_type,
            ingredientId: obj?.ingredient_id,
          };
        }
      );
      return tempIngredient;
    }
  };

  const loadCIPData = () => {
    let currentProcessCIP = [...(processFields[processNumber]?.cipSip || [])];
    if (currentProcessCIP.length > 0) {
      let dataObject = currentProcessCIP[0];
      let currentCipData = Object.entries(dataObject).reduce(function (
        acc: any,
        obj: any
      ) {
        let getFindData = SipContents.find((data) => data.typeName == obj[0]);
        acc.push({
          typeName: obj[0],
          value: obj[1],
          unit: getFindData?.unit,
          name: getFindData?.name,
        });
        return acc;
      },
      []);
      return currentCipData;
    } else {
      return SipContents;
    }
  };

  const loadProcessData = () => {
    let tempProcessData = [...(processData?.processing || [])];

    let tempProcess = tempProcessData.map((obj: any) => {
      return {
        processName: obj?.processingName,
        content: 0,
        unit: "",
        processId: obj?._id
      };
    });

    if (processData?.processing || [].length > 0) {
      return tempProcess;
    } else {
      return tempProcess;
    }
  };

  const onSubmit = (data: any, status?: any) => {
    let processData = data.processDetails;
    const dataToCreate = {
      _id: id,
      productId: processData?.productId,
      productName: processData?.productName,
      userCompletedStage: "PROCESSING",
      processing: processData,
    };

    let lastProcessData = processData[processData.length - 1];
    let checkProcessName = isEmpty(lastProcessData.processingName.trim());
    let checkFacilityName = isEmpty(lastProcessData.facilityName.trim());

    if ((checkProcessName || checkFacilityName) && status == "CompleteLater") {
      errorView("Please fill process data");
    } else {
      dispatch(createProcess(dataToCreate)).then((res) => {
        if (status == "new") {
          processFieldsAppend({
            ...defaultProcess,
            processNumber: processFields.length + 1,
          });
          setProcessNumber(processFields.length);
        } else if (status == "CompleteLater") {
          navigate("/process");
        }
      })
    }
  };

  const getRequiredValue = () => {
    let getFacilityName =
      getValues().processDetails?.[processNumber].facilityName;
    let getProcessingName =
      getValues().processDetails?.[processNumber].processingName;

    return { getFacilityName, getProcessingName };
  };

  return (
    <>
      <>
        <TitleBar titleName={"Produce Process"} />
        <Grid className="backgroundMain">
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <InputText
              register={register}
              labelName={"Product Name"}
              placeholder="Product Name"
              name={`productName`}
              disabled={true}
            />
          </Grid.Column>
        </Grid>

        <Grid className="tabbMain">
          <TabView
            computer={2}
            tabData={ProcessTab}
            defaultSelect={1}
            addParams={true}
            paramsData={processData?._id}
          >
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <EmphasizeText>
                Please follow your exact production process flow
              </EmphasizeText>
            </Grid.Column>
            <Grid style={{ width: "100%" }}>
              <Grid.Column computer={3}>
                <CustomButton
                  theme="green"
                  title="Add New Process"
                  onClick={handleSubmit((data) => {
                    onSubmit(data, "new");
                  })}
                  customColumnStyle="topButtonView customProcessAdd"
                  iconWithText={true}
                  iconName="plus"
                  buttonOutLine={true}
                />
              </Grid.Column>
            </Grid>
            {processFields.map((data: any, index: any) => {
              return (
                <Grid.Column computer={2} tablet={3} mobile={8}>
                  <div
                    className={
                      processNumber === index
                        ? "tabViewMainActive"
                        : "tabViewMainInactive"
                    }
                    onClick={() => {
                      setProcessNumber(index);
                    }}
                  >
                    <p
                      className={
                        processNumber === index
                          ? "processTabActiveColor"
                          : "processTabDeActiveColor"
                      }
                    >
                      {!isEmpty(data.processingName)
                        ? data.processingName
                        : `Process ${index + 1}`}
                    </p>
                  </div>
                </Grid.Column>
              );
            })}

            <div className="newBack">
              <Grid.Column computer={16} tablet={8} mobile={16}>
                <TitleView
                  CustomTitleViewMain="packingMn"
                  title={
                    processFields[processNumber]?.processingName ||
                    `Process ${processFields.length}`
                  }
                />

                {processFields.map((data: any, index: any) => {
                  let getProcessType = data?.processingType;
                  return processNumber == index ? (
                    <Grid>
                      <Grid.Column computer={4} tablet={7} mobile={16}>
                        <InputText
                          register={register}
                          errors={
                            errors.processDetails?.[processNumber]?.facilityName
                          }
                          labelName={"Processing Facility"}
                          placeholder="Processing Facility"
                          name={`processDetails.${processNumber}.facilityName`}
                          required={true}
                          errorMessage={"Please enter processing facility"}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={16}>
                        <InputText
                          register={register}
                          errors={
                            errors.processDetails?.[processNumber]
                              ?.processingName
                          }
                          labelName={"Processing Name"}
                          placeholder="Processing Name"
                          name={`processDetails.${processNumber}.processingName`}
                          required={true}
                          errorMessage={"Please enter processing Name"}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <DropDown
                          labelName={"Processing Type"}
                          placeholder="Type"
                          currentData={PROCESSING_TYPES}
                          defaultValue={getProcessType}
                          customGridColumn={"customGridColomnTyp"}
                          handleChangeState={(e: any, { value }: any) => {
                            let { getProcessingName, getFacilityName } =
                              getRequiredValue();
                            let getTempData = data;
                            delete getTempData.id;
                            updateValue(index, {
                              ...getTempData,
                              processingType: value,
                              processingName: getProcessingName,
                              facilityName: getFacilityName,
                            });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={16}>
                        <label className={`inputTextLable`}>
                          Associate ingredients
                        </label>
                        <CustomButton
                          customColumnStyle="processingCustomColumnStyle"
                          customButtonStyle="customBtn"
                          theme="green"
                          title={`+ ${"  "}Add Ingredients`}
                          onClick={() => setViewIngredient(true)}
                        />
                      </Grid.Column>

                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Machine Model Number"}
                          placeholder="Machine Model Number"
                          name={`processDetails[${processNumber}].machineModelNo`}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Capacity(kg/hr)"}
                          placeholder="Capacity(kg/hr)"
                          name={`processDetails[${processNumber}].capacity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Input(kg)"}
                          placeholder="Input(kg)"
                          name={`processDetails[${processNumber}].input`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Output(kg)"}
                          placeholder="Output(kg)"
                          name={`processDetails[${processNumber}].output`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Waste/Side Stream (kg)"}
                          placeholder="Waste/Side Stream per kg"
                          name={`processDetails[${processNumber}].sideStream`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={4} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Heat(MJ/kg)"}
                          placeholder="Heat(MJ/kg)"
                          name={`processDetails[${processNumber}].heat`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Electricity(kWh/kg)"}
                          placeholder="Electricity(kWh/kg)"
                          name={`processDetails[${processNumber}].electricity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Water(m3)"}
                          placeholder="Water(m3)"
                          name={`processDetails[${processNumber}].water`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={5} mobile={16}>
                        <InputText
                          register={register}
                          labelName={"Citric Acid(kg/kg)"}
                          placeholder="Citric Acid(kg/kg)"
                          name={`processDetails[${processNumber}].citricAcid`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>

                      <Grid.Column computer={3} tablet={16} mobile={16}>
                        <CustomButton
                          customColumnStyle="processingCustomColumnStyle"
                          theme="green"
                          title="+ CIP / SIP"
                          customButtonStyle="customBtnCIP"
                          onClick={() => setViewCipSip(true)}
                        />
                      </Grid.Column>
                    </Grid>
                  ) : null;
                })}
              </Grid.Column>
            </div>
            <div className="processButtonView">
              <CustomButton
                theme="green"
                title={"Complete  Later"}
                customColumnStyle={"customRecipeButton"}
                type={"submit"}
                onClick={handleSubmit((data) => {
                  onSubmit(data, "CompleteLater");
                })}
                buttonOutLine={true}
              />
              <CustomButton
                theme="green"
                title={"Next"}
                type={"submit"}
                onClick={handleSubmit((data) => {
                  onSubmit(data);
                  navigate(`/process/packing/${processData?._id}`);
                })}
              />
            </div>
          </TabView>
        </Grid>
      </>
      <AddIngredientsModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
        getIngredientData={(data: any) => {
          let { getProcessingName, getFacilityName } = getRequiredValue();
          let tempCurrentValue: any = { ...processFields[processNumber] };
          delete tempCurrentValue?.id;
          updateValue(processNumber, {
            ...tempCurrentValue,
            associateIngredeients: data?.ingredientData,
            associateProcesses: data?.processData,
            processingName: getProcessingName,
            facilityName: getFacilityName,
          });
        }}
        activeProcessData={loadProcessData()}
        ingredientData={loadIngredientData()}
      />
      <SipRegisterModal
        visibleModal={viewCipSip}
        setVisibleModal={() => {
          setViewCipSip(false);
        }}
        getSipData={(data: any) => {
          let { getProcessingName, getFacilityName } = getRequiredValue();
          let tempCurrentValue: any = { ...processFields[processNumber] };
          delete tempCurrentValue?.id;
          updateValue(processNumber, {
            ...tempCurrentValue,
            cipSip: data,
            processingName: getProcessingName,
            facilityName: getFacilityName,
          });
        }}
        processCIPData={loadCIPData()}
      />
    </>
  );
};

export default Processing;
