import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import "./tabBar.scss";

export const TabView = ({
  tabData = [],
  computer = 2,
  tablet = 16,
  mobile = 16,
  defaultSelect = 0,
  children,
  customMainTab,
  addParams = false,
  paramsData = "",
  disable = false,
}: any) => {
  const navigate = useNavigate();
  const [selectItem, setSelectItem] = useState(defaultSelect);

  useEffect(() => {
    setSelectItem(defaultSelect);
  }, []);

  return (
    <>
      <Grid className={`tabMain ${customMainTab}`}>
        {tabData.map((data: any, index: any) => {
          return (
            <Grid.Column
              computer={data.size}
              tablet={tablet}
              mobile={mobile}
              key={index}
              onClick={() => {
                if (!disable) {
                  setSelectItem(index);
                  navigate(
                    addParams ? `${data.route}/${paramsData}` : data.route
                  );
                }
              }}
              className={
                index == selectItem ? "tabSelectTab" : "tabSelectOther"
              }
            >
              <p
                className={index == selectItem ? `tabSelectTitle` : `tabTitle`}
              >
                {data.name}
              </p>
            </Grid.Column>
          );
        })}
      </Grid>
      {children}
    </>
  );
};
