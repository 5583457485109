import { useEffect } from "react";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { InputText, CustomButton } from "../../components";
import { useAppDispatch } from "../../store/hooks";

import "./forgotPassword.scss";
import { usePostUserForgot } from "../../api/userDetails";
import { successMessage } from "../../helpers/ErrorHandler";
import { customEmailValidation } from "../../utils/utils";

const PasswordForgot = () => {
  const navigate = useNavigate();
  const {
    data,
    isLoading: userLoading,
    mutate,
    isSuccess = false,
  } = usePostUserForgot();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //handle user password forget when data change
  useEffect(() => {
    if (isSuccess) {
      successMessage("Email send");
      navigate("/password-reset", {
        state: {
          isPasswordResetRequired: true,
          email: "",
        },
      });
    }
  }, [isSuccess]);

  //handle user password submit
  const onSubmit = async (data: any) => {
    await mutate(data);
  };

  //handle loading
  if (userLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <form className="mainContaa" onSubmit={handleSubmit(onSubmit)}>
        <Grid className="backgroundLoginMain">
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <span className="welcomeTxt">Forgot Password</span>
          </Grid.Column>

          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <InputText
              register={register}
              errors={errors.email}
              required={true}
              labelName={"Email"}
              placeholder="Enter email"
              name="email"
              errorMessage="Please enter email"
              customInputValidation={true}
              validateHandle={(value: any) => {
                return customEmailValidation(value);
              }}
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <CustomButton
              theme="green"
              title={"Submit"}
              customButtonStyle={"customBtnn"}
              type={"submit"}
            />
          </Grid.Column>
          <Grid.Column
            style={{ paddingTop: "0px" }}
            computer={16}
            tablet={16}
            mobile={16}
          >
            <CustomButton
              theme="green"
              title={"Cancel"}
              customButtonStyle={"customBtnn"}
              buttonOutLine={true}
              onClick={() => navigate("/auth")}
            />
          </Grid.Column>
        </Grid>
      </form>
    </>
  );
};

export default PasswordForgot;
