export const ArrowHeadSvg = () => {
  return (
    <svg style={{ position: "absolute", zIndex: -1, top: 0, left: 0 }}>
      <defs>
        <marker
          markerWidth="10"
          markerHeight="10"
          refX="15"
          refY="2"
          viewBox="0 0 6 6"
          orient="auto-start-reverse"
          id="arrow"
        >
          <polyline
            points="0,2 2,1 0,0"
            fill="none"
            strokeWidth="0.6666666666666666"
            stroke="var(--pecentageSub)"
            strokeLinecap="square"
            transform="matrix(1,0,0,1,0.6666666666666666,1)"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};


export const ArrowHeadSvgSelected = () => {
  return (
    <svg style={{ position: "absolute", zIndex: -1, top: 0, left: 0 }}>
      <defs>
        <marker
          markerWidth="10"
          markerHeight="10"
          refX="15"
          refY="2"
          viewBox="0 0 6 6"
          orient="auto-start-reverse"
          id="arrow_selected"
        >
          <polyline
            points="0,2 2,1 0,0"
            fill="none"
            strokeWidth="0.6666666666666666"
            stroke="var(--menuBarIconActive)"
            strokeLinecap="square"
            transform="matrix(1,0,0,1,0.6666666666666666,1)"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};
