import React, { useMemo, useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";

import {
  TabView,
  DropDown,
  InputText,
  CustomButton,
  SearchValue,
  ItemAdder,
  TitleView,
  TitleBar,
} from "../../../components";
import {
  CultivationTab,
  HarvestSeasonData,
  FertilizerNameData,
  PesticideNameData,
  CultivationFieldFertilizerTitle,
  CultivationFieldPesticidesTitle,
} from "../../../config/constants";

import "./Field.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import {
  getCultivationDataById,
  updateCultivationData,
} from "../../../store/actions/cultivation";
import { error } from "console";
import { isEmpty } from "lodash";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { errorView } from "../../../helpers/ErrorHandler";

const Field = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );
  const [currentSession, setCurrentSession] = useState<string>(
    cultivationData?.cultivationField?.harvestSeason || ""
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    dispatch(getCultivationDataById(id));
  }, []);

  let defaultFertilizerListDetails = {
    fertilizerName: "",
    fertilizerQuantity: 0,
  };

  let defaultPersticideListDetails = {
    pesticideName: "",
    presticideQuantity: 0,
  };

  const values = useMemo(() => {
    return {
      fertilizerList: [
        ...(cultivationData?.cultivationField?.fertilizerList || []),
        defaultFertilizerListDetails,
      ],
      persticideList: [
        ...(cultivationData?.cultivationField?.persticideList || []),
        defaultPersticideListDetails,
      ],
      growthArea: cultivationData?.cultivationField?.growthArea || "",
      harvestSeason:
        cultivationData?.cultivationField?.harvestSeason || "Spring",
      harvestYear: cultivationData?.cultivationField?.harvestYear || "",
      yield: cultivationData?.cultivationField?.yield || "",
      ingredientName: cultivationData?.ingredientName || "",
    };
  }, [cultivationData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: fertilizersFields,
    append: fertilizersAppend,
    remove: fertilizersRemove,
    update,
  } = useFieldArray({ control, name: "fertilizerList" });

  const {
    fields: pesticidesFields,
    append: pesticidesAppend,
    remove: pesticidesRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "persticideList" });

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/cultivation/processing/${cultivationData?._id}`);
      default:
        return navigate(`/cultivation`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    let getValueData = getValues();
    
    let tempData: any = getValueData;
    if (!errorStatus) {
      let getValueData = getValues();
      let tempData: any = getValueData;

      delete tempData["ingredientName"];

    let tempPersticideList = getValueData.persticideList
    let tempPersticideListIndex = tempPersticideList.length - 1
    
    if(tempPersticideList?.[tempPersticideListIndex]?.pesticideName === '' && tempPersticideList?.[tempPersticideListIndex]?.presticideQuantity === 0) {
      tempPersticideList.splice(tempPersticideListIndex, 1)
    }

    let tempFertilizerList = getValueData.fertilizerList
    let tempFertilizerListIndex = tempFertilizerList.length - 1

    if(tempFertilizerList?.[tempFertilizerListIndex]?.fertilizerName === '' && tempFertilizerList?.[tempFertilizerListIndex]?.fertilizerQuantity === 0) {
      tempFertilizerList.splice(tempFertilizerListIndex, 1)
    }
      const filterPersticideArray = tempPersticideList.map(
        ({ id, ...rest }: any) => {
          return rest;
        }
      );

      const filterFertilizerArray = tempFertilizerList.map(
        ({ id, ...rest }: any) => {
          return rest;
        }
      );

      let customData = {
        cultivationField: {
          ...getValueData,
          persticideList: filterPersticideArray,
          fertilizerList: filterFertilizerArray,
        },
        userCompletedStage: "FIELD",
        _id: cultivationData?._id,
      };

      dispatch(updateCultivationData(customData, "Field")).then((res: any) => {
        navigationOption(navigateStatus);
      });
    } else {
      errorView("Please insert valid year");
    }
  };

  const addFertilizers = (index: any) => {
    let fertilizerName =
      getValues().fertilizerList?.[index]?.fertilizerName || null;
    if (!fertilizerName) {
      setError(`fertilizerList.${index}.fertilizerName`, {});
    } else {
      fertilizersAppend(defaultFertilizerListDetails);
    }
  };

  const addPersticide = (index: any) => {
    let persticide = getValues().persticideList?.[index]?.pesticideName || null;
    if (!persticide) {
      setError(`persticideList.${index}.pesticideName`, {});
    } else {
      pesticidesAppend(defaultPersticideListDetails);
    }
  };

  const checkDateValidation = (data: any) => {
    let currentYear = new Date().getFullYear();
    if (data.length < 4) {
      setErrorMessage("Please insert valid year");
      setErrorStatus(true);
      return data;
    } else if (data.length == 4) {
      if (currentYear >= data) {
        setErrorMessage("");
        setErrorStatus(false);
      } else {
        setErrorMessage("Please insert valid year");
        setErrorStatus(true);
      }
      return data;
    } else {
      let subString = data.substring(0, 4);
      return subString;
    }
  };

  return (
    <>
      <TitleBar titleName={"Cultivation Field"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText
            register={register}
            errors={errors.yield}
            labelName={"Ingredient Name*"}
            placeholder="Ingredient Name*"
            name="ingredientName"
            disabled={true}
          />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={CultivationTab}
          defaultSelect={1}
          addParams={true}
          paramsData={cultivationData?._id}
        >
          <Grid style={{ width: "100%" }}>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                errors={errors.yield}
                labelName={"Yield (kg/ha)"}
                placeholder="Yield (kg/ha)"
                name="yield"
                type="number"
                valueAsNumber={true}
                minNumber={0}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputText
                register={register}
                errors={errors.growthArea}
                labelName={"Growth Area/ha"}
                placeholder="Growth Area/ha"
                name="growthArea"
                type="number"
                valueAsNumber={true}
                minNumber={0}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return checkDateValidation(e.target.value);
                  },
                }}
                control={control}
                errors={errors.harvestYear || errorStatus}
                errorMessage={errorMessage}
                name="harvestYear"
                labelName={"Harvest year"}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
              <DropDown
                labelName={"Harvest season"}
                placeholder="Select Season"
                currentData={HarvestSeasonData}
                defaultValue={currentSession}
                handleChangeState={(e: any, { value }: any) => {
                  setCurrentSession(value);
                  setValue("harvestSeason", value);
                }}
                customGridColumn={"customGridColomnFilter"}
              />
            </Grid.Column>
          </Grid>
          <Grid style={{ width: "100%" }}>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <TitleView
                CustomTextTitle="customT"
                CustomTitleViewMain="packingTitle"
                title="List of Fertilizers"
              />
              <ItemAdderTitleView titleData={CultivationFieldFertilizerTitle} />
              {fertilizersFields.map((item, index) => {
                let defaultFertilizer: string = item.fertilizerName as string;
                return (
                  <ItemAdder
                    itemAddHandle={() => addFertilizers(index)}
                    itemRemoveHandle={fertilizersRemove}
                    items={fertilizersFields}
                    currentIndex={index}
                    customItemAdderGridWithInputs={
                      "customItemAdderGridWithInputsPacking"
                    }
                    computerMainView={11}
                  >
                    <Grid>
                      <Grid.Column
                        computer={11}
                        tablet={12}
                        mobile={16}
                        className="packingLeftView packingVertical"
                      >
                        <DropDown
                          placeholder="Select Fertilizer Name"
                          currentData={FertilizerNameData}
                          defaultValue={defaultFertilizer}
                          customGridColumn={"customGridColomnFilter"}
                          handleChangeState={(e: any, { value }: any) => {
                            clearErrors(
                              `fertilizerList.${index}.fertilizerName`
                            );
                            let tempData = item;
                            update(index, {
                              ...tempData,
                              fertilizerName: value,
                            });
                          }}
                        />
                        {!isEmpty(errors.fertilizerList?.[index]) ? (
                          <p className="textInputError">
                            Please select fertilizer name
                          </p>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column
                        computer={5}
                        tablet={4}
                        mobile={16}
                        className="packingVertical"
                      >
                        <InputText
                          register={register}
                          placeholder="FertilizervQuantity"
                          name={`fertilizerList.${index}.fertilizerQuantity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                    </Grid>
                    <></>
                  </ItemAdder>
                );
              })}
            </Grid.Column>

            <Grid.Column computer={8} tablet={8} mobile={16}>
              <TitleView
                CustomTextTitle="customT"
                CustomTitleViewMain="packingTitle"
                title="List of Pesticides"
              />
              <ItemAdderTitleView titleData={CultivationFieldPesticidesTitle} />
              {pesticidesFields.map((item, index) => {
                let defaultPesticideName: string = item.pesticideName as string;

                return (
                  <ItemAdder
                    itemAddHandle={() => addPersticide(index)}
                    itemRemoveHandle={pesticidesRemove}
                    items={pesticidesFields}
                    currentIndex={index}
                    customItemAdderGridWithInputs={
                      "customItemAdderGridWithInputsPacking"
                    }
                  >
                    <Grid>
                      <Grid.Column
                        computer={11}
                        tablet={12}
                        mobile={16}
                        className="packingLeftView packingVertical"
                      >
                        <DropDown
                          placeholder="Select Pesticide Name"
                          currentData={PesticideNameData}
                          defaultValue={defaultPesticideName}
                          customGridColumn={"customGridColomnFilter"}
                          handleChangeState={(e: any, { value }: any) => {
                            clearErrors(
                              `persticideList.${index}.pesticideName`
                            );
                            let tempData = { ...item };
                            updateValue(index, {
                              ...tempData,
                              pesticideName: value,
                            });
                          }}
                        />
                        {!isEmpty(errors.persticideList?.[index]) ? (
                          <p className="textInputError">
                            Please select persticide name
                          </p>
                        ) : null}
                      </Grid.Column>
                      <Grid.Column
                        computer={5}
                        tablet={4}
                        mobile={16}
                        className="packingVertical"
                      >
                        <InputText
                          register={register}
                          placeholder="ex: 1200"
                          name={`persticideList.${index}.presticideQuantity`}
                          type="number"
                          valueAsNumber={true}
                        />
                      </Grid.Column>
                    </Grid>
                    <></>
                  </ItemAdder>
                );
              })}
            </Grid.Column>
          </Grid>
          <div className="packingButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customRecipeButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("late");
              }}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Field;
