import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../types/ProductTypes";

interface ProductState {
  product?: [];
  productDetails: {
    _id: String;
    climate_impact: String;
    product_image_path: String;
    product_ingredients: [];
    product_name: String;
    product_other_info: String;
    product_status: String;
    product_type: String;
    registration_number: String;
    sales_units: [];
  };
}

const initialState: ProductState = {
  product: [],
  productDetails: {
    _id: "",
    climate_impact: "",
    product_image_path: "",
    product_ingredients: [],
    product_name: "",
    product_other_info: "",
    product_status: "",
    product_type: "",
    registration_number: "",
    sales_units: [],
  },
};

export const auth = createSlice({
  name: "product",
  initialState,
  reducers: {
    RESET: () => initialState,
    GET_PRODUCT_DATA: (state, action) => {
      state.product = action.payload;
    },
    SINGLE_PRODUCT_DETAILS: (state, action) => {
      state.productDetails = action.payload;
    },
    RESET_SINGLE_PRODUCT_DETAILS: (state, action) => {
      state.productDetails = action.payload;
    },
  },
});

export const {
  RESET,
  GET_PRODUCT_DATA,
  SINGLE_PRODUCT_DETAILS,
  RESET_SINGLE_PRODUCT_DETAILS,
} = auth.actions;
export default auth.reducer;
