import {
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import { isArray } from "lodash";

import { DrawingContext } from "../../../SimulateDrawingTool";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import { SimulationChemicalContent } from "../../../../../components";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { SipContents } from "../../../../../config/constants";
import AddIngredientModal from "../../../../cultivation/processing/IngredientContentModal";
import {
  useGetFacilityWholeList,
  useGetFarmGateProcessDataById,
  useGetProcessTypesWholeList,
} from "../../../../../api/cultivations";
import {
  useGetSimulationFarmGateProcessDataById,
  usePutSimulationFarmGateProcessData,
  usePostSimulationFarmGateProcessData,
  useDeleteSimulationFarmGateProcessData,
} from "../../../../../api/simulation/farmGateProcess";
import { errorView, successMessage } from "../../../../../helpers/ErrorHandler";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import {
  useGetChemicalTypes,
  useGetWasteManagementWasteTypes,
} from "../../../../../api/static-data";
import { useGetTransportTypesList } from "../../../../../api/cultivations";
import SimulationRecipeContent from "../../../../cultivation/processing/SimulationRecipeModal";
import { SimulationWasteManagementContent } from "../../../../../components/wasteManagment/SimulationWasteManagementModal";
import SimulationSipContentContent from "../../../../cultivation/processing/SimulationSipContentModal";
import {
  removeNewDrawingItemStatus,
  saveNewDrawingItem,
} from "../../../../../utils/cacheStorage";
import SimulationProcessingDetails from "../../../../../components/processingDetails/SimulationProcessingDetails";
import SimulationMeasurementDetails from "../../../../../components/measurementDetails/SimulationMeasurementDetails";

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

export const FarmGateProcessBase = ({ modalData }: any) => {
  const { farmGateId, ingredientId, supplierId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();
  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  // get chart node item from node id
  const processId = nodeItem?.data?.reference?.processId;
  const { height } = useWindowDimensions();
  const [viewIngredient, setViewIngredient] = useState(false);
  const [facilityName, setFacilityName] = useState<any>();
  const [processingType, setProcessingType] = useState("");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [bounceEnable, setBounceEnable] = useState(false);
  const [cipSipData, setCipSipData] = useState<any>([]);
  const [addIngredientActive, setAddIngredientActive] = useState(false);

  const customCapacityValidation = useRef<HandleValidation>(null);
  const customInputValidation = useRef<HandleValidation>(null);
  const customOutputValidation = useRef<HandleValidation>(null);

  //get data from api
  const { data: facilityWholeList, isSuccess: isFacilitySuccess } =
    useGetFacilityWholeList("FACTORY");
  const { data: processTypesWholeList } = useGetProcessTypesWholeList();
  const { mutate: postFarmGateProcessData } =
    usePostSimulationFarmGateProcessData();
  const { mutate: putFarmGateProcessData } =
    usePutSimulationFarmGateProcessData();
  const { mutate: deleteFarmGateProcessData } =
    useDeleteSimulationFarmGateProcessData();
  const { data, status, fetchStatus, refetch } =
    useGetSimulationFarmGateProcessDataById(processId);        

  const {
    data: originalProcessData,
    status: originalProcessStatus,
    fetchStatus: originalProcessFetch,
  } = useGetFarmGateProcessDataById(data?.originalProcessId || null);
  const { data: chemicalDataType } = useGetChemicalTypes();
  const { data: wasteManagementType } = useGetWasteManagementWasteTypes();
  const { data: TransportTypeList } = useGetTransportTypesList();

  const chemicalData = data?.chemical || [];
  const wasteManagement = data?.wasteManagements || [];
  const farmGateReference = nodeItem?.data?.reference;

  useEffect(() => {
    if (isFacilitySuccess) {
      setFacilityName(data?.facilityId || facilityWholeList?.[0]?.value);
      setValue(
        "facilityName",
        data?.facilityId || facilityWholeList?.[0]?.value
      );
    }
  }, [isFacilitySuccess, data]);

  const values = useMemo(() => {
    setCipSipData(data?.cipSip);
    setProcessingType(`${data?.processingType}`);
    setFacilityName(`${data?.facilityId}`);
    return {
      processingName: data?.processingName,
      processingType: data?.processingType,
      facilityName: data?.facilityId,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output || 0,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
    };
  }, [nodeItem, data]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const onSubmit = (data: any, cipSip?: any) => {

    let checkIsValidCipSip = isArray(cipSip);
    let dataToCreate = {
      processingName: data?.processingName,
      ingredientId: ingredientId,
      supplierId: supplierId,
      processNumber: 1,
      facilityId: data?.facilityName,
      processingIngredient: [],
      cipSip: checkIsValidCipSip ? cipSip : cipSipData,
      processingType: data?.processingType,
      machineModelNo: data?.machineModelNo,
      capacity: data?.capacity || 0,
      input: data?.input || 0,
      output: data?.output,
      sideStream: data?.sideStream,
      heat: data?.heat || 0,
      electricity: data?.electricity || 0,
      water: data?.water || 0,
      citricAcid: data?.citricAcid,
      processingStatus: "ACTIVE",
      farmGateId: farmGateId,
    };
    if (farmGateReference) {
      dataToCreate.farmGateId = farmGateReference.processId
        putFarmGateProcessData(dataToCreate, {
          onSuccess: (data: any) => {
            removeNewDrawingItemStatus();
            const updatedNode = {
              ...nodeItem,
              data: {
                ...nodeItem?.data,
                label: data?.processingName,
                // reference is mongoose mix type so you can set any type of here please set necessary reference only
                reference: {
                  processId: data?._id,
                },
              },
            };
            const updatedChartNodes = chartNodes.map((n: any) => {
              if (n.id === nodeItem?.id) {
                return updatedNode;
              }
              return n;
            });
            if (checkIsValidCipSip) {
              successMessage("Process updated CIP / SIP successfully");
              return refetch();
            }
            setChartNodes(updatedChartNodes);
            setNodeItem(updatedNode);
            saveDrawing(chartEdges, updatedChartNodes);
            successMessage("Process updated successfully");
          },
        });
      return;
    }
    dataToCreate.farmGateId = farmGateId
    postFarmGateProcessData(dataToCreate, {
      onSuccess: (data: any) => {
        saveNewDrawingItem("created");
        const updatedNode = {
          ...nodeItem,
          data: {
            ...nodeItem?.data,
            label: data?.processingName,
            // reference is mongoose mix type so you can set any type of here please set necessary reference only
            reference: {
              processId: data?._id,
            },
          },
        };
        const updatedChartNodes = chartNodes.map((n: any) => {
          if (n.id === nodeItem?.id) {
            return updatedNode;
          }
          return n;
        });
        setChartNodes(updatedChartNodes);
        setNodeItem(updatedNode);
        saveDrawing(chartEdges, updatedChartNodes);
        successMessage("Process created successfully");
      },
    });
  };

  const updateCipSipData = (data: any) => {
    setCipSipData(data);
    const currentData = getValues();
    onSubmit(currentData, data);
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const checkRecipeValidation = () => {
    setBounceEnable(true);
    errorView("Please save the process to add recipe");
    return;
  };

  const deleteFarmGate = () => {
    onNodesDelete();
    successMessage("Process deleted successfully");
  };

  const loadCIPData = (currentData: any[]) => {
    if (nodeItem?.data.reference && currentData?.length > 0) {
      const responseSipData = Object.keys(currentData?.[0]);
      const newSipContent: any = SipContents?.map((sip) => {
        const sipContent = responseSipData?.map((resSip) => {
          return (
            sip?.typeName == resSip && {
              ...sip,
              value: currentData?.[0][resSip],
            }
          );
        });
        return sipContent.filter((value) => value !== false);
      });
      return newSipContent.flat(Infinity);
    } else {
      return SipContents;
    }
  };

  const originalCipSipData = useMemo(
    () => loadCIPData(originalProcessData?.cipSip),
    [originalProcessData]
  );

  const simulationCipSipData = useMemo(() => loadCIPData(data?.cipSip), [data]);

  const handleChemical = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add chemical details");
      return false;
    }
  };

  const handleWasteManagement = () => {
    if (processId) {
      return true;
    } else {
      setBounceEnable(true);
      errorView("Please save the process to add waste management details");
      return false;
    }
  };

  const handleSave = () => {
    setBounceEnable(false);
    customCapacityValidation.current?.checkCustomRequired(getValues().capacity);
    customInputValidation.current?.checkCustomRequired(getValues().input);
    customOutputValidation.current?.checkCustomRequired(getValues().output);
  };

  const loadingStatus =
    (nodeItem?.data.reference &&
      status == "loading" &&
      fetchStatus == "fetching") ||
    (originalProcessStatus == "loading" && originalProcessFetch == "fetching");

  if (loadingStatus) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <SimulationProcessingDetails
                register={register}
                errors={errors}
                processTypesWholeList={processTypesWholeList}
                processingType={processingType}
                setProcessingType={setProcessingType}
                setValue={setValue}
                facilityName={facilityName}
                isValidate={isValidate}
                facilityWholeList={facilityWholeList}
                setFacilityName={setFacilityName}
                originalProcessData={originalProcessData}
              />
              <SimulationMeasurementDetails
                control={control}
                errors={errors}
                customCapacityValidation={customCapacityValidation}
                customInputValidation={customInputValidation}
                customOutputValidation={customOutputValidation}
                addIngredientActive={addIngredientActive}
                setViewIngredient={setViewIngredient}
                data={data}
                originalProcessData={originalProcessData}
              />
              <Grid>
                <Grid.Column computer={16}>
                  <SimulationSipContentContent
                    getSipData={(data: any) => {
                      updateCipSipData(data);
                    }}
                    processCIPData={simulationCipSipData}
                    currentCipSipData={data?.cipSip}
                    originalCipSipData={originalProcessData?.cipSip}
                    originalProcessCIPData={originalCipSipData}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationRecipeContent
                    processId={nodeItem?.data?.reference?.processId}
                    nodeItem={nodeItem}
                    recipeId={data?.recipeId}
                    checkRecipeValidation={checkRecipeValidation}
                    type="farm-gate-simulation"
                    data={data}
                    originalProcessData={originalProcessData}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationChemicalContent
                    chemicalDataType={chemicalDataType}
                    processCIPData={() => {}}
                    chemicalData={chemicalData}
                    processId={processId}
                    TransportTypeList={TransportTypeList}
                    refetchChemical={refetch}
                    handleOpenValidation={handleChemical}
                    type="farm"
                    data={data}
                    originalProcessData={originalProcessData}
                  />
                </Grid.Column>
                <Grid.Column computer={16} className="pt-0">
                  <SimulationWasteManagementContent
                    wasteManagementType={wasteManagementType}
                    wasteManagement={wasteManagement}
                    processId={processId}
                    sideStreamValue={values?.sideStream}
                    refetchWasteData={refetch}
                    handleOpenValidation={handleWasteManagement}
                    type="farm"
                    data={data}
                    originalProcessData={originalProcessData}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              cancelStatus={true}
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              saveButton={() => handleSave()}
              deleteButton={() => setVisibleDeleteModal(true)}
              cancelButton={() => setNodeItem(null)}
              saveTitle={"Save"}
              bounceEnable={bounceEnable}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <AddIngredientModal
        visibleModal={viewIngredient}
        setVisibleModal={() => {
          setViewIngredient(false);
        }}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            farmGateId: farmGateId,
            processId: nodeItem?.data?.reference?.processId,
          };
          deleteFarmGateProcessData(dataToDelete, {
            onSuccess: (data: any) => {
              deleteFarmGate();
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete ${data?.processingName} Process`}
        subTitle={`Are you sure you want to remove the ${data?.processingName} farm gate process? Please consider you cannot recover these data after remove`}
      />
    </Grid>
  );
};
