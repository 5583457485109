import { DragEvent } from "react";
import "./CreateNodeCard.scss";

interface CreateNodeCardProps {
  item: any;
  image: any;
  cardHeader: string;
  cardExtra: string;
}

export const CreateNodeCard = (props: CreateNodeCardProps) => {
  const { item, image, cardHeader, cardExtra } = props;

  const onDragStart = (event: DragEvent<HTMLDivElement>, node: any) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div
      className="create-node-card"
      onDragStart={(event) => onDragStart(event, item)}
      draggable
    >
      <div className="node-card-image">
        <img src={image} loading="lazy" alt="images" />
      </div>

      <div className="card-details">
        <span className="card-header">{cardHeader}</span>
      </div>
    </div>
  );
};
