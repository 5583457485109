import gql from "graphql-tag";

export const GET_CLIENT_LIST = gql`
  query GetClientList {
    getClientList {
      _id
      clientName
      product {
        _id
        productName
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient(
    $clientAddress: String
    $clientCity: String
    $clientCountry: String
    $clientEmail: String
    $clientName: String!
    $clientOtherInfo: String
    $clientPhone: String
    $clientProvince: String
    $clientZipCode: String
    $product: [CreateClientProductDto!]
  ) {
    createClient(
      createClient: {
        clientAddress: $clientAddress
        clientCity: $clientCity
        clientCountry: $clientCountry
        clientEmail: $clientEmail
        clientName: $clientName
        clientOtherInfo: $clientOtherInfo
        clientPhone: $clientPhone
        clientProvince: $clientProvince
        clientZipCode: $clientZipCode
        product: $product
      }
    ) {
      _id
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClientById(
    $_id: String!
    $clientAddress: String
    $clientCity: String
    $clientCountry: String
    $clientEmail: String
    $clientName: String
    $clientOtherInfo: String
    $clientPhone: String
    $clientProvince: String
    $clientZipCode: String
    $clientStatus: String
    $product: [UpdateClientProductDto!]
  ) {
    updateClientById(
      updateClientById: {
        _id: $_id
        clientAddress: $clientAddress
        clientCity: $clientCity
        clientCountry: $clientCountry
        clientEmail: $clientEmail
        clientName: $clientName
        clientOtherInfo: $clientOtherInfo
        clientPhone: $clientPhone
        clientProvince: $clientProvince
        clientZipCode: $clientZipCode
        clientStatus: $clientStatus
        product: $product
      }
    ) {
      _id
    }
  }
`;

export const GET_CLIENT_BY_ID = gql`
  query getClient($clientId: String!) {
    getClient(clientId: $clientId) {
      _id
      clientAddress
      clientCity
      clientCountry
      clientEmail
      clientName
      clientOtherInfo
      clientPhone
      clientProvince
      clientZipCode
      clientStatus
      product {
        _id
        productName
        salesUnitId
        salesUnitName
      }
    }
  }
`;
