import React from "react";
import { Button, Modal } from "semantic-ui-react";

import "./confirmModal.scss";

const CultivationConfirmModal = ({
  viewModal = false,
  closeModal = () => {},
  check = () => {},
  calculation = () => {},
  next,
  size = "small",
  title = "",
  subTitle = "",
}: any) => {
  return (
    <>
      <Modal
        centered={false}
        size={size}
        open={viewModal}
        onClose={() => closeModal()}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <p className="subTitleModalView">{subTitle}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => check()}>
            I will check again
          </Button>
          <Button positive onClick={() => calculation()}>
            Calculate
          </Button>
          {next && <Button positive onClick={() => next()}>
            Add next ingredient
          </Button>}
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CultivationConfirmModal;
