import React, { useEffect, useState } from "react";
import { Grid, Table, Loader, Dimmer } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { ClentListData, ClientTableCol, ClientListTableCol } from "../../../config/constants";
import ClientRegisterModal from "../clientRegister/ClientRegisterModal";
import ClientEditModal from "../clientEdit/ClientEditModal";
import ClientDetailsModal from "../clientDetails/ClientDetailsModal";
import { useAppDispatch } from "../../../store/hooks";
import {
  getClientsWithProductsData,
  updateClient,
  getClientById
} from "../../../store/actions/client";
import {useGetClientsList} from "../../../api/clients"
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

interface ClientListProps {
  notFromDrawing: boolean
}

const ClientList = (props: ClientListProps) => {
  const {notFromDrawing} = props
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<any>({});

  const {
    data: clientList,
    isLoading: isClientListLoading
  } = useGetClientsList();

  const handleClientDelete = (data: any) => {
    setDataToDelete(data);
    setShowClientModal(true);
  };

  const getClientDetails = (id: any) => {
    dispatch(getClientById(id)).then(() => setVisibleDetailsModal(true));
  };
  
  const loadTableData = () => {
    return clientList?.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.clientName}</p>
          </Table.Cell>
          {!notFromDrawing ? <><Table.Cell>
            <p>{data?.productName}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    getClientDetails(data.clientId);
                  }}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => setVisibleEditModal(true)}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline onClick={() => {
                    handleClientDelete({
                      _id: data.clientId,
                      clientName: data.clientName,
                      clientStatus: "DISABLE",
                      product: [],
                    });
                  }} cursor={"pointer"} size={24} color="var(--mainColor)" />
              </Grid.Column>
            </Grid>
          </Table.Cell></> : <><Table.Cell>
            <p>{data?.clientEmail}</p>
          </Table.Cell>
          <Table.Cell>
          <p>{data?.clientPhone}</p>
        </Table.Cell></>}
        </Table.Row>
      );
    });
  };

  // Loading state check
  if (isClientListLoading) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Clients"} />
      {!notFromDrawing && <CustomButton
        theme="green"
        title="New Client"
        onClick={() => setVisibleModal(true)}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />}
      <Grid>
        <Grid.Column
          computer={16}
          tablet={16}
          mobile={16}
          className="userBankDetailsMain"
        >
          <CommonTable tableHeaderData={notFromDrawing ? ClientListTableCol : ClientTableCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <ClientRegisterModal
        visibleModal={visibleModal}
        setVisibleModal={() => setVisibleModal(false)}
      />
      <ClientDetailsModal
        visibleModal={visibleDetailsModal}
        setVisibleModal={() => setVisibleDetailsModal(false)}
      />
      <ClientEditModal
        visibleModal={visibleEditModal}
        setVisibleModal={() => setVisibleEditModal(false)}
      />
      <ConfirmModal
        viewModal={showClientModal}
        closeModal={() => setShowClientModal(false)}
        cancel={() => {
          setShowClientModal(false);
          setDataToDelete({});
        }}
        approve={() => {
          setShowClientModal(false);
          dispatch(updateClient(dataToDelete));
          setDataToDelete({});
        }}
        title="Delete Client Data"
        subTitle="Are you sure you want to delete the client?"
      />
    </>
  );
};

export default ClientList;
