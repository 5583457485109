import { configureStore } from "@reduxjs/toolkit";
import auth from "./slicers/auth";
import product from "./slicers/product";
import ingredient from "./slicers/ingredient";
import supplier from "./slicers/supplier";
import process from "./slicers/process";
import cultivation from "./slicers/cultivation";
import client from "./slicers/client";
import users from "./slicers/users";
import company from "./slicers/company";

export const store = configureStore({
  reducer: {
    authUser: auth,
    product: product,
    ingredient: ingredient,
    supplier: supplier,
    process: process,
    cultivation: cultivation,
    client: client,
    users: users,
    company: company,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
