import { useState, useContext, useMemo, useEffect } from "react";
import { DropDown, InputText, CustomButton } from "../../../../../components";
import { useReactFlow } from "reactflow";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import {
  usePostProcessGateInternalTransportData,
  usePutProcessGateInternalTransportData,
  useDeleteProcessGateInternalTransportData,
  useGetProcessGateInternalTransportById,
} from "../../../../../api/processGate/supplierProcess";
import { useGetTransportTypesList } from "../../../../../api/cultivations";
import { successMessage } from "../../../../../helpers/ErrorHandler";
import { DrawingForIngredientContext } from "../../../DrawingToolForIngredient";
import { InputNumber } from "../../../../../components/InputText/InputNumber";
import { valueGraterThanZero } from "../../../../../utils/utils";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";

export const ProcessInternalTransportBase = ({ modalData }: any) => {
  const { processGateId, nodeItem } = modalData;
  const { deleteElements } = useReactFlow();

  const {
    nodeItemId,
    chartEdges,
    chartNodes,
    setNodeItem,
    setChartNodes,
    saveDrawing,
  } = useContext(DrawingForIngredientContext);

  const { height } = useWindowDimensions();
  const [transportType, setTransportType] = useState("1");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

  const { data: TransportTypeList } = useGetTransportTypesList();

  const { mutate: postInternalTransportData } =
    usePostProcessGateInternalTransportData();
  const { mutate: deleteInternalTransportData } =
    useDeleteProcessGateInternalTransportData();
  const { mutate: putInternalTransportData } =
    usePutProcessGateInternalTransportData();
  const {
    data: internalTransportData,
    status,
    fetchStatus,
  } = useGetProcessGateInternalTransportById(
    nodeItem?.data?.reference?.internalTransportId
  );

  const values = useMemo(() => {
    setTransportType(`${internalTransportData?.transportationType || "1"}`);
    return {
      internalTransportName: internalTransportData?.internalTransportName,
      averageDistance: internalTransportData?.averageDistance,
      transportType: `${internalTransportData?.transportationType || "1"}`,
      weight: internalTransportData?.weight,
    };
  }, [nodeItem, internalTransportData]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const updateNodeData = (findTransportType: any, data: any) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        label: `Internal Transport (${data?.internalTransportName})`,
        reference: {
          internalTransportId: data?._id,
        },
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage(
      nodeItem?.data.reference
        ? "Internal Transport updated successfully"
        : "Internal Transport created successfully"
    );
  };

  const onSubmit = (data: any) => {
    let findTransportType: any = TransportTypeList?.find(
      (e: any) => e.key === transportType
    );
    let transportId = nodeItem?.data?.reference?.internalTransportId;
    const dataToSubmit = {
      processGateId: processGateId,
      internalTransportId: nodeItem?.data?.reference?.internalTransportId,
      transportData: {
        internalTransportName: data?.internalTransportName,
        transportationType: Number(data?.transportType),
        averageDistance: data?.averageDistance,
        weight: data?.weight ?? 0,
      },
    };
    if (transportId) {
      putInternalTransportData(dataToSubmit, {
        onSuccess: (data: any) => {
          updateNodeData(findTransportType, data);
        },
      });
    } else {
      postInternalTransportData(dataToSubmit, {
        onSuccess: (data: any) => {
          updateNodeData(findTransportType, data);
        },
      });
    }
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  if (
    nodeItem?.data.reference &&
    status == "loading" &&
    fetchStatus == "fetching"
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid>
              <Grid.Column computer={16} tablet={8} mobile={16}>
                  <InputText
                    labelName={"Internal Transport Name*"}
                    errorMessage="Internal Transport Name is required"
                    name="internalTransportName"
                    register={register}
                    errors={errors.internalTransportName}
                    required
                  />
                </Grid.Column>
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errors?.averageDistance}
                    labelName={
                      "Average Distance from Filed(s) to Facility (km)*"
                    }
                    placeholder="Average Distance from Filed(s) to Facility (km)"
                    name={`averageDistance`}
                    required={true}
                    errorMessage={"Please enter average distance"}
                    type="number"
                    otherValidation={{
                      validate: {
                        valueCheck: (v: any) =>
                          valueGraterThanZero(v) ||
                          "Average Distance must be grater than 0",
                      },
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <DropDown
                    labelName={"Transportation Type"}
                    currentData={TransportTypeList || []}
                    defaultValue={transportType}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setTransportType(value);
                      setValue("transportType", value);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={16} tablet={8} mobile={16}>
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Weight(Kg)"}
                    placeholder="Weight(Kg)"
                    name={`weight`}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              cancelStatus={true}
              deleteStatus={nodeItem?.data.reference}
              saveButtonStatus={true}
              deleteButton={() => setVisibleDeleteModal(true)}
              cancelButton={() => setNodeItem(null)}
              saveTitle={nodeItem?.data.reference ? "Update" : "Save"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            processGateId: processGateId,
            internalTransportId: nodeItem?.data?.reference?.internalTransportId,
          };
          deleteInternalTransportData(dataToDelete, {
            onSuccess: (data: any) => {
              setNodeItem(null);
              onNodesDelete();
              successMessage("Internal Transport deleted successfully");
            },
          });

          setVisibleDeleteModal(false);
        }}
        title="Delete Process Gate Internal Transport"
        subTitle="Are you sure you want to remove the process gate internal transport? Please consider you cannot recover these data after removed"
      />
    </Grid>
  );
};
