import gql from "graphql-tag";

export const CREATE_INGREDIENT = gql`
  mutation AddingIngredientsToProduct(
    $product_id: String!
    $product_name: String!
    $product_ingredients: [UpdateCreateProductIngredeients!]!
  ) {
    AddingIngredientsToProduct(
      AddingProductIngredients: {
        product_id: $product_id
        product_name: $product_name
        product_ingredients: $product_ingredients
      }
    ) {
      product_type
      product_name
      product_ingredients {
        ingredient_id
        ingredient_name
        unit_size
        unit_type
        ean_barcode
      }
    }
  }
`;

export const UPDATE_INGREDIENT = gql`
  mutation UpdateIngredient(
    $_id: String!
    $ingredient_name: String!
    $suppliers: [UpdateSuppliers!]
    $products: [UpdateProducts!]
    $ingredient_status: String!
  ) {
    UpdateIngredient(
      UpdateIngrediant: {
        _id: $_id
        ingredient_name: $ingredient_name
        suppliers: $suppliers
        products: $products
        ingredient_status: $ingredient_status
      }
    ) {
      _id
    }
  }
`;

export const GET_ALL_INGREDIENTS = gql`
  query GetIngredeintSupplierList {
    GetIngredeintSupplierList {
      ingredient_id
      ingredient_name
      supplier_id
      supplier_name
      product_id
      product_name
      climate_impact
      ingredient_type
    }
  }
`;

export const GET_INGREDIENT_BY_ID = gql`
  query getIngredient($ingredient_id: String!, $ingredient_status: String!) {
    getIngredient(
      tenant_id: {
        ingredient_id: $ingredient_id
        ingredient_status: $ingredient_status
      }
    ) {
      ingredient_name
      suppliers {
        supplier_id
        supplier_name
        unit_size
        unit_type
      }
      products {
        _id
        product_id
        product_name
      }
    }
  }
`;

export const GET_AN_INGREDIENT_DETAILS = gql`
  query GetIngredeintProductSupplier(
    $ingredient_id: String!
    $product_id: String!
    $supplier_id: String
    $ingredient_status: String!
    $ingredient_type: String!
  ) {
    GetIngredeintProductSupplier(
      SearchProductIngredientDto: {
        ingredient_id: $ingredient_id
        product_id: $product_id
        supplier_id: $supplier_id
        ingredient_status: $ingredient_status
        ingredient_type: $ingredient_type
      }
    ) {
      _id
      ingredient_name
      product_name
      supllier_object_id # Object id of supplier object
      supplier_name
      product_object_id # Object id of product object
      product_id
      product_name
      supplier_id
      supllier_object_id
      supplier_name
      ingredient_type
      data_type
      unit_size
      unit_type
      ean_bar_code
    }
  }
`;

export const GET_ALL_SUPPLIERS = gql`
  query getAllSuppliers {
    getAllSuppliers {
      _id
      supplier_name
    }
  }
`;

export const GET_ALL_INGREDIENT_DATA = gql`
  query AllIngredients {
    AllIngredients {
      _id
      ingredient_name
    }
  }
`;
