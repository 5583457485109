import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";

import {
  TabView,
  DropDown,
  InputText,
  CustomButton,
  ItemAdder,
  TitleView,
  TitleBar,
} from "../../../components";
import {
  CultivationTab,
  ProcessTab,
  TransportationType,
  CultivationInternalTransportationTitle,
  CultivationFromTransportationTitle
} from "../../../config/constants";

import "./Transportation.scss";
import { isEmpty } from "lodash";
import { useAppDispatch } from "../../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";
import {
  getCultivationDataById,
  updateCultivationData,
} from "../../../store/actions/cultivation";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";

const Transportation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );

  useEffect(() => {
    dispatch(getCultivationDataById(id));
  }, []);

  const defaultInternalTransportation = {
    averageDistance: 0,
    transportType: TransportationType[0].text,
    _id: null,
  };

  const defaultExternalTransportation = {
    distance: 0,
    from: "",
    to: "",
    transportType: TransportationType[0].text,
    _id: null,
  };

  const values = useMemo(() => {
    return {
      internalTransportation: [
        ...(cultivationData?.internalTransportation || []),
        defaultInternalTransportation,
      ],
      externalTransportation: [
        ...(cultivationData?.externalTransportation || []),
        defaultExternalTransportation,
      ],
      ingredientName: cultivationData?.ingredientName,
    };
  }, [cultivationData]);

  const {
    register,
    control,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: internalTransportFields,
    append: internalTransportAppend,
    remove: internalTransportRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "internalTransportation" });

  const {
    fields: externalTransportFields,
    append: externalTransportAppend,
    remove: externalTransportRemove,
    update: updateExternal,
  } = useFieldArray({ control, name: "externalTransportation" });

  const [defaultTypeInternal, setDefaultTypeInternal] = useState<any>(
    ""
  );
  const [defaultTypeExternal, setDefaultTypeExternal] = useState<any>(
    ""
  );

  const internalTransportationAdd = (index: any) => {
    let checkAverageDistance = getValues().internalTransportation?.[index]?.averageDistance || 0;
    
    if (checkAverageDistance === 0) {
      setError(`internalTransportation.${index}.averageDistance`, {});
    } else {
      internalTransportAppend(defaultInternalTransportation);

    }
  };

  const externalTransportationAdd = (index: any) => {
    let checkDistance = getValues().externalTransportation?.[index]?.distance || 0;
    
    if (checkDistance === 0) {
      setError(`externalTransportation.${index}.distance`, {});
    } else {
    externalTransportAppend(defaultExternalTransportation);
    }
  };

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/cultivation/confirmation/${cultivationData?._id}`);
      default:
        return navigate(`/cultivation`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();

      let tempInternalTransportation = getValueData.internalTransportation;
      let tempInternalTransportationIndex = tempInternalTransportation.length - 1

      let tempExternalTransportation = getValueData.externalTransportation;
      let tempExternalTransportationIndex = tempExternalTransportation.length - 1

      if(tempInternalTransportation?.[tempInternalTransportationIndex]?.averageDistance === 0 && tempInternalTransportation?.[tempInternalTransportationIndex]?.transportType === '') {
        tempInternalTransportation.splice(tempInternalTransportationIndex, 1);
      }

      if(tempExternalTransportation?.[tempExternalTransportationIndex]?.from === '' && tempExternalTransportation?.[tempExternalTransportationIndex]?.to === '' && tempExternalTransportation?.[tempExternalTransportationIndex]?.distance === 0) {
        tempExternalTransportation.splice(tempExternalTransportationIndex, 1);
      }
      
      

      const filterInternalTransportation = tempInternalTransportation.map(

        ({ _id, id,  ...rest }: any) => {
          if(_id === null || _id === "") {
            return rest;

          } else {
            return {
              _id,
              ...rest
            }
          }
        }
      );

      const filterExternalTransportation = tempExternalTransportation.map(
        ({ _id,id, ...rest }: any) => {
          if(_id === null || _id === "") {
            return rest;

          } else {
            return {
              _id,
              ...rest
            }
          }
        }
      );

      let energyData = {
        _id: cultivationData?._id,
        internalTransportation: filterInternalTransportation,
        externalTransportation: filterExternalTransportation,
        userCompletedStage: "TRANSPORTATION",
      };
      dispatch(updateCultivationData(energyData, "Transport")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  return (
    <>
      <TitleBar titleName={"Cultivation Transport"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={CultivationTab}
          defaultSelect={6}
          addParams={true}
          paramsData={cultivationData?._id}
        >
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Internal Transportation"
            />
            <ItemAdderTitleView titleData={CultivationInternalTransportationTitle} />
            {internalTransportFields.map((item, index) => {
              let transportTypeInternal = item?.transportType || null;
              return (
                <ItemAdder
                  itemAddHandle={() => internalTransportationAdd(index)}
                  itemRemoveHandle={internalTransportRemove}
                  items={internalTransportFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={6}
                      tablet={5}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <InputText
                        register={register}
                        required={true}
                        errors={
                          errors.internalTransportation?.[index]
                            ?.averageDistance
                        }
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          
                          e.target.value > 0
                            ? clearErrors(`internalTransportation.${index}.averageDistance`)
                            : setError(
                                `internalTransportation.${index}.averageDistance`,
                                {}
                              );
                        }}
                        placeholder="Average Distance"
                        name={`internalTransportation.${index}.averageDistance`}
                        type="number"
                        valueAsNumber={true}
                        errorMessage={"Average Diastance should be greater than 0"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={7}
                      tablet={7}
                      mobile={16}
                      className="packingVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Transportation Type"
                        currentData={TransportationType}
                        defaultValue={
                          transportTypeInternal
                            ? transportTypeInternal
                            : defaultTypeInternal
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          updateValue(index, {
                            ...item,
                            transportType: value,
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>

          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Transportation From Supplier"
            />
            <ItemAdderTitleView titleData={CultivationFromTransportationTitle} />
            {externalTransportFields.map((item, index) => {
              let transportTypeExternal = item?.transportType || null;
              return (
                <ItemAdder
                  itemAddHandle={() => externalTransportationAdd(index)}
                  itemRemoveHandle={externalTransportRemove}
                  items={externalTransportFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={6}
                      tablet={6}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Transportation Type"
                        currentData={TransportationType}
                        defaultValue={
                          transportTypeExternal
                            ? transportTypeExternal
                            : defaultTypeExternal
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          updateExternal(index, {
                            ...item,
                            transportType: value,
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        placeholder="From"
                        name={`externalTransportation[${index}].from`}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        placeholder="To"
                        name={`externalTransportation[${index}].to`}
                        customInputValidation={true}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={3}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        required={true}
                        errors={errors.externalTransportation?.[index]?.distance}
                        placeholder="Distance(km)"
                        name={`externalTransportation.${index}.distance`}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          
                          e.target.value > 0
                            ? clearErrors(`externalTransportation.${index}.distance`)
                            : setError(
                                `externalTransportation.${index}.distance`,
                                {}
                              );
                        }}
                        type="number"
                        valueAsNumber={true}
                        errorMessage={"Diastance should be greater than 0"}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>

          <div className="packingButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customRecipeButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("last");
              }}
            />
            <CustomButton
              onClick={() => {
                onSubmit("next");
              }}
              theme="green"
              title={"Next"}
              type={"submit"}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Transportation;
