import { useState, useContext, useEffect, useMemo, useCallback, useRef } from "react";
import { useReactFlow } from "reactflow";
import { isEmpty } from "lodash";
import { DropDown, InputText, CustomButton } from "../../../components";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import useWindowDimensions from "../../../hooks/windowsSize";
import { DrawingForIngredientContext } from "../DrawingToolForIngredient";
import { useGetFacilityList } from "../../../api/facility";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import {
  useCreateExternalStorage,
  useUpdateExternalStorage,
  useGetExternalStorageById,
  useDeleteExternalStorage,
} from "../../../api/general/supplierStorage";
import { useGetFacilityWholeList } from "../../../api/cultivations";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { valueGraterThanZero, isValueValid } from "../../../utils/utils";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";

interface WarehouseBaseProps {}

interface HandleValidation {
  checkCustomRequired(data?: any): void;
}

interface IFormData {
  externalStorageName?: string;
  externalStorageId: string;
  facilityId: string;
  storageTemperature?: number;
  warehouseName?: string;
  warehouseSize?: number;
  weight?: number;
  electricity?: number;
  warehouseUtilization?: number;
  storageDays?: number;
}

export const WarehouseBase = (props: WarehouseBaseProps) => {
  const { height } = useWindowDimensions();
  const [warehouse, setWarehouse] = useState("");
  const reactFlow = useReactFlow();
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [customRequired, setCustomRequired] = useState(false);

  const {
    nodeItemId,
    setNodeItem,
    setChartNodes,
    saveDrawing,
    chartEdges,
    chartNodes,
    supplierIngredientId,
  } = useContext(DrawingForIngredientContext);

  const nodeItem = useMemo(
    () => reactFlow.getNode(nodeItemId || ""),
    [nodeItemId]
  );

  const externalStorageId =
    nodeItem?.data?.reference?.externalStorageId || null;

  const initFormData: IFormData = {
    externalStorageId,
    facilityId: "",
  };

  const [formData, setFormData] = useState<IFormData>(initFormData);
  const { deleteElements } = useReactFlow();

  const {
    data: facilityData,
    isSuccess: isFacilityDataSuccess,
    isLoading: isFacilityDataLoading,
  } = useGetFacilityWholeList("STORAGE");

  const facilities = useMemo(() => {
    if (isFacilityDataSuccess) {
      return facilityData;
    }
  }, [facilityData, isFacilityDataSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    values: formData,
  });

  const customUtilizationValidation = useRef<HandleValidation>(null);
  const customWeightValidation = useRef<HandleValidation>(null);
  const customStorageDaysValidation = useRef<HandleValidation>(null);

  const {
    mutate: externalStorageCreateDataMutate,
    data: createExternalStorageData,
    isSuccess: isExternalStorageCreateSuccess,
  } = useCreateExternalStorage();

  const {
    mutate: externalStorageUpdateDataMutate,
    data: updateExternalStorageData,
    isSuccess: isExternalStorageUpdateSuccess,
  } = useUpdateExternalStorage();

  const { data: currentFormData } =
    useGetExternalStorageById(externalStorageId);

  const {
    mutate: externalStorageDeleteDataMutate,
    isLoading: isExternalStorageDeleteLoading,
    isSuccess: isExternalStorageDeleteSuccess,
  } = useDeleteExternalStorage();

  const updateDrawing = (externalStorageId: string, externalStorageName: string) => {
    const updatedNodeItem = updateNodeItem(nodeItem, externalStorageId, externalStorageName);
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNodeItem;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNodeItem);
    saveDrawing(chartEdges, updatedChartNodes);
    successMessage("Ware house updated successfully");
  };

  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem?.id || "" }] });
    setNodeItem(null);
  };

  const closeModal = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleDeleteConfirmation = () => {
    externalStorageDeleteDataMutate(externalStorageId, {
      onSuccess(data) {
        onNodesDelete();
      },
    });
    closeModal();
  };

  useEffect(() => {
    if (nodeItem && chartEdges) {
      const foundLink = chartEdges.find((edge) => edge.target === nodeItem?.id);
      if (!foundLink) {
        setNodeItem(null);
        errorView("Please link the component");
      }
    }
    if (isExternalStorageCreateSuccess) {
      const externalStorageId = (
        createExternalStorageData as any
      )?._id.toString();
      const externalStorageName = (createExternalStorageData as any)
        ?.externalStorageName;
      updateDrawing(externalStorageId, externalStorageName);
    }

    if (isExternalStorageUpdateSuccess) {
      const externalStorageId = (
        updateExternalStorageData as any
      )?._id?.toString();
      const externalStorageName = (updateExternalStorageData as any)
        ?.externalStorageName;
      updateDrawing(externalStorageId, externalStorageName);
    }
    if (currentFormData) {
      setWarehouse(currentFormData.facilityId);
      const formData = {
        externalStorageId: currentFormData._id,
        externalStorageName: currentFormData.externalStorageName,
        facilityId: currentFormData.facilityId,
        storageTemperature: currentFormData.storageTemperature,
        warehouseName: currentFormData.warehouseName,
        warehouseSize: currentFormData.warehouseSize,
        weight: currentFormData.weight,
        electricity: currentFormData.electricity,
        warehouseUtilization: currentFormData.warehouseUtilization,
        storageDays: currentFormData.storageDays,
      };
      setFormData(formData);
      if (isExternalStorageDeleteSuccess && nodeItem) {
        reactFlow.deleteElements({ nodes: [{ id: nodeItem.id }] });
        successMessage("Ware house deleted successfully");
      }
    }
  }, [
    currentFormData,
    nodeItem,
    externalStorageId,
    isExternalStorageCreateSuccess,
    externalStorageId,
    isExternalStorageUpdateSuccess,
    isExternalStorageDeleteSuccess,
  ]);

  // update nodeItem
  const updateNodeItem = useCallback(
    (nodeItem: any, externalStorageId: string, externalStorageName: string) => {
      return {
        ...nodeItem,
        data: {
          ...nodeItem?.data,
          ...(externalStorageId && {
            label: `External Warehouse (${externalStorageName})`,
          }),
          reference: {
            ...nodeItem?.data?.referece,
            externalStorageId,
          },
        },
      };
    },
    []
  );

  const onSubmit = useCallback(
    (values: IFormData) => {
      if (!supplierIngredientId) {
        errorView("Please connect ingredient before create external storage");
        return;
      }
      console.log('EEE', values);
      
      if (isEmpty(warehouse)) {
        setIsValidate(true);
        return;
      }

      let storageData = {
        externalStorageName: values?.externalStorageName,
        warehouseName: warehouse,
        warehouseSize: values?.warehouseSize || 0,
        storageTemperature: values?.storageTemperature || 0,
        weight: values?.weight || 0,
        electricity: values?.electricity || 0,
        warehouseUtilization: values?.warehouseUtilization || 0,
        storageDays: values?.storageDays || 0,
        supplierIngredientId,
      };

      if (externalStorageId) {
        // update external storage
        const updateExternalStorage = {
          ...storageData,
          facilityId: warehouse,
          externalStorageId,
        };
        externalStorageUpdateDataMutate(updateExternalStorage);
      } else {
        // create external storage
        const createExternalStorage = {
          ...storageData,
          facilityId: warehouse,
        };
        externalStorageCreateDataMutate(createExternalStorage);
      }
    },
    [
      saveDrawing,
      externalStorageUpdateDataMutate,
      isExternalStorageCreateSuccess,
      isExternalStorageUpdateSuccess,
      updateExternalStorageData,
      createExternalStorageData,
      nodeItem,
      externalStorageId,
      chartEdges,
      warehouse,
    ]
  );

  return (
    <Grid>
      <Grid.Column
        computer={16}
        tablet={16}
        mobile={16}
        className="userBankDetailsMain"
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 100,
                overflowX: "hidden",
              }}
            >
              <Grid>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                  <InputText
                    labelName={"External Storage Name*"}
                    errorMessage="External Storage Name is required"
                    name="externalStorageName"
                    register={register}
                    errors={errors.externalStorageName}
                    required
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <DropDown
                    labelName={"Warehouse Name*"}
                    currentData={facilities}
                    required
                    validate={isValidate}
                    placeholder="Select Warehouse Name"
                    defaultValue={warehouse}
                    customGridColumn={"customGridColomnTyp"}
                    handleChangeState={(e: any, { value }: any) => {
                      setWarehouse(value);
                      setValue("warehouseName", value);
                    }}
                    errorMessage={"Warehouse name is required"}
                    search
                    loading={isFacilityDataLoading}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    errors={errors?.storageTemperature}
                    labelName={"Storage Temperature (℃)"}
                    placeholder="Storage Temperature (℃)"
                    name={`storageTemperature`}
                    customInputValidation={true}
                    enableMinus={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Warehouse size (m3)"}
                    placeholder="Warehouse size (m3)"
                    name={`warehouseSize`}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customWeightValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Weight (kg)"}
                    placeholder="Weight (kg)"
                    name={`weight`}
                    valueAsNumber={true}
                    errors={errors?.weight}
                    errorMessage="Weight name is required"
                    customRequired={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Electricity (kWh)"}
                    placeholder="Electricity (kWh)"
                    name={`electricity`}
                    valueAsNumber={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customUtilizationValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(value) || value === 0 ? "" : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Utilization of warehouse %"}
                    placeholder="Utilization of warehouse %"
                    name={`warehouseUtilization`}
                    valueAsNumber={true}
                    errors={errors?.warehouseUtilization}
                    errorMessage="Utilization is required"
                    customRequired={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                  className="paddingRemoveVertical-draw"
                >
                  <InputNumber
                    ref={customStorageDaysValidation}
                    transform={{
                      input: (value: any) =>
                        isNaN(parseInt(value)) || value === 0
                          ? ""
                          : value.toString(),
                      output: (e: any) => {
                        return e;
                      },
                    }}
                    control={control}
                    labelName={"Storage day(s)"}
                    placeholder="Storage day(s)"
                    name={`storageDays`}
                    valueAsNumber={true}
                    errors={errors?.storageDays}
                    errorMessage="Storage day(s) required"
                    customRequired={true}
                  />
                </Grid.Column>
              </Grid>
            </div>
            <MainBottomButtonView
              cancelStatus={true}
              deleteStatus={externalStorageId}
              saveButtonStatus={true}
              saveButton={() => {
                customUtilizationValidation.current?.checkCustomRequired(
                  getValues().warehouseUtilization
                );
                customWeightValidation.current?.checkCustomRequired(
                  getValues().weight
                );
                customStorageDaysValidation.current?.checkCustomRequired(
                  getValues().storageDays
                );
              }}
              deleteButton={() => setIsOpenConfirmationModal(true)}
              cancelButton={() => setNodeItem(null)}
              saveTitle={externalStorageId ? "Update" : "Submit"}
              type="submit"
            />
          </form>
        </div>

        <ConfirmModal
          viewModal={isOpenConfirmationModal}
          closeModal={closeModal}
          cancel={closeModal}
          approve={() => handleDeleteConfirmation()}
          title="Delete External Warehouse"
          subTitle="Are you sure you want to remove the external storage? 
              Please consider you cannot recover these data after remove."
        />
      </Grid.Column>
    </Grid>
  );
};
