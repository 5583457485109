import React, { useState,useEffect, useMemo } from "react";
import { Grid, Message } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";

import {
  TabView,
  InputText,
  CustomButton,
  DropDown,
  TitleView,
  TitleBar,
} from "../../../components";
import { ProcessTab } from "../../../config/constants";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import { checkProductProcessData } from "../../../store/actions/ingredient";
import {
  createReceipt,
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";
import { GET_INGREDIENT_CREATE_RESPONSE_DATA } from "../../../store/slicers/ingredient";
import { useGetProductSalesUnits } from "../../../api/product";

import "./addRecipe.scss";
import { useGetProductIngredient } from "../../../api/ingredients";

interface HandleValidation {
  checkValidation(): void;
}

const AddRecipe = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const productDetails = {
    searchProductId: "",
    searchProductName: "",
    searchProductType: "",
    activeStatus: false,
  };
  let { processData } = useSelector((state: RootState) => state.process);
  const [productData, setProductData] = useState<any>(productDetails);
  const [salesUnitId, setSalesUnitId] = useState<any>();
  const [searchKey, setSearchKey] = useState("");
  const [productId, setProductId] = useState<string>();
  const [salesUnitName, setSalesUnitName] = useState<any>();

  // Custom hooks get product sales unit
  const {
    data: productSalesUnitsData,
    isLoading: isProductSalesUnitsLoading,
    refetch: fetchProductSalesUnit,
  } = useGetProductSalesUnits({ searchKey });

  // Custom hooks get by ingredient
  const {
    data: productIngredientData,
    status,
    refetch: fetchIngredientData,
  } = useGetProductIngredient(productId);

  let productIngredient = productIngredientData || [];

  //get ingredient and arrange data
  const getIngredientData = () => {
    return productIngredient.map((data: any) => {
      return {
        content: 0,
        ingredientId: data._id,
        ingredientName: data.ingredient_name,
        prcentage: 0,
        unitSize: 0,
      };
    });
  };

  //when process data, ingredient and product data change then set default value
  const values = useMemo(() => {
    return {
      receipt: id == "new" ? getIngredientData() : processData?.receipe || [],
      productName: processData?.productName,
    };
  }, [processData?._id, id, productIngredientData, status == "success"]);

  const {
    register,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: receiptFields,
    append,
    remove,
  } = useFieldArray({ control, name: "receipt" });

  //check ingredint load
  useEffect(() => {
    if (status == "success" && id == "new") {
      remove();
      append(getIngredientData());
    }
  }, [status == "success"]);

  //load process data
  const loadInitialData = () => {
    if (id == "new") {
      fetchProductSalesUnit();
    } else {
      dispatch(getProcessDataById(id)).then((res) => {
        setSalesUnitId(res?.salesUnitId);
        setSalesUnitName(res?.salesUnitName);
        setProductData({
          ...productData,
          searchProduct: res?.productName,
          searchProductTitle: res?.productName,
        });
      });
    }
  };

  //check ingredient and process data load
  useEffect(() => {
    loadInitialData();
    return () => {
      reset();
      dispatch(GET_INGREDIENT_CREATE_RESPONSE_DATA([]));
    };
  }, [id]);

  //handle navigation
  const navigationOption = (status: any, id: any) => {
    switch (status) {
      case "Next":
        return navigate(`/process/processing/${id}`);
      default:
        return navigate(`/process`);
    }
  };

  //handle onSubmit
  const onSubmit = (navigateTo: any) => {
    let getValueData = getValues();
    let tempReceipt = [...getValueData.receipt];
    if (id == "new") {
      let receiptData = {
        productId: productData.searchProduct.product_id,
        productName: productData.searchProduct.product_name,
        receipe: tempReceipt,
        salesUnitId: salesUnitId,
        salesUnitName: salesUnitName,
      };
      dispatch(createReceipt(receiptData)).then((id) => {
        navigationOption(navigateTo, id);
      });
    } else {
      const filterReceiptData = tempReceipt.map(({ _id, ...rest }: any) => {
        return rest;
      });
      let receiptData = {
        _id: processData?._id,
        salesUnitId: salesUnitId,
        salesUnitName: salesUnitName,
        receipe: filterReceiptData,
      };
      dispatch(updateProcessData(receiptData, "Receipt")).then(() => {
        navigationOption(navigateTo, processData?._id);
      });
    }
  };

  //Get search by product
  const getSearchProductValue = (data: any) => {
    const productDataSet = productSalesUnitsData?.find(
      (productSalesUnit) => productSalesUnit.salesUnitId === data.value
    );
    const productId: any = productDataSet?.productId;
    const salesUnitId = productDataSet?.salesUnitId;
    const salesUnitName = productDataSet?.salesUnitName;
    setProductId(productId);
    setSalesUnitId(salesUnitId);
    setSalesUnitName(salesUnitName);

    setProductData({
      ...productData,
      searchProduct: {
        product_id: productId,
        product_name: productDataSet?.productName,
      },
    });

    dispatch(checkProductProcessData(productId, salesUnitId)).then((res) => {
      if (res?.data?.getProcessByProductId?._id || undefined) {
        navigate(`/process/recipe/${res?.data?.getProcessByProductId?._id}`);
      } else {
        navigate(`/process/recipe/new`);
      }
    });
  };

  //create dropdown data
  const productSalesUnits: DropdownOption[] = (productSalesUnitsData || []).map(
    ({ productName, salesUnitName, salesUnitId }) => ({
      key: salesUnitId,
      text: `${productName} - ${salesUnitName}`,
      value: salesUnitId,
    })
  );

  return (
    <>
      <TitleBar titleName={"Add Recipe"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={6} tablet={8} mobile={16}>
          <DropDown
            labelName="Product Name*"
            placeholder="Select Product"
            currentData={productSalesUnits}
            defaultValue={salesUnitId}
            handleChangeState={(e, data) => getSearchProductValue(data)}
            onSearchChange={(e, data) => {
              setSearchKey(data.searchQuery);
            }}
            loading={isProductSalesUnitsLoading}
            search
          />
        </Grid.Column>
      </Grid>
      <Grid className="tabbMain">
        <TabView
          tabData={ProcessTab}
          defaultSelect={0}
          addParams={true}
          paramsData={processData?._id}
          disable={id == "new" ? true : false}
        >
          <Grid.Column
            computer={16}
            tablet={16}
            mobile={16}
            className="addRecipeIngredientTitle"
          >
            {receiptFields.length > 0 ? (
              <>
                <TitleView
                  CustomTitleViewMain="packingMn"
                  title="Please add the recipe for making 1kg/L of content"
                />
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="addRecipeIngredientMain"
                >
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <p className="titleOfIngredient">Ingredient Name</p>
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <p className="titleOfIngredient">Content</p>
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <p className="titleOfIngredient">Percentage</p>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    {receiptFields.map((data: any, index: any) => {
                      return (
                        <>
                          <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={16}
                            className="paddingRemoveBottom"
                          >
                            <InputText
                              register={register}
                              placeholder="Ingredient Name"
                              name={`receipt.${index}.ingredientName`}
                              required={false}
                              disabled={true}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={3}
                            tablet={8}
                            mobile={8}
                            className="paddingRemoveBottom"
                          >
                            <InputText
                              register={register}
                              placeholder="Kg"
                              name={`receipt.${index}.content`}
                              required={true}
                              customInputValidation={true}
                              type="number"
                              valueAsNumber={true}
                              defaultValues={data.content}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={3}
                            tablet={8}
                            mobile={8}
                            className="paddingRemoveBottom"
                          >
                            <InputText
                              register={register}
                              placeholder="%"
                              name={`receipt.${index}.prcentage`}
                              required={true}
                              customInputValidation={true}
                              type="number"
                              valueAsNumber={true}
                              defaultValues={data.percentage}
                            />
                          </Grid.Column>
                        </>
                      );
                    })}
                  </Grid>
                </Grid.Column>
              </>
            ) : (
              <Message>
                <Message.Header>No any Ingredient</Message.Header>
              </Message>
            )}
          </Grid.Column>

          <div className="recipeButton">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("Main");
              }}
              customColumnStyle={"customRecipeButton"}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              onClick={() => {
                onSubmit("Next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default AddRecipe;

