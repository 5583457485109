import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";

import {
  TabView,
  InputText,
  CustomButton,
  SearchValue,
  ItemAdder,
  TitleView,
  DropDown,
  TitleBar,
} from "../../../components";
import {
  PACKAGING_MATERIALS,
  ProcessTab,
  CultivationPackagingTitle,
  CultivationSinglePackagingTitle,
} from "../../../config/constants";

import { RootState } from "../../../store/store";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";
import { useAppDispatch } from "../../../store/hooks";

import "./packing.scss";

const Packing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { processData } = useSelector((state: RootState) => state.process);

  const defaultSinglePackaging = {
    packageName: "",
    meaterialWeight: 0,
    packageMaterial: "",
  };

  const defaultPackagingMethod = {
    packagingMachine: "",
    energy: 0,
    waste: 0,
    capacity: 0
  };

  const values = useMemo(() => {
    return {
      singlePacking: [
        ...(processData?.packaging || []),
        defaultSinglePackaging,
      ],
      packagingMethod: [
        ...(processData?.packagingMachine || []),
        defaultPackagingMethod,
      ],
      contentWeight: 0,
      productName: `${processData?.productName} - ${processData?.salesUnitName}`,
      totalWeight: processData?.packaging?.reduce((x: any, y: any) => {
        return Number(x) + Number(y.meaterialWeight);
      }, 0),
    };
  }, [processData]);

  const {
    register,
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });
  const [defaultType, setDefaultType] = useState<any>(
    ""
  );

  useEffect(() => {
    setValue("contentWeight", 0);
    dispatch(getProcessDataById(id));
  }, []);

  const {
    fields: singlePackingFields,
    append: singlePackingAppend,
    remove: singlePackingRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "singlePacking" });

  const {
    fields: packagingMethodFields,
    append: packagingMethodAppend,
    remove: packagingMethodRemove,
  } = useFieldArray({ control, name: "packagingMethod" });

  const itemAddSinglePackaging = (index: any) => {
    let checkPackageName =
      getValues().singlePacking?.[index]?.packageName || "";
    let checkMeaterialWeight =
      getValues().singlePacking?.[index]?.meaterialWeight || 0;
    if (isEmpty(checkPackageName.trim())) {
      setError(`singlePacking.${index}.packageName`, {});
    } else if (checkMeaterialWeight <= 0 || isNaN(checkMeaterialWeight)) {
      setError(`singlePacking.${index}.meaterialWeight`, {});
    } else {
      singlePackingAppend(defaultSinglePackaging);
    }
  };

  const itemAddHandlePackingMethod = (index: any) => {
    let checkPackagingMachine =
      getValues().packagingMethod?.[index]?.packagingMachine || "";
    let checkEnergy = getValues().packagingMethod?.[index]?.energy || 0;
    let checkWaste = getValues().packagingMethod?.[index]?.waste || 0;
    let checkCapacity = getValues().packagingMethod?.[index]?.capacity || 0;

    if (isEmpty(checkPackagingMachine.trim())) {
      setError(`packagingMethod.${index}.packagingMachine`, {});
    } else if (checkEnergy === 0) {
      setError(`packagingMethod.${index}.energy`, {});
    } else if (checkWaste === 0) {
      setError(`packagingMethod.${index}.waste`, {});
    } else if (checkCapacity === 0) {
      setError(`packagingMethod.${index}.capacity`, {});
    } else {
      packagingMethodAppend(defaultPackagingMethod);
    }
  };

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/process/storage/${processData?._id}`);
      default:
        return navigate(`/process`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();

      let tempSinglePackaging = getValueData.singlePacking;
      let tempSinglePackagingIndex = tempSinglePackaging.length - 1;

      let tempPackagingMethod = getValueData.packagingMethod;
      let tempPackagingMethodIndex = tempPackagingMethod.length - 1;

      if (
        tempSinglePackaging?.[tempSinglePackagingIndex]?.packageName === "" &&
        tempSinglePackaging?.[tempSinglePackagingIndex]?.meaterialWeight === 0
      ) {
        tempSinglePackaging.splice(tempSinglePackagingIndex, 1);
      }

      if (
        tempPackagingMethod?.[tempPackagingMethodIndex]?.packagingMachine ===
          "" &&
        tempPackagingMethod?.[tempPackagingMethodIndex]?.energy === 0 &&
        tempPackagingMethod?.[tempPackagingMethodIndex]?.waste === 0 &&
        tempPackagingMethod?.[tempPackagingMethodIndex]?.capacity === 0
      ) {
        tempPackagingMethod.splice(tempPackagingMethodIndex, 1);
      }
      let packagingData = {
        _id: processData?._id,
        packaging: tempSinglePackaging,
        packagingMachine: tempPackagingMethod,
        userCompletedStage: "PACKAGING",
      };
      dispatch(updateProcessData(packagingData, "Packaging")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  const getTotalValue = (value?: 0, currentIndex?: any): any => {
    let currentSingleWeight = getValues()?.singlePacking?.reduce(
      (x: any, y: any, index: any) => {
        if (currentIndex == index) {
          return Number(x) + Number(value);
        } else {
          return Number(x) + Number(y.meaterialWeight);
        }
      },
      getValues()?.contentWeight || 0
    );
    return Number(currentSingleWeight);
  };

  return (
    <>
      <TitleBar titleName={"Processing Impact"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>
      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={ProcessTab}
          defaultSelect={2}
          addParams={true}
          paramsData={processData?._id}
        >
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTextTitle="customT"
              CustomTitleViewMain="packingMn"
              title="Single unit packaging"
            />
            <Grid>
              <Grid.Column computer={4} tablet={8} mobile={16}>
                <InputText
                  register={register}
                  labelName={"Content Weight(kg)"}
                  placeholder="Content Weight(kg)"
                  name="contentWeight"
                  type="number"
                  valueAsNumber={true}
                  customInputValidation={true}
                  onChangeFunction={(e: any) => {
                    setValue("totalWeight", getTotalValue(e.target.value));
                  }}
                />
              </Grid.Column>

              <Grid.Column computer={4} tablet={8} mobile={16}>
                <InputText
                  register={register}
                  labelName={"Total Weight With Packaging(kg)"}
                  placeholder="Total Weight With Packaging(kg)"
                  name="totalWeight"
                  defaultValues={0}
                  disabled={true}
                />
              </Grid.Column>
            </Grid>
            <ItemAdderTitleView titleData={CultivationPackagingTitle} />
            {singlePackingFields.map((item: any, index: any) => {
              let packageMaterialName = item?.packageMaterial || null;

              return (
                <ItemAdder
                  itemAddHandle={() => itemAddSinglePackaging(index)}
                  itemRemoveHandle={singlePackingRemove}
                  items={singlePackingFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={7}
                      tablet={7}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <InputText
                        key={index}
                        register={register}
                        errors={errors.singlePacking?.[index]?.packageName}
                        placeholder="Packaging Name"
                        name={`singlePacking[${index}].packageName`}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value.length > 0
                            ? clearErrors(`singlePacking.${index}.packageName`)
                            : setError(
                                `singlePacking.${index}.packageName`,
                                {}
                              );
                        }}
                        errorMessage={"Packaging name is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Material"
                        currentData={PACKAGING_MATERIALS}
                        defaultValue={
                          packageMaterialName
                            ? packageMaterialName
                            : defaultType
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          if (!item._id) {
                            updateValue(index, {
                              packageMaterial: value,
                              meaterialWeight:
                                getValues().singlePacking?.[index]
                                  ?.meaterialWeight,
                              packageName:
                                getValues().singlePacking?.[index]?.packageName,
                            });
                          } else {
                            setDefaultType(value);
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={5}
                      tablet={5}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.singlePacking?.[index]?.meaterialWeight}
                        placeholder="Material Weight(kg)"
                        name={`singlePacking.${index}.meaterialWeight`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          setValue(
                            "totalWeight",
                            getTotalValue(e.target.value, index)
                          );
                          e.target.value > 0
                            ? clearErrors(
                                `singlePacking.${index}.meaterialWeight`
                              )
                            : setError(
                                `singlePacking.${index}.meaterialWeight`,
                                {}
                              );
                        }}
                        errorMessage={"Material weight is required"}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Packaging Method"
            />
            <ItemAdderTitleView titleData={CultivationSinglePackagingTitle} />
            {packagingMethodFields.map((item: any, index: any) => {
              return (
                <ItemAdder
                  itemAddHandle={() => itemAddHandlePackingMethod(index)}
                  itemRemoveHandle={packagingMethodRemove}
                  items={packagingMethodFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid key={index}>
                    <Grid.Column
                      computer={7}
                      tablet={7}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={
                          errors.packagingMethod?.[index]?.packagingMachine
                        }
                        placeholder="Machine Name"
                        name={`packagingMethod[${index}].packagingMachine`}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value.length > 0
                            ? clearErrors(
                                `packagingMethod.${index}.packagingMachine`
                              )
                            : setError(
                                `packagingMethod.${index}.packagingMachine`,
                                {}
                              );
                        }}
                        errorMessage={"Machine name is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.packagingMethod?.[index]?.energy}
                        placeholder="Energy(kWh/hr)"
                        name={`packagingMethod[${index}].energy`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearErrors(`packagingMethod.${index}.energy`)
                            : setError(`packagingMethod.${index}.energy`, {});
                        }}
                        errorMessage={"Energy is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={5}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.packagingMethod?.[index]?.waste}
                        placeholder="Waste(kg/hr)"
                        name={`packagingMethod[${index}].waste`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearErrors(`packagingMethod.${index}.waste`)
                            : setError(`packagingMethod.${index}.waste`, {});
                        }}
                        errorMessage={"Waste is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={5}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.packagingMethod?.[index]?.capacity}
                        placeholder="Capacity(pk/hr)"
                        name={`packagingMethod[${index}].capacity`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearErrors(`packagingMethod.${index}.capacity`)
                            : setError(`packagingMethod.${index}.capacity`, {});
                        }}
                        errorMessage={"Capacity is required"}
                      />
                    </Grid.Column>
                  </Grid>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <div className="packingButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customRecipeButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("later");
              }}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              type={"submit"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Packing;
