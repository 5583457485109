import React from "react";

import ProcessDetails from "../screens/process/processDetails/ProcessingDetails";
import AddRecipe from "../screens/process/addRecipe/AddRecipe";
import PalletInformation from "../screens/process/palletInformation/PalletInformation";
import Processing from "../screens/process/processing/Processing";
import Packing from "../screens/process/packing/Packing";
import Energy from "../screens/process/energy/Energy";
import Storage from "../screens/process/storage/Storage";
import Confirmation from "../screens/process/confirmation/Confirmation";
import ProcessTransportation from "../screens/process/transportation/Transportation";
import { adminAccess, userAccess } from "./useRoleAccess";

export const ProcessRoutes = [
  {
    path: "/process",
    route: <ProcessDetails />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "recipe/:id",
    route: <AddRecipe />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "processing/:id",
    route: <Processing />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "packing/:id",
    route: <Packing />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "pallet/:id",
    route: <PalletInformation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "energy/:id",
    route: <Energy />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "storage/:id",
    route: <Storage />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "transportation/:id",
    route: <ProcessTransportation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "confirmation/:id",
    route: <Confirmation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
