import gql from "graphql-tag";

export const GET_CULTIVATION_LIST = gql`
  query GetCultivationList {
    getAllCultivations {
      _id
      ingredientName
      supplierName
    }
  }
`;

export const CHECK_INGREDIENT_CULTIVATION = gql`
  query getCultivationByIngredientId($ingredientId: String!, $supplierId: String) {
    getCultivationByIngredientId(ingredientId: $ingredientId, supplierId: $supplierId) {
      _id
    }
  }
`;

export const GET_SINGLE_CULTIVATION = gql`
  query getCultivationById($cultivationId: String!) {
    getCultivationById(cultivationId: $cultivationId) {
      _id
      processing {
        _id
        associateIngredeient {
          _id
          content
          ingredientId
          ingredientName
          unit
        }
        cipSip {
          chemicalConcentration
          chemicalConsumption
          chemicalUse
          energyConsumption
          frequency
          manufacturer
          modelNumber
          steamFlow
          steamPressure
        }
        capacity
        citricAcid
        electricity
        facilityName
        heat
        input
        output
        machineModelNo
        processNumber
        processingName
        processingType
        sideStream
        water
      }
      cultivationField {
        _id
        fertilizerList {
          _id
          fertilizerName
          fertilizerQuantity
        }
        growthArea
        harvestSeason
        harvestYear
        persticideList {
          _id
          pesticideName
          presticideQuantity
        }
        yield
      }
      farmingType
      originCountry
      produceCultivar
      ingredientId
      ingredientName
      supplierId
      supplierName
      produceType
      productType
      cultivationField {
        _id
        fertilizerList {
          _id
          fertilizerName
          fertilizerQuantity
        }
        growthArea
        harvestSeason
        harvestYear
        persticideList {
          _id
          pesticideName
          presticideQuantity
        }
        yield
      }
      storage {
        _id
        areaUtilization
        electricity
        warehouseId
        warehouseName
        warehouseSize
        weight
        temperature
      }
      packaging {
        _id
        meaterialWeight
        packageMaterial
        packageName
      }
      packagingMethod {
        _id
        energy
        packagingMethod
        waste
        capacity
      }
      internalTransportation {
        _id
        averageDistance
        transportType
      }
      externalTransportation {
        _id
        distance
        from
        to
        transportType
      }
      energy {
        _id
        type
        kwh
        precentage
      }
    }
  }
`;

export const UPDATE_CULTIVATION = gql`
  mutation updateCultivation(
    $_id: String!
    $processing: [CultivationProcessingDto!]
    $cultivationField: CultivationFieldDto
    $farmingType: String
    $originCountry: String
    $ingredientId: String
    $ingredientName: String
    $produceCultivar: String
    $produceType: String
    $productType: String
    $status: String
    $storage: [CultivationStorageDto!]
    $packaging: [CultivationPackagingsDto!]
    $energy: [CultivationEnergyDto!]
    $packagingMethod: [CultivationPackagingMethodDto!]
    $internalTransportation: [CultivationTransportationDto!]
    $externalTransportation: [CultivationExternalTransportationDto!]
    $userCompletedStage: String
  ) {
    updateCultivation(
      updateCultivation: {
        _id: $_id
        processing: $processing
        cultivationField: $cultivationField
        farmingType: $farmingType
        originCountry: $originCountry
        productType: $productType
        storage: $storage
        packaging: $packaging
        status: $status
        ingredientId: $ingredientId
        ingredientName: $ingredientName
        produceCultivar: $produceCultivar
        produceType: $produceType
        energy: $energy
        packagingMethod: $packagingMethod
        internalTransportation: $internalTransportation
        externalTransportation: $externalTransportation
        userCompletedStage: $userCompletedStage
      }
    ) {
      _id
    }
  }
`;

export const CREATE_CULTIVATION = gql`
  mutation createCultivation(
    $farmingField: String!
    $farmingType: String!
    $ingredientId: String!
    $ingredientName: String!
    $originCountry: String!
    $produceCultivar: String!
    $produceType: String!
    $supplierId: String
    $supplierName: String
  ) {
    createCultivation(
      createCultivation: {
        farmingField: $farmingField
        farmingType: $farmingType
        ingredientId: $ingredientId
        ingredientName: $ingredientName
        originCountry: $originCountry
        produceCultivar: $produceCultivar
        produceType: $produceType
        supplierId: $supplierId
        supplierName: $supplierName
      }
    ) {
      _id
    }
  }
`;
