import { GraphQLInputObjectType, GraphQLString, buildSchema } from "graphql";
import gql from "graphql-tag";

export const CREATE_PRODUCT_WITH_IMAGE = gql`
  mutation CreateNewProduct(
    $product_name: String!
    $product_type: String
    $registration_number: String
    $product_image: Upload!
    $product_other_info: String
    $product_ingredients: [ProductIngredeients!]!
    $createdById: String
    $createdByName: String
    $sales_units: [ProductSizes!]!
  ) {
    createNewProduct(
      createProduct: {
        product_name: $product_name
        product_type: $product_type
        registration_number: $registration_number
        product_image: $product_image
        product_other_info: $product_other_info
        product_ingredients: $product_ingredients
        createdById: $createdById
        createdByName: $createdByName
        sales_units: $sales_units
      }
    ) {
      _id
      product_name
      product_type
    }
  }
`;

export const CREATE_PRODUCT_WITHOUT_IMAGE = gql`
  mutation CreateNewProduct(
    $product_name: String!
    $product_type: String
    $registration_number: String
    $product_other_info: String
    $product_ingredients: [ProductIngredeients!]!
    $createdById: String
    $createdByName: String
    $sales_units: [ProductSizes!]!
  ) {
    createNewProduct(
      createProduct: {
        product_name: $product_name
        product_type: $product_type
        registration_number: $registration_number
        product_other_info: $product_other_info
        product_ingredients: $product_ingredients
        createdById: $createdById
        createdByName: $createdByName
        sales_units: $sales_units
      }
    ) {
      _id
      product_name
      product_type
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($_id: String, $tenant_id: String, $product_status: String) {
    getAllProducts(
      search: {
        tenant_id: $tenant_id
        _id: $_id
        product_status: $product_status
      }
    ) {
      _id
      product_name
      climate_impact
      product_type
      registration_number
      tenant_id
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query getProductById($_id: String, $product_status: String) {
    getProductById(search: { _id: $_id, product_status: $product_status }) {
      _id
      product_name
      registration_number
      product_type
      product_other_info
      product_image_path
      climate_impact
      product_status
      product_ingredients {
        ingredient_id
        ingredient_name
        unit_size
        unit_type
        ean_barcode
        data_type
        ingredient_type
      }
      sales_units {
        _id
        sales_unit_name
        sales_unit_size
        sales_unit_type
        ean_barcode
        climate_impact
        sales_unit_status
      }
    }
  }
`;

export const CHECK_PRODUCT_PROCESS = gql`
  query getProcessByProductId($productId: String!, $salesUnitId: String!) {
    getProcessByProductId(productId: $productId, salesUnitId: $salesUnitId) {
      _id
    }
  }
`;

export const UPDATE_PRODUCT_DETAILS = gql`
  mutation UpdateProduct(
    $_id: String!
    $product_name: String!
    $product_type: String
    $registration_number: String
    $product_other_info: String
  ) {
    UpdateProduct(
      updateProduct: {
        _id: $_id
        product_name: $product_name
        product_type: $product_type
        registration_number: $registration_number
        product_other_info: $product_other_info
        product_status: "ACTIVE"
      }
    ) {
      _id
      product_name
    }
  }
`;

export const REMOVE_PRODUCT_IMAGE = gql`
  mutation DeleteProductImage($_id: String!, $filePath: String!) {
    DeleteProductImage(DeleteProductImage: { _id: $_id, filePath: $filePath }) {
      _id
    }
  }
`;

export const ADD_PRODUCT_SALES_UNITS = gql`
  mutation AddingNewProductSalesUnit(
    $product_id: String!
    $sales_units: [NewSalesUnit!]!
  ) {
    AddingNewProductSalesUnit(
      NewSalesUnits: { product_id: $product_id, sales_units: $sales_units }
    ) {
      product_name
    }
  }
`;

export const UPDATE_PRODUCT_IMAGE = gql`
  mutation UpdateProductImage(
    $_id: String!
    $product_name: String!
    $filePath: String!
    $product_image: Upload!
    $tenant_id: String!
    $tenat_name: String!
    $updatedById: String!
    $updatedByName: String!
  ) {
    UpdateProductImage(
      UpdateProductImage: {
        _id: $_id
        product_name: $product_name
        filePath: $filePath
        product_image: $product_image
        tenant_id: $tenant_id
        tenat_name: $tenat_name
        updatedById: $updatedById
        updatedByName: $updatedByName
      }
    ) {
      product_name
    }
  }
`;

export const UPDATE_PRODUCT_SIZE_UNIT = gql`
  mutation UpdateSelectedSalesUnitOfProduct(
    $product_id: ID!
    $sale_unit_id: ID!
    $sales_unit_name: String!
    $sales_unit_size: String!
    $sales_unit_type: String!
    $ean_barcode: String!
    $sales_unit_status: String!
  ) {
    UpdateSelectedSalesUnitOfProduct(
      UpdateSalesUnit: {
        product_id: $product_id
        sale_unit_id: $sale_unit_id
        sales_unit_name: $sales_unit_name
        sales_unit_size: $sales_unit_size
        sales_unit_type: $sales_unit_type
        ean_barcode: $ean_barcode
        sales_unit_status: $sales_unit_status
      }
    ) {
      _id
      sales_units {
        sales_unit_status
        sales_unit_name
        sales_unit_size
        sales_unit_type
        ean_barcode
      }
    }
  }
`;

export const DELETE_PRODUCT_DETAILS = gql`
  mutation UpdateProduct(
    $_id: String!
    $product_name: String!
    $product_status: String
  ) {
    UpdateProduct(
      updateProduct: {
        _id: $_id
        product_name: $product_name
        product_status: $product_status
      }
    ) {
      _id
      product_name
    }
  }
`;
