import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../types/ProductTypes";

interface IngredientState {
  ingredients?: [];
  products?: [];
  suppliers?: [];
  ingredientsWithSupplier?: [];
  ingredientSuppliers?: [];
  ingredientProducts?: [];
  ingredientName?: "";
  ingredientPreEditData?: any;
  ingredientCreateResponseData?: any;
  allIngredient?: [];
}

const initialState: IngredientState = {
  ingredients: [],
  products: [],
  suppliers: [],
  ingredientsWithSupplier: [],
  ingredientSuppliers: [],
  ingredientProducts: [],
  ingredientName: "",
  ingredientPreEditData: {},
  ingredientCreateResponseData: [],
  allIngredient: [],
};

export const ingredientSlice = createSlice({
  name: "ingredient",
  initialState,
  reducers: {
    RESET: () => initialState,
    GET_INGREDIENTS_DATA: (state, action) => {
      state.ingredients = action.payload;
    },
    GET_INGREDIENT_CREATE_RESPONSE_DATA: (state, action) => {
      state.ingredientCreateResponseData = action.payload;
    },
    GET_PRODUCTS_SEARCH_DATA: (state, action) => {
      state.products = action.payload;
    },
    GET_SUPPLIERS_SEARCH_DATA: (state, action) => {
      state.suppliers = action.payload;
    },
    GET_INGREDIENTS_SEARCH_DATA: (state, action) => {
      state.ingredientsWithSupplier = action.payload;
    },
    GET_INGREDIENT_DATA_BY_ID: (state, action) => {
      state.ingredientSuppliers = action.payload.suppliers;
      state.ingredientProducts = action.payload.products;
      state.ingredientName = action.payload.ingredient_name;
    },
    GET_AN_INGREDIENT_DATA: (state, action) => {
      state.ingredientPreEditData = action.payload;
    },
    GET_ALL_INGREDIENT_AVAILABLE: (state, action) => {
      state.allIngredient = action.payload;
    },
  },
});

export const {
  RESET,
  GET_INGREDIENTS_DATA,
  GET_PRODUCTS_SEARCH_DATA,
  GET_SUPPLIERS_SEARCH_DATA,
  GET_INGREDIENTS_SEARCH_DATA,
  GET_INGREDIENT_DATA_BY_ID,
  GET_AN_INGREDIENT_DATA,
  GET_INGREDIENT_CREATE_RESPONSE_DATA,
  GET_ALL_INGREDIENT_AVAILABLE,
} = ingredientSlice.actions;
export default ingredientSlice.reducer;
