import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import {
  TabView,
  DropDown,
  InputText,
  CustomButton,
  SearchValue,
  ItemAdder,
  TitleView,
  TitleBar,
  CommonContainer,
} from "../../../components";
import {
  CultivationEnergyTitle,
  CultivationTab,
  EnergyTypeListData,
  ProcessTab,
} from "../../../config/constants";

import "./enegry.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";
import {
  getCultivationDataById,
  updateCultivationData,
} from "../../../store/actions/cultivation";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";

const Energy = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { cultivationData } = useSelector(
    (state: RootState) => state.cultivation
  );

  let defaultEnergyDetails = {
    type: EnergyTypeListData[0].text,
    kwh: 0,
    precentage: 0,
  };

  const values = useMemo(() => {
    return {
      energy: [...(cultivationData?.energy || []), defaultEnergyDetails],
      ingredientName: cultivationData?.ingredientName,
    };
  }, [cultivationData]);

  useEffect(() => {
    dispatch(getCultivationDataById(id));
  }, []);

  const {
    register,
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: energyFields,
    append: energyAppend,
    remove: energyRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "energy" });

  const [defaultType, setDefaultType] = useState<any>(
    ""
  );

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/cultivation/storage/${cultivationData?._id}`);
      default:
        return navigate(`/cultivation`);
    }
  };

  const energyItemAdd = (index: any) => {
    let resultsKWh = getValues().energy?.[index]?.kwh || null;
    let resultsPercentage = getValues().energy?.[index]?.precentage || null;

    if (!resultsKWh && !resultsPercentage) {
      setError(`energy.${index}.kwh`, {});
      setError(`energy.${index}.precentage`, {});
    } else {
      energyAppend(defaultEnergyDetails);
    }
  };

  const clearError = (index: any) => {
    clearErrors(`energy.${index}.kwh`);
    clearErrors(`energy.${index}.precentage`);
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();

      let tempEnergy = getValueData.energy;
      let tempEnergyIndex = tempEnergy.length - 1

      if(tempEnergy?.[tempEnergyIndex]?.kwh === 0 && tempEnergy?.[tempEnergyIndex]?.precentage === 0) {
        tempEnergy.splice(tempEnergyIndex, 1)
      }

      const filterTempEnergy = tempEnergy.map(({ _id, ...rest }: any) => {
        if(_id === null || _id === "") {
          return rest;

        } else {
          return {
            _id,
            ...rest
          }
        }
      }
    );
      
      let energyData = {
        _id: cultivationData?._id,
        energy: filterTempEnergy,
        userCompletedStage: "ENERGY",
      };
      dispatch(updateCultivationData(energyData, "Energy")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  return (
    <>
      <TitleBar titleName={"Cultivation Energy"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText
            register={register}
            name="ingredientName"
            disabled={true}
          />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={CultivationTab}
          defaultSelect={4}
          addParams={true}
          paramsData={cultivationData?._id}
        >
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Insert the proportion of your energy mix"
            />

            <ItemAdderTitleView titleData={CultivationEnergyTitle} />
            {energyFields.map((item, index) => {
              let energyTypeValue = item?.type || null;
              return (
                <ItemAdder
                  itemAddHandle={() => energyItemAdd(index)}
                  itemRemoveHandle={energyRemove}
                  items={energyFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={7}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveLeft paddingRemoveVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Energy Type"
                        currentData={EnergyTypeListData}
                        defaultValue={
                          energyTypeValue ? energyTypeValue : defaultType
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          updateValue(index, {
                            ...item,
                            type: value,
                          });
                          setDefaultType(value);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.energy?.[index]?.kwh}
                        placeholder="KWh"
                        name={`energy.${index}.kwh`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearError(index)
                            : setError(`energy.${index}.kwh`, {});
                        }}
                        errorMessage={"kwh is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={5}
                      tablet={16}
                      mobile={16}
                      className="paddingRemoveVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.energy?.[index]?.precentage}
                        placeholder="Percentage"
                        name={`energy.${index}.precentage`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearError(index)
                            : setError(`energy.${index}.precentage`, {});
                        }}
                        errorMessage={"precentage is required"}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <div className="enegryButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customREnegryButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("late");
              }}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              type={"submit"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Energy;
