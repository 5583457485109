import React, { memo, useContext } from "react";
import { Handle, Position, useStore } from "reactflow";
import "./CustomNode.scss";
import {
  generalNodes,
  farmGateNodes,
  processGateNodes,
} from "../../../config/drawingConstants";
import { DrawingContext } from "../DrawingTool";

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;
interface CustomNodeProps {
  id: string;
  data: any;
  isConnectable: boolean;
  selected: boolean;
}

const sourceStyle = { zIndex: 1, backgroundColor: "#3FBE5A" };

export default memo((props: CustomNodeProps) => {
  const drawingContext = useContext(DrawingContext);
  const { data } = props;
  const nodeTypes = [...generalNodes, ...farmGateNodes, ...processGateNodes];
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isConnecting = !!connectionNodeId;
  return (
    <>
      <div className="node-item">
        <div className="node-header">
          <span>{data.label}</span>
        </div>

        {!isConnecting && (
          <Handle
            className="custom-handle"
            position={Position.Left}
            type="source"
            style={sourceStyle}
          />
        )}
        <Handle
          className="custom-handle"
          position={Position.Left}
          type="target"
          style={{ backgroundColor: "#3FBE5A" }}
        />
        <div className="node-item-image">
          <img
            src={nodeTypes.find((n) => n.id === data.icon)?.image}
            loading="lazy"
            alt="images"
          />
        </div>
        <div
          className="node-footer"
          onClick={() => {
            drawingContext.setNodeItem(props.id);
          }}
        >
          <span>{data.description || "Please Add new description"}</span>
        </div>
      </div>
    </>
  );
});
