import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";

import {
  TabView,
  DropDown,
  InputText,
  CustomButton,
  ItemAdder,
  TitleView,
  TitleBar,
} from "../../../components";
import { ProcessTab, TransportationType, CultivationInternalTransportationTitle, CultivationFromTransportationTitle } from "../../../config/constants";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";

import "./Transportation.scss";
import { isEmpty } from "lodash";
import { useAppDispatch } from "../../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";

const Transportation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { processData } = useSelector((state: RootState) => state.process);

  useEffect(() => {
    dispatch(getProcessDataById(id));
  }, []);

  const defaultInternalTransportation = {
    averageDistance: 0,
    transportType: "",
    _id: null,
  };

  const defaultExternalTransportation = {
    distance: 0,
    from: "",
    to: "",
    transportType: "",
    _id: null,
  };

  const values = useMemo(() => {
    return {
      internalTransport: [
        ...(processData?.internalTransport || []),
        defaultInternalTransportation,
      ],
      externalTransport: [
        ...(processData?.externalTransport || []),
        defaultExternalTransportation,
      ],
      productName: `${processData?.productName} - ${processData?.salesUnitName}`,
    };
  }, [processData]);

  const {
    register,
    control,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: internalTransportFields,
    append: internalTransportAppend,
    remove: internalTransportRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "internalTransport" });

  const {
    fields: externalTransportFields,
    append: externalTransportAppend,
    remove: externalTransportRemove,
    update: updateExternal,
  } = useFieldArray({ control, name: "externalTransport" });

  const [defaultTypeInternal, setDefaultTypeInternal] = useState<any>(
    ""
  );
  const [defaultTypeExternal, setDefaultTypeExternal] = useState<any>(
    ""
  );

  const internalTransportationAdd = (index: any) => {
    let checkAverageDistance = getValues().internalTransport?.[index]?.averageDistance || 0;
    if (checkAverageDistance === 0) {
      setError(`internalTransport.${index}.averageDistance`, {});
    } else {
      internalTransportAppend(defaultInternalTransportation);

    }
    
  };

  const externalTransportationAdd = (index: any) => {
    let checkDistance = getValues().externalTransport?.[index]?.distance || 0;

    if (checkDistance === 0) {
      setError(`externalTransport.${index}.distance`, {});
    } else {
      externalTransportAppend(defaultExternalTransportation);
    }
    
  };

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/process/confirmation/${processData?._id}`);
      default:
        return navigate(`/process`);
    }
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();

      let tempInternalTransportation = getValueData.internalTransport;
      let tempInternalTransportationIndex = tempInternalTransportation.length - 1

      let tempExternalTransportation = getValueData.externalTransport;
      let tempExternalTransportationIndex = tempExternalTransportation.length - 1

      if(tempInternalTransportation?.[tempInternalTransportationIndex]?.averageDistance === 0 && tempInternalTransportation?.[tempInternalTransportationIndex]?.transportType === '') {
        tempInternalTransportation.splice(tempInternalTransportationIndex, 1);
      }

      if(tempExternalTransportation?.[tempExternalTransportationIndex]?.from === '' && tempExternalTransportation?.[tempExternalTransportationIndex]?.to === '' && tempExternalTransportation?.[tempExternalTransportationIndex]?.distance === 0) {
        tempExternalTransportation.splice(tempExternalTransportationIndex, 1);
      }

      const filterInternalTransportation = tempInternalTransportation.map(
        ({ _id, ...rest }: any) => {
          if(_id === null || _id === "") {
            return rest;

          } else {
            return {
              _id,
              ...rest
            }
          }
        }
      );

      const filterExternalTransportation = tempExternalTransportation.map(
        ({ _id, ...rest }: any) => {
          if(_id === null || _id === "") {
            return rest;

          } else {
            return {
              _id,
              ...rest
            }
          }
        }
      );

      let energyData = {
        _id: processData?._id,
        internalTransport: filterInternalTransportation,
        externalTransport: filterExternalTransportation,
        userCompletedStage: "TRANSPORTATION",
      };
      dispatch(updateProcessData(energyData, "Transport")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  return (
    <>
      <TitleBar titleName={"Processing Transport"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={ProcessTab}
          defaultSelect={5}
          addParams={true}
          paramsData={processData?._id}
        >
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Internal Transportation"
            />
              <ItemAdderTitleView titleData={CultivationInternalTransportationTitle} />
            {internalTransportFields.map((item, index) => {
              let transportTypeInternal = item?.transportType || null;

              return (
                <ItemAdder
                  itemAddHandle={() => internalTransportationAdd(index)}
                  itemRemoveHandle={internalTransportRemove}
                  items={internalTransportFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={6}
                      tablet={5}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <InputText
                        register={register}
                        errors={
                          errors.internalTransport?.[index]?.averageDistance
                        }
                        onChangeFunction={(e: any) => {
                          
                          e.target.value > 0
                            ? clearErrors(`internalTransport.${index}.averageDistance`)
                            : setError(
                                `internalTransport.${index}.averageDistance`,
                                {}
                              );
                        }}
                        placeholder="Average Distance"
                        name={`internalTransport.${index}..averageDistance`}
                        type="number"
                        valueAsNumber={true}
                        customInputValidation={true}
                        errorMessage={"Average Diastance should be greater than 0"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={7}
                      tablet={7}
                      mobile={16}
                      className="packingVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Transportation Type"
                        currentData={TransportationType}
                        defaultValue={
                          transportTypeInternal
                            ? transportTypeInternal
                            : defaultTypeInternal
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          if (!item?._id) {
                            updateValue(index, {
                              transportType: value,
                              averageDistance:
                                getValues().internalTransport?.[index]
                                  ?.averageDistance,
                              _id: item?._id || null,
                            });
                          } else {
                            setDefaultTypeInternal(value);
                          }
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Transportation From Supplier"
            />
            <ItemAdderTitleView titleData={CultivationFromTransportationTitle} />
            {externalTransportFields.map((item, index) => {
              let transportTypeExternal = item?.transportType || null;
              return (
                <ItemAdder
                  itemAddHandle={() => externalTransportationAdd(index)}
                  itemRemoveHandle={externalTransportRemove}
                  items={externalTransportFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={6}
                      tablet={6}
                      mobile={16}
                      className="packingLeftView packingVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Transportation Type"
                        currentData={TransportationType}
                        defaultValue={
                          transportTypeExternal
                            ? transportTypeExternal
                            : defaultTypeExternal
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          if (!item?._id) {
                            updateExternal(index, {
                              transportType: value,
                              from: getValues().externalTransport?.[index]
                                ?.from,
                              to: getValues().externalTransport?.[index]?.to,
                              distance:
                                getValues().externalTransport?.[index]
                                  ?.distance,
                              _id: "",
                            });
                          } else {
                            setDefaultTypeExternal(value);
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        placeholder="From"
                        name={`externalTransport[${index}].from`}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={4}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        placeholder="To"
                        name={`externalTransport[${index}].to`}
                        customInputValidation={true}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={3}
                      mobile={16}
                      className="packingVertical"
                    >
                      <InputText
                        register={register}
                        placeholder="Distance(km)"
                        errors={errors.externalTransport?.[index]?.distance}
                        name={`externalTransport[${index}].distance`}
                        onChangeFunction={(e: any) => {
                          
                          e.target.value > 0
                            ? clearErrors(`externalTransport.${index}.distance`)
                            : setError(
                                `externalTransport.${index}.distance`,
                                {}
                              );
                        }}
                        type="number"
                        valueAsNumber={true}
                        customInputValidation={true}
                        errorMessage={"Diastance should be greater than 0"}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <div className="packingButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customRecipeButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("last");
              }}
            />
            <CustomButton
              onClick={() => {
                onSubmit("next");
              }}
              theme="green"
              title={"Next"}
              type={"submit"}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Transportation;
