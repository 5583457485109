export default {
  //common
  mainColor: "#71a950",
  mainTextColor: "#535E6D",
  mainTextInputHeader: "#535E6D",
  mainTextInptColor: "#707070",
  mainTextInputBorderColor: "#d2d7e0",
  //menu bar
  menuBarIconActive: "#71a950",
  menuBarIconDeactive: "#535E6D",
  //table
  tableHeaderColor: "#C9DDBD",
  //product summary
  pecentageSub: "#C9DDBD",
  //product summary header
  productSummaryTab:"#C9DDBD",
  activeProductTab:"#ffffff",
  cardHeader:"#267C38",
  nodeCardColor:"#F9F9F4",
  cardIconColor:"#277C38",
  digramSilder:"#FFFFFF",
  drawerSearchView:"#C9DDBD",
  drawingIconsSepia: "40%",
  drawingIconsHueRotate: "0deg",
};