import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  usersData: any;
  userDetails?: any;
}

const initialState: AuthState = {
  userDetails: null,
  usersData: [],
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    LOG_OUT: () => initialState,
    SET_USER_DATA: (state, action) => {
      state.usersData = action.payload;
    },
    SET_USER_DATA_BY_ID: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const { LOG_OUT, SET_USER_DATA, SET_USER_DATA_BY_ID } = users.actions;
export default users.reducer;
