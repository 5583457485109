import { GraphQLInputObjectType, GraphQLString, buildSchema } from "graphql";
import gql from "graphql-tag";

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    UserLogin(userLogin: { email: $email, password: $password }) {
      Session
      ChallengeName
      ChallengeParameters {
        USER_ID_FOR_SRP
        userAttributes
        userAttributes
      }
      AuthenticationResult {
        AccessToken
        ExpiresIn
        TokenType
        RefreshToken
        IdToken
      }
    }
  }
`;

export const DASHBOARD_LINK = gql`
  query GetQSDashboardLink {
    getQSDashboardLink {
      url
    }
  }
`;
