import { createSlice } from "@reduxjs/toolkit";

interface ClientState {
  allClientProducts?: [];
  aClientData?: any;
}

const initialState: ClientState = {
  allClientProducts: [],
  aClientData: {}
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    RESET: () => initialState,
    GET_CLIENT_PRODUCTS_DATA: (state, action) => {
      state.allClientProducts = action.payload;
    },
    GET_CLIENT_DATA_BY_ID: (state, action) => {
      state.aClientData = action.payload;
    },
  },
});

export const {
  RESET,
  GET_CLIENT_PRODUCTS_DATA,
  GET_CLIENT_DATA_BY_ID
} = clientSlice.actions;
export default clientSlice.reducer;
