import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Popup } from "semantic-ui-react";

import { MENU_SIDE_BAR } from "../../config/constants";
import { ImageView } from "../ImageView/ImageView";
import useWindowDimensions from "../../hooks/windowsSize";

import { useAppDispatch } from "../../store/hooks";

import "./menuBar.scss";
import { getUserRolesInLocal } from "../../utils/cacheStorage";
import PermissionsGate from "../PermissionGate";
import { SCOPES } from "../../config/permission-maps";

const MenuBar = ({ modalStatus = () => {} }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const allowedRoles = useMemo(
    () => getUserRolesInLocal(),
    [getUserRolesInLocal]
  );

  var currentPath = location.pathname.split("/")[1];
  const { height } = useWindowDimensions();

  let currentAvailablePath = currentPath;

  return (
    <div onMouseEnter={() => modalStatus()}>
      <div
        className="mainMenuView"
        style={{
          height: height,
        }}
      >
        {MENU_SIDE_BAR.map((data, mainIndex) => {
          return (
            <div key={mainIndex} className="mainBarView">
              {data.subTitle.map((sub, subIndex) => {
                return (
                  <PermissionsGate scopes={[SCOPES.canAccessInSideMenu]} feature={sub.name}>
                  <Popup
                    key={subIndex}
                    content={sub.titleName}
                    trigger={
                      <div
                        className="sideBarIconDefault"
                        onClick={() => navigate(sub.path)}
                      >
                        <ImageView
                          addRandomId={false}
                          customImageView={
                            currentAvailablePath == sub.path
                              ? "menuBarActiveColor"
                              : "menuBarIconView"
                          }
                          src={sub.icon}
                        />
                      </div>
                    }
                  />
                  </PermissionsGate>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuBar;
