import { apolloClient } from "../../graphql/client";
import {
  UPDATE_PROCESS,
  CREATE_PROCESS,
  GET_SINGLE_PROCESS,
  GET_PROCESS_LIST,
} from "../../graphql/process/process-graphql";
import {
  GET_SINGLE_PROCESS_DATA,
  PROCESS_LIST_DATA,
  SET_ADDED_PROCESS_DATA,
  SET_PROCESS_DETAILS,
  GET_A_PROCESS,
} from "../slicers/process";
import { errorView, successMessage } from "../../helpers/ErrorHandler";

export const getProcessList = () => async (dispatch: any) => {
  const response = await apolloClient.query({
    query: GET_PROCESS_LIST,
  });
  dispatch(PROCESS_LIST_DATA(response?.data?.getProcessesList));
};

export const getProcessDataById = (processId: any) => async (dispatch: any) => {
  const response = await apolloClient.query({
    query: GET_SINGLE_PROCESS,
    variables: {
      processId: processId,
    },
  });
  dispatch(GET_SINGLE_PROCESS_DATA(response?.data?.getProcess));
  return response?.data?.getProcess;
};

export const getSingleProcessDataById =
  (processId: any) => async (dispatch: any) => {
    const response = await apolloClient.query({
      query: GET_SINGLE_PROCESS,
      variables: {
        processId: processId,
      },
    });
    dispatch(GET_A_PROCESS(response?.data?.getProcess));
    return true;
  };

export const createProcess = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_PROCESS,
      variables: data,
    });
    successMessage("Process added successfully");
    return response?.data?.UpdateProcess?.processing;
    // dispatch(getSingleProcessDataById(data._id));
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const deleteProcess = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_PROCESS,
      variables: data,
    });
    const newProcessList = response?.data?.UpdateProcess;
    dispatch(SET_ADDED_PROCESS_DATA(newProcessList));
    dispatch(getProcessList());
    successMessage("Process delete successfully");
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const createReceipt = (data: any) => async (dispatch: any) => {
  const response = await apolloClient.mutate({
    mutation: CREATE_PROCESS,
    variables: {
      productId: data.productId,
      productName: data.productName,
      receipe: data.receipe,
      salesUnitId: data.salesUnitId,
      salesUnitName: data.salesUnitName
    },
  });
  successMessage("add receipt data successfully");
  return response.data.createProcess._id;
  //   dispatch(getSuppliersData());
};

export const updateProcessData =
  (data: any, status: any) => async (dispatch: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_PROCESS,
        variables: data,
      });
      const newProcessList = response?.data?.UpdateProcess;
      dispatch(SET_ADDED_PROCESS_DATA(newProcessList));
      successMessage(`Update ${status} successfully`);
      return response?.data?.UpdateProcess;
    } catch ({ message }: any) {
      errorView(message);
    }
  };
