import React from "react";
import { Grid, Table } from "semantic-ui-react";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { isArray } from "lodash";

export const LoadTableData = ({
  chemicalData,
  chemicalDataType,
  updateChemicalData,
  deleteDataAction,
}: any) => {
  return (
    <>
      {isArray(chemicalData)
        ? chemicalData?.map((data: any, index: any) => {
            const addedChemical = chemicalDataType?.find((chemical: any) => chemical.value == data?.chemicalName)
            return (
              <Table.Row className="tbleR" key={index}>
                <Table.Cell>
                  <p>{addedChemical?.text || ""}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{data?.amount}</p>
                </Table.Cell>
                <Table.Cell>
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdModeEditOutline
                        cursor={"pointer"}
                        onClick={() => {
                          updateChemicalData(data, chemicalDataType);
                        }}
                        size={24}
                        color="var(--mainColor)"
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdDeleteOutline
                        cursor={"pointer"}
                        size={24}
                        color="var(--mainColor)"
                        onClick={() => {
                          deleteDataAction(data);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            );
          })
        : null}
    </>
  );
};

export const LoadTransportTableData = ({
  chemicalTransportData,
  TransportTypeList,
  updateChemicalTransportData,
  deleteDataTransportationAction,
}: any) => {
  return (
    <>
      {isArray(chemicalTransportData)
        ? chemicalTransportData.map((data: any, index: any) => {
            return (
              <Table.Row className="tbleR" key={index}>
                <Table.Cell>
                  <p>{TransportTypeList[data?.transportType].text}</p>
                </Table.Cell>
                <Table.Cell>
                  <p>{data?.distance}</p>
                </Table.Cell>
                <Table.Cell>
                  <Grid>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdModeEditOutline
                        cursor={"pointer"}
                        onClick={() => {
                          updateChemicalTransportData(data);
                        }}
                        size={24}
                        color="var(--mainColor)"
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={16}
                      mobile={16}
                      key={index}
                      className="alignTableIcon"
                    >
                      <MdDeleteOutline
                        cursor={"pointer"}
                        size={24}
                        color="var(--mainColor)"
                        onClick={() => {
                          deleteDataTransportationAction(data);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Table.Cell>
              </Table.Row>
            );
          })
        : null}
    </>
  );
};