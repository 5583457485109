import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "semantic-ui-react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { isEmpty } from "lodash";

import {
  TabView,
  DropDown,
  InputText,
  CustomButton,
  SearchValue,
  ItemAdder,
  TitleView,
  TitleBar,
} from "../../../components";
import { EnergyTypeListData, ProcessTab, CultivationEnergyTitle } from "../../../config/constants";
import { ItemAdderTitleView } from "../../../components/itemAdder/ItemAdderTitleView";

import "./enegry.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useAppDispatch } from "../../../store/hooks";
import {
  getProcessDataById,
  updateProcessData,
} from "../../../store/actions/process";

const Energy = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  let { processData } = useSelector((state: RootState) => state.process);

  let defaultEnergyDetails = {
    energyType: "",
    kWh: 0,
    percentage: 0,
  };

  const values = useMemo(() => {
    return {
      energy: [...(processData?.energyGrid || []), defaultEnergyDetails],
      productName: `${processData?.productName} - ${processData?.salesUnitName}`,
    };
  }, [processData]);

  useEffect(() => {
    dispatch(getProcessDataById(id));
  }, []);

  const {
    register,
    control,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  const {
    fields: energyFields,
    append: energyAppend,
    remove: energyRemove,
    update: updateValue,
  } = useFieldArray({ control, name: "energy" });

  const [defaultType, setDefaultType] = useState<any>(
    ""
  );

  const navigationOption = (status: any) => {
    switch (status) {
      case "next":
        return navigate(`/process/transportation/${processData?._id}`);
      default:
        return navigate(`/process`);
    }
  };

  const energyItemAdd = (index: any) => {
    let resultsKWh = getValues().energy?.[index]?.kWh || null;
    let resultsPercentage = getValues().energy?.[index]?.percentage || null;

    if (!resultsKWh && !resultsPercentage) {
      setError(`energy.${index}.kWh`, {});
      setError(`energy.${index}.percentage`, {});
    } else {
      energyAppend(defaultEnergyDetails);
    }
  };

  const clearError = (index: any) => {
    clearErrors(`energy.${index}.kWh`);
    clearErrors(`energy.${index}.percentage`);
  };

  const onSubmit = (navigateStatus: any) => {
    if (isEmpty(errors)) {
      let getValueData = getValues();

      let tempEnergy = getValueData.energy;
      let tempEnergyIndex = tempEnergy.length - 1

      if(tempEnergy?.[tempEnergyIndex]?.kWh === 0 && tempEnergy?.[tempEnergyIndex]?.percentage === 0) {
        tempEnergy.splice(tempEnergyIndex, 1)
      }

      const filterTempEnergy = tempEnergy.map(({ _id, ...rest }: any) => {
        if(_id === null || _id === "") {
          return rest;

        } else {
          return {
            _id,
            ...rest
          }
        }
      }
    );

      let energyData = {
        _id: processData?._id,
        energyGrid: filterTempEnergy,
        userCompletedStage: "ENERGY",
      };
      dispatch(updateProcessData(energyData, "Energy")).then((res) => {
        navigationOption(navigateStatus);
      });
    }
  };

  return (
    <>
      <TitleBar titleName={"Processing Impact"} />
      <Grid className="backgroundMain">
        <Grid.Column computer={5} tablet={8} mobile={16}>
          <InputText register={register} name="productName" disabled={true} />
        </Grid.Column>
      </Grid>

      <Grid className="tabbMain">
        <TabView
          computer={2}
          tabData={ProcessTab}
          defaultSelect={4}
          addParams={true}
          paramsData={processData?._id}
        >
          <Grid.Column computer={16} tablet={8} mobile={16}>
            <TitleView
              CustomTitleViewMain="packingMn"
              title="Insert the proportion of your energy mix"
            />
            <ItemAdderTitleView titleData={CultivationEnergyTitle} />
            {energyFields.map((item, index) => {
              let energyTypeValue = item?.energyType || null;

              return (
                <ItemAdder
                  itemAddHandle={() => energyItemAdd(index)}
                  itemRemoveHandle={energyRemove}
                  items={energyFields}
                  currentIndex={index}
                  customItemAdderGridWithInputs={
                    "customItemAdderGridWithInputsPacking"
                  }
                >
                  <Grid>
                    <Grid.Column
                      computer={7}
                      tablet={7}
                      mobile={16}
                      className="paddingRemoveLeft paddingRemoveVertical"
                    >
                      <DropDown
                        required={true}
                        placeholder="Select Energy Type"
                        currentData={EnergyTypeListData}
                        defaultValue={
                          energyTypeValue ? energyTypeValue : defaultType
                        }
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          
                            updateValue(index, {
                              energyType: value,
                              kWh: getValues().energy?.[index]?.kWh,
                              percentage:
                                getValues().energy?.[index]?.percentage,
                            });
                            setDefaultType(value);
                          
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      tablet={4}
                      mobile={16}
                      className="paddingRemoveVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.energy?.[index]?.kWh}
                        placeholder="KWh"
                        name={`energy.${index}.kWh`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearError(index)
                            : setError(`energy.${index}.kWh`, {});
                        }}
                        errorMessage={"kWh is required"}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={5}
                      tablet={5}
                      mobile={16}
                      className="paddingRemoveVertical"
                    >
                      <InputText
                        register={register}
                        errors={errors.energy?.[index]?.percentage}
                        placeholder="Percentage"
                        name={`energy.${index}.percentage`}
                        type="number"
                        valueAsNumber={true}
                        required={true}
                        customInputValidation={true}
                        onChangeFunction={(e: any) => {
                          e.target.value > 0
                            ? clearError(index)
                            : setError(`energy.${index}.percentage`, {});
                        }}
                        errorMessage={"percentage is required"}
                      />
                    </Grid.Column>
                  </Grid>
                  <></>
                </ItemAdder>
              );
            })}
          </Grid.Column>
          <div className="enegryButtonView">
            <CustomButton
              theme="green"
              title={"Complete Later"}
              customColumnStyle={"customREnegryButton"}
              buttonOutLine={true}
              onClick={() => {
                onSubmit("late");
              }}
            />
            <CustomButton
              theme="green"
              title={"Next"}
              type={"submit"}
              onClick={() => {
                onSubmit("next");
              }}
            />
          </div>
        </TabView>
      </Grid>
    </>
  );
};

export default Energy;
