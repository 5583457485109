import { apolloClient } from "../../graphql/client";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import {
  CREATE_CULTIVATION,
  GET_CULTIVATION_LIST,
  GET_SINGLE_CULTIVATION,
  UPDATE_CULTIVATION,
  CHECK_INGREDIENT_CULTIVATION
} from "../../graphql/cultivation/cultivation-graphql";
import {
  CULTIVATION_LIST_DATA,
  GET_SINGLE_CULTIVATION_DATA,
  RESET_SINGLE_CULTIVATION_DATA,
} from "../slicers/cultivation";

export const getCultivationList = () => async (dispatch: any) => {
  const response = await apolloClient.query({
    query: GET_CULTIVATION_LIST,
  });
  dispatch(CULTIVATION_LIST_DATA(response?.data?.getAllCultivations));
};

export const checkIngredientCultivationData =
  (ingredientId: any, supplierId: any) => async (dispatch: any) => {
    const response = await apolloClient.query({
      query: CHECK_INGREDIENT_CULTIVATION,
      variables: {
        ingredientId: ingredientId,
        supplierId: supplierId,
      },
    });

    return response;
  };

export const getCultivationDataById =
  (cultivationId: any) => async (dispatch: any) => {
    const response = await apolloClient.query({
      query: GET_SINGLE_CULTIVATION,
      variables: {
        cultivationId: cultivationId,
      },
    });
    dispatch(GET_SINGLE_CULTIVATION_DATA(response?.data?.getCultivationById));
    return response;
  };

export const resetCultivationData = () => async (dispatch: any) => {
  dispatch(RESET_SINGLE_CULTIVATION_DATA(""));
};

export const createCultivation = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_CULTIVATION,
      variables: data,
    });
    successMessage("Ingredient added successfully");
    dispatch(getCultivationList());
    return response.data.createCultivation;
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const updateCultivationData =
  (data: any, status: any) => async (dispatch: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_CULTIVATION,
        variables: data,
      });
      if (status == "cultivation") {
        dispatch(getCultivationList());
      }
      successMessage(`Update ${status} successfully`);
      return response?.data?.updateCultivation;
    } catch ({ message }: any) {
      errorView(message);
    }
  };
