import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { Outlet, useNavigate } from "react-router-dom";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";

import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { CultivationTableCol } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";

import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";
import {
  getCultivationDataById,
  getCultivationList,
} from "../../../store/actions/cultivation";
import CultivationDetailsModal from "../cultivationDetails/CultivationDetailsModal";
import CultivationEditModal from "../cultivationEdit/CultivationEditModal";
import { updateCultivationData } from "../../../store/actions/cultivation";

const CultivationList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [idData, setIdData] = useState();

  let { cultivationList } = useSelector(
    (state: RootState) => state.cultivation
  );

  useEffect(() => {
    dispatch(getCultivationList());
  }, []);

  const deleteProcessDetails = (data: any) => {
    dispatch(updateCultivationData(data, "cultivation")).then(() => {
      dispatch(getCultivationList());
    });
    setDeleteData({});
  };
  const loadTableData = () => {
    return cultivationList?.map((data: any, index) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data["ingredientName"]}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data["supplierName"]}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    dispatch(getCultivationDataById(data?._id)).then((res) => {
                      if (res) {
                        setIdData(data._id);
                        setVisibleDetailsModal(true);
                      }
                    });
                  }}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() =>
                    dispatch(getCultivationDataById(data?._id)).then((res) => {
                      if (res) {
                        setIdData(data._id);
                        setVisibleEditModal(true);
                      }
                    })
                  }
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    const deleteProcessData = {
                      _id: data?._id,
                      status: "DISABLE",
                    };
                    setDeleteData(deleteProcessData);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar titleName={"Cultivation Details"} />
      <CustomButton
        theme="green"
        title="New Cultivation"
        onClick={() => navigate("cultivar/new")}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={CultivationTableCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <CultivationDetailsModal
        cultivationId={idData}
        visibleModal={visibleDetailsModal}
        setVisibleModal={() => setVisibleDetailsModal(false)}
      />
      <CultivationEditModal
        cultivationId={idData}
        visibleModal={visibleEditModal}
        setVisibleModal={() => setVisibleEditModal(false)}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteProcessDetails(deleteData);
        }}
        title="Delete Cultivation"
        subTitle="Are you sure you want to delete your cultivation data?"
      />
    </>
  );
};

export default CultivationList;
