import {
  GET_INGREDIENTS_DATA,
  GET_PRODUCTS_SEARCH_DATA,
  GET_SUPPLIERS_SEARCH_DATA,
  GET_INGREDIENTS_SEARCH_DATA,
  GET_INGREDIENT_DATA_BY_ID,
  GET_AN_INGREDIENT_DATA,
  GET_INGREDIENT_CREATE_RESPONSE_DATA,
  GET_ALL_INGREDIENT_AVAILABLE,
} from "../slicers/ingredient";
import { apolloClient } from "../../graphql/client";
import {
  CREATE_INGREDIENT,
  GET_ALL_INGREDIENTS,
  GET_ALL_SUPPLIERS,
  GET_INGREDIENT_BY_ID,
  GET_AN_INGREDIENT_DETAILS,
  UPDATE_INGREDIENT,
  GET_ALL_INGREDIENT_DATA,
} from "../../graphql/ingredients/ingredient-graphql";

import {
  CHECK_PRODUCT_PROCESS,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
} from "../../graphql/products/product-graphql";

import { errorView, successMessage } from "../../helpers/ErrorHandler";

export const getIngredientsData = () => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_INGREDIENTS,
    });
    dispatch(GET_INGREDIENTS_DATA(response.data.GetIngredeintSupplierList));
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const getIngredientsSearchData = () => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_INGREDIENTS,
    });

    const newD = await response?.data?.GetIngredeintSupplierList?.map(
      (ingred: any) => {
        const newIngredient: any = {
          id: ingred.ingredient_id,
          title: ingred.ingredient_name,
          ingredientData: {
            ingredient_id: ingred.ingredient_id,
            ingredient_name: ingred.ingredient_name,
          },
        };
        return newIngredient;
      }
    );
    dispatch(GET_INGREDIENTS_SEARCH_DATA(newD));
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const getSuppliersSearchData = () => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_SUPPLIERS,
    });

    const newD = await response?.data?.getAllSuppliers?.map((supplier: any) => {
      const newSupplier: any = {
        id: supplier._id,
        title: supplier.supplier_name,
        supplierDetails: {
          supplier_id: supplier._id,
          supplier_name: supplier.supplier_name,
        },
      };
      return newSupplier;
    });

    dispatch(
      GET_SUPPLIERS_SEARCH_DATA([
        { id: 0, title: "New" },
        { id: 1, title: "Unknown" },
        ...newD,
      ])
    );
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const getProductSearchData = () => async (dispatch: any) => {
  // const response = await apolloClient.query({
  //   query: GET_PRODUCTS,
  //   variables: {
  //     tenant_id: null,
  //     _id: null,
  //     product_status: "ACTIVE",
  //   },
  // });

  // const newD = await response.data?.getAllProducts?.map((product: any) => {
  //   const newProduct: any = {
  //     id: product._id,
  //     title: product.product_name,
  //     product_type: product.product_type,
  //     productDetails: {
  //       product_id: product._id,
  //       product_name: product.product_name,
  //       tenant_id: product.tenant_id,
  //     },
  //   };
  //   return newProduct;
  // });

  // dispatch(GET_PRODUCTS_SEARCH_DATA(newD));
};

export const checkProductProcessData =
  (productId: any, salesUnitId: any) => async (dispatch: any) => {
    const response = await apolloClient.query({
      query: CHECK_PRODUCT_PROCESS,
      variables: {
        productId: productId,
        salesUnitId: salesUnitId,
      },
    });

    return response;
  };

export const getProductSearchByIdData =
  (productId: any) => async (dispatch: any) => {
    const response = await apolloClient.query({
      query: GET_PRODUCT_BY_ID,
      variables: {
        tenant_id: null,
        _id: productId,
        product_status: "ACTIVE",
      },
    });

    dispatch(
      GET_INGREDIENT_CREATE_RESPONSE_DATA(
        response.data?.getProductById?.product_ingredients
      )
    );
  };

export const resetProductSearchByIdData = () => async (dispatch: any) => {
  dispatch(GET_INGREDIENT_CREATE_RESPONSE_DATA([]));
};

export const addIngredient = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_INGREDIENT,
      variables: data,
    });

    successMessage("Ingredient added successfully");
    dispatch(getIngredientsData());
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const updateIngredient = (data: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_INGREDIENT,
      variables: data,
    });

    successMessage("Ingredient updated successfully");
    dispatch(getIngredientsData());
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const getIngredientById = (id: any) => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_INGREDIENT_BY_ID,
      variables: {
        ingredient_id: id,
        ingredient_status: "ACTIVE",
      },
    });

    dispatch(GET_INGREDIENT_DATA_BY_ID(response.data.getIngredient));
  } catch ({ message }: any) {
    errorView(message);
  }
};

export const getIngredientDetailsToEdit =
  (ingredientId: any, productId: any, supplierId: any, ingredientType: any) =>
  async (dispatch: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_AN_INGREDIENT_DETAILS,
        variables: {
          ingredient_id: ingredientId,
          product_id: productId,
          supplier_id: supplierId,
          ingredient_status: "ACTIVE",
          ingredient_type: ingredientType,
        },
      });

      dispatch(
        GET_AN_INGREDIENT_DATA(response.data.GetIngredeintProductSupplier)
      );
      return true;
    } catch ({ message }: any) {
      errorView(message);
      return false;
    }
  };

export const getAllIngredient = () => async (dispatch: any) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_INGREDIENTS,
    });

    const filterArray = await response.data?.GetIngredeintSupplierList?.map(
      (ingredient: any) => {
        const newProduct: any = {
          id: ingredient._id,
          title: `${ingredient.ingredient_name}${
            ingredient?.supplier_name
              ? `, ${ingredient?.supplier_name || ""}`
              : ""
          }`,
          ingredientData: {
            ingredient_id: ingredient.ingredient_id,
            ingredient_name: ingredient.ingredient_name,
            supplier_id: ingredient.supplier_id,
            supplier_name: ingredient.supplier_name,
          },
        };
        return newProduct;
      }
    );

    dispatch(GET_ALL_INGREDIENT_AVAILABLE(filterArray));
  } catch ({ message }: any) {
    errorView(message);
  }
};
