import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
  TableRow,
} from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../components";
import { FacilityHeatTable, HEAT_SOURCE_UNIT_TYPES } from "../../../config/constants";
import { MdDeleteOutline } from "react-icons/md";
import { InputNumber } from "../../../components/InputText/InputNumber";

import "./facilityForm.scss";
import { checkYearValidation } from "../../../utils/utils";

const FacilityHeatSource = ({
  heatType,
  defaultHeatType,
  setDefaultHeatType,
  errorsFacilityHeat,
  handleSubmitFacilityHeat,
  addFacilityHeatData,
  addFacilityHeat,
  currentFacilityHeatData,
  setDeleteHeatData,
  setVisibleDeleteHeatModal,
  control,
  setUnit,
  getDefaultUnit,
  renderTableData = false,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  const groupHeatData = (data: any) => {
    let groupIngredientData = data.reduce(
      (currentType: any, data: any, index: any) => {
        data.index = index;
        let checkDataIsAvailable = currentType?.find(
          (other: any) => other.key == data.Year
        );
        if (currentType?.length == 0) {
          let facilityData = {
            key: data.Year,
            value: [data],
          };
          return [...currentType, facilityData];
        } else if (checkDataIsAvailable) {
          let currentIndex = currentType?.findIndex(
            (other: any) => other.key == data.Year
          );
          let updatedArray = [];

          const updateArray = [...currentType[currentIndex].value, data];
          const updatedObject = {
            ...currentType[currentIndex],
            value: updateArray,
          };
          updatedArray = [
            ...currentType.slice(0, currentIndex),
            updatedObject,
            ...currentType.slice(currentIndex + 1),
          ];
          return updatedArray;
        } else {
          let facilityData = {
            key: data.Year,
            value: [data],
          };

          return [...currentType, facilityData];
        }
      },
      []
    );
    return groupIngredientData;
  };

  const renderTableRow = (
    dataView: any,
    rowSpan: any,
    totalValue: any,
    onDelete: any
  ) => (
    <TableRow key={dataView?.id || dataView?.index}>
      {rowSpan > 0 && (
        <Table.Cell rowSpan={rowSpan}>
          <p>{dataView?.Year ?? ""}</p>
        </Table.Cell>
      )}
      <Table.Cell>
        <p>{dataView?.HeatSource ?? 0.0}</p>
      </Table.Cell>
      <Table.Cell>
        <p>
          {dataView?.Volumes ?? ""}
          <span className="unitTypeView">{dataView?.UnitType ?? ""}</span>
        </p>
      </Table.Cell>
      <Table.Cell>
        <p>{totalValue}</p>
      </Table.Cell>
      <Table.Cell>
        <Grid>
          {!renderTableData ? (
            <Grid.Column computer={4} tablet={16} mobile={16}>
              <MdDeleteOutline
                cursor="pointer"
                size={24}
                color="var(--mainColor)"
                onClick={onDelete}
              />
            </Grid.Column>
          ) : null}
        </Grid>
      </Table.Cell>
    </TableRow>
  );

  const loadTableData = () => {
    const groupFacilityByYear = groupHeatData(currentFacilityHeatData);
    return groupFacilityByYear.map((groupData: any, groupIndex: any) => {
      const rowCount = groupData?.value?.length || 0;
      return groupData?.value?.map((dataView: any, dataIndex: any) => {
        const isFirstRow = dataIndex === 0;
        const Volumes = dataView?.Volumes || 0;
        const price = dataView?.Price || 0;
        const totalValue = Volumes * price;

        const handleDelete = () => {
          setDeleteHeatData(dataView);
          setVisibleDeleteHeatModal(true);
        };
        return renderTableRow(
          dataView,
          isFirstRow ? rowCount : 0,
          totalValue,
          handleDelete
        );
      });
    });
  };

  return (
    <>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"Heat Sources"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <Grid>
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className={
                !renderTableData ? "userBankDetailsMain" : "recipeTableViewMain"
              }
            >
              <div>
                {!renderTableData ? (
                  <Grid>
                    <Grid.Column
                      computer={4}
                      tablet={8}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <DropDown
                        labelName={"Source*"}
                        placeholder="Heat Source"
                        currentData={heatType}
                        defaultValue={heatType?.[defaultHeatType].value || null}
                        customGridColumn={"customGridColomnType"}
                        handleChangeState={(e: any, { value }: any) => {
                          let getIndex = heatType?.findIndex(
                            (e: any) => e?.value === value
                          );
                          setDefaultHeatType(getIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      tablet={8}
                      mobile={16}
                      className="paddingRemoveBottom"
                    >
                      <InputNumber
                        control={control}
                        errors={errorsFacilityHeat.Year}
                        labelName={"Year*"}
                        placeholder="Year"
                        name="Year"
                        required={true}
                        errorMessage="Year is required"
                        otherValidation={{
                          validate: {
                            valueCheck: (v: any) =>
                              checkYearValidation(v) || "Please add valid year",
                          },
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={8}
                      tablet={8}
                      mobile={16}
                      className="paddingRemoveBottom"
                    />
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <InputNumber
                        control={control}
                        errors={errorsFacilityHeat.Volumes}
                        labelName={"Volumes consumed*"}
                        placeholder="Volumes"
                        name="Volumes"
                        required={true}
                        errorMessage="Volumes is required"
                      />
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={8} mobile={16}>
                      <Grid.Column computer={4} tablet={8} mobile={16}>
                        <DropDown
                          labelName={"Unit Type*"}
                          placeholder="Type"
                          currentData={HEAT_SOURCE_UNIT_TYPES}
                          defaultValue={getDefaultUnit}
                          customGridColumn={"customGridColomnType"}
                          handleChangeState={(e: any, { value }: any) => {
                            setUnit(value);
                          }}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <InputNumber
                        control={control}
                        errors={errorsFacilityHeat.Price}
                        labelName={"Price per unit"}
                        placeholder="Price"
                        name="Price"
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16}>
                      <CustomButton
                        theme="green"
                        customColumnStyle={"addUnitSize"}
                        icon="plus"
                        onClick={handleSubmitFacilityHeat((data: any) => {
                          addFacilityHeatData(data);
                        })}
                      />
                    </Grid.Column>
                  </Grid>
                ) : null}

                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className={
                    !renderTableData
                      ? "productRegisterTable customTableBottom"
                      : ""
                  }
                >
                  {addFacilityHeat?.length > 0 && (
                    <CommonTable tableHeaderData={FacilityHeatTable}>
                      {loadTableData()}
                    </CommonTable>
                  )}
                </Grid.Column>
              </div>
            </Grid.Column>
          </Grid>
        </AccordionContent>
      </Accordion>
    </>
  );
};

export default FacilityHeatSource;
