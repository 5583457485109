import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CultivationState } from "../types/cultivationInputs";

const initialState: CultivationState = {
  cultivationData: {
    _id: "",
    processing: [
      {
        _id: "",
        associateIngredeient: [
          {
            content: "",
            ingredientId: "",
            ingredientName: "",
            unit: "",
          },
        ],
        capacity: 0,
        cipSip: [
          {
            chemicalConcentration: 0,
            chemicalConsumption: 0,
            chemicalUse: 0,
            energyConsumption: 0,
            frequency: 0,
            manufacturer: "",
            modelNumber: "",
            steamFlow: 0,
            steamPressure: 0,
          },
        ],
        citricAcid: "",
        electricity: "",
        facilityId: "",
        facilityName: "",
        heat: "",
        input: "",
        machineModelNo: "",
        output: "",
        processNumber: "",
        processingName: "",
        processingType: "",
        sideStream: "",
        water: "",
      },
    ],
    cultivationField: {
      _id: "",
      fertilizerList: [
        {
          _id: "",
          fertilizerName: "",
          fertilizerQuantity: 0,
        },
      ],
      growthArea: 0,
      harvestSeason: "",
      harvestYear: "",
      persticideList: [
        {
          _id: "",
          pesticideName: "",
          presticideQuantity: 0,
        },
      ],
      yield: 0,
    },
    farmingType: "",
    originCountry: "",
    ingredientId: "",
    ingredientName: "",
    supplierId: "",
    supplierName: "",
    produceCultivar: "",
    productType: "",
    produceType: "",
    farmingField: "",
    storage: [
      {
        _id: "",
        areaUtilization: 0,
        electricity: 0,
        warehouseId: "",
        warehouseName: "",
        warehouseSize: "",
        weight: 0,
        temperature: 0,
        storageDays: 0
      },
    ],
    energy: [
      {
        kwh: 0,
        precentage: 0,
        type: "",
      },
    ],
    packaging: [
      {
        meaterialWeight: 0,
        packageMaterial: "",
        packageName: "",
      },
    ],
    transportation: [
      {
        _id: "",
        averageDistance: 0,
        transportType: "",
      },
    ],
    packagingMethod: [
      {
        energy: 0,
        packagingMethod: "",
        waste: 0,
        capacity: 0,
      },
    ],
    internalTransportation: [
      {
        _id: "",
        averageDistance: 0,
        transportType: "",
      },
    ],
    externalTransportation: [
      {
        _id: "",
        distance: 0,
        from: "",
        to: "",
        transportType: "",
      },
    ],
  },
  cultivationList: [
    {
      _id: "",
      produceType: "",
    },
  ],
};

export const cultivationSlice = createSlice({
  name: "cultivation",
  initialState,
  reducers: {
    RESET: () => initialState,
    CULTIVATION_LIST_DATA: (state, action) => {
      state.cultivationList = action.payload;
    },
    GET_SINGLE_CULTIVATION_DATA: (state, action) => {
      state.cultivationData = action.payload;
    },
    RESET_SINGLE_CULTIVATION_DATA: (state, action) => {
      state.cultivationData = initialState.cultivationData;
    },
  },
});

export const {
  RESET,
  CULTIVATION_LIST_DATA,
  GET_SINGLE_CULTIVATION_DATA,
  RESET_SINGLE_CULTIVATION_DATA,
} = cultivationSlice.actions;
export default cultivationSlice.reducer;
