export const OriginCountries = [
    {key: 1,	text: "Afghanistan" ,	value: "Afghanistan" },
    {key: 2,	text: "Albania" ,	value: "Albania" },
    {key: 3,	text: "Algeria" ,	value: "Algeria" },
    {key: 4,	text: "Andorra" ,	value: "Andorra" },
    {key: 5,	text: "Angola" ,	value: "Angola" },
    {key: 6,	text: "Antigua and Barbuda" ,	value: "Antigua and Barbuda" },
    {key: 7,	text: "Argentina" ,	value: "Argentina" },
    {key: 8,	text: "Armenia" ,	value: "Armenia" },
    {key: 9,	text: "Australia" ,	value: "Australia" },
    {key: 10,	text: "Austria" ,	value: "Austria" },
    {key: 11,	text: "Azerbaijan" ,	value: "Azerbaijan" },
    {key: 12,	text: "Bahamas" ,	value: "Bahamas" },
    {key: 13,	text: "Bahrain" ,	value: "Bahrain" },
    {key: 14,	text: "Bangladesh" ,	value: "Bangladesh" },
    {key: 15,	text: "Barbados" ,	value: "Barbados" },
    {key: 16,	text: "Belarus" ,	value: "Belarus" },
    {key: 17,	text: "Belgium" ,	value: "Belgium" },
    {key: 18,	text: "Belize" ,	value: "Belize" },
    {key: 19,	text: "Benin" ,	value: "Benin" },
    {key: 20,	text: "Bhutan" ,	value: "Bhutan" },
    {key: 21,	text: "Bolivia" ,	value: "Bolivia" },
    {key: 22,	text: "Bosnia and Herzegovina" ,	value: "Bosnia and Herzegovina" },
    {key: 23,	text: "Botswana" ,	value: "Botswana" },
    {key: 24,	text: "Brazil" ,	value: "Brazil" },
    {key: 25,	text: "Brunei Darussalam" ,	value: "Brunei Darussalam" },
    {key: 26,	text: "Bulgaria" ,	value: "Bulgaria" },
    {key: 27,	text: "Burkina Faso" ,	value: "Burkina Faso" },
    {key: 28,	text: "Burundi" ,	value: "Burundi" },
    {key: 29,	text: "Cabo Verde" ,	value: "Cabo Verde" },
    {key: 30,	text: "Cambodia" ,	value: "Cambodia" },
    {key: 31,	text: "Cameroon" ,	value: "Cameroon" },
    {key: 32,	text: "Canada" ,	value: "Canada" },
    {key: 33,	text: "Central African Republic" ,	value: "Central African Republic" },
    {key: 34,	text: "Chad" ,	value: "Chad" },
    {key: 35,	text: "Chile" ,	value: "Chile" },
    {key: 36,	text: "China" ,	value: "China" },
    {key: 37,	text: "Colombia" ,	value: "Colombia" },
    {key: 38,	text: "Comoros" ,	value: "Comoros" },
    {key: 39,	text: "Congo" ,	value: "Congo" },
    {key: 40,	text: "Costa Rica" ,	value: "Costa Rica" },
    {key: 41,	text: "Ivory Coast" ,	value: "Ivory Coast" },
    {key: 42,	text: "Croatia" ,	value: "Croatia" },
    {key: 43,	text: "Cuba" ,	value: "Cuba" },
    {key: 44,	text: "Cyprus" ,	value: "Cyprus" },
    {key: 45,	text: "Czech Republic" ,	value: "Czech Republic" },
    {key: 46,	text: "Democratic Republic of the Congo" ,	value: "Democratic Republic of the Congo" },
    {key: 47,	text: "Denmark" ,	value: "Denmark" },
    {key: 48,	text: "Djibouti" ,	value: "Djibouti" },
    {key: 49,	text: "Dominica" ,	value: "Dominica" },
    {key: 50,	text: "Dominican Republic" ,	value: "Dominican Republic" },
    {key: 51,	text: "Ecuador" ,	value: "Ecuador" },
    {key: 52,	text: "Egypt" ,	value: "Egypt" },
    {key: 53,	text: "El Salvador" ,	value: "El Salvador" },
    {key: 54,	text: "Equatorial Guinea" ,	value: "Equatorial Guinea" },
    {key: 55,	text: "Eritrea" ,	value: "Eritrea" },
    {key: 56,	text: "Estonia" ,	value: "Estonia" },
    {key: 57,	text: "Eswatini" ,	value: "Eswatini" },
    {key: 58,	text: "Ethiopia" ,	value: "Ethiopia" },
    {key: 59,	text: "Fiji" ,	value: "Fiji" },
    {key: 60,	text: "Finland" ,	value: "Finland" },
    {key: 61,	text: "France" ,	value: "France" },
    {key: 62,	text: "Gabon" ,	value: "Gabon" },
    {key: 63,	text: "Gambia" ,	value: "Gambia" },
    {key: 64,	text: "Georgia" ,	value: "Georgia" },
    {key: 65,	text: "Germany" ,	value: "Germany" },
    {key: 66,	text: "Ghana" ,	value: "Ghana" },
    {key: 67,	text: "Greece" ,	value: "Greece" },
    {key: 68,	text: "Grenada" ,	value: "Grenada" },
    {key: 69,	text: "Guatemala" ,	value: "Guatemala" },
    {key: 70,	text: "Guinea" ,	value: "Guinea" },
    {key: 71,	text: "Guinea Bissau" ,	value: "Guinea Bissau" },
    {key: 72,	text: "Guyana" ,	value: "Guyana" },
    {key: 73,	text: "Haiti" ,	value: "Haiti" },
    {key: 74,	text: "Honduras" ,	value: "Honduras" },
    {key: 75,	text: "Hungary" ,	value: "Hungary" },
    {key: 76,	text: "Iceland" ,	value: "Iceland" },
    {key: 77,	text: "India" ,	value: "India" },
    {key: 78,	text: "Indonesia" ,	value: "Indonesia" },
    {key: 79,	text: "Iran" ,	value: "Iran" },
    {key: 80,	text: "Iraq" ,	value: "Iraq" },
    {key: 81,	text: "Ireland" ,	value: "Ireland" },
    {key: 82,	text: "Israel" ,	value: "Israel" },
    {key: 83,	text: "Italy" ,	value: "Italy" },
    {key: 84,	text: "Jamaica" ,	value: "Jamaica" },
    {key: 85,	text: "Japan" ,	value: "Japan" },
    {key: 86,	text: "Jordan" ,	value: "Jordan" },
    {key: 87,	text: "Kazakhstan" ,	value: "Kazakhstan" },
    {key: 88,	text: "Kenya" ,	value: "Kenya" },
    {key: 89,	text: "Kiribati" ,	value: "Kiribati" },
    {key: 90,	text: "Kuwait" ,	value: "Kuwait" },
    {key: 91,	text: "Kyrgyzstan" ,	value: "Kyrgyzstan" },
    {key: 92,	text: "Lao" ,	value: "Lao" },
    {key: 93,	text: "Latvia" ,	value: "Latvia" },
    {key: 94,	text: "Lebanon" ,	value: "Lebanon" },
    {key: 95,	text: "Lesotho" ,	value: "Lesotho" },
    {key: 96,	text: "Liberia" ,	value: "Liberia" },
    {key: 97,	text: "Libya" ,	value: "Libya" },
    {key: 98,	text: "Liechtenstein" ,	value: "Liechtenstein" },
    {key: 99,	text: "Lithuania" ,	value: "Lithuania" },
    {key: 100,	text: "Luxembourg" ,	value: "Luxembourg" },
    {key: 101,	text: "Madagascar" ,	value: "Madagascar" },
    {key: 102,	text: "Malawi" ,	value: "Malawi" },
    {key: 103,	text: "Malaysia" ,	value: "Malaysia" },
    {key: 104,	text: "Maldives" ,	value: "Maldives" },
    {key: 105,	text: "Mali" ,	value: "Mali" },
    {key: 106,	text: "Malta" ,	value: "Malta" },
    {key: 107,	text: "Marshall Islands" ,	value: "Marshall Islands" },
    {key: 108,	text: "Mauritania" ,	value: "Mauritania" },
    {key: 109,	text: "Mauritius" ,	value: "Mauritius" },
    {key: 110,	text: "Mexico" ,	value: "Mexico" },
    {key: 111,	text: "Micronesia" ,	value: "Micronesia" },
    {key: 112,	text: "Monaco" ,	value: "Monaco" },
    {key: 113,	text: "Mongolia" ,	value: "Mongolia" },
    {key: 114,	text: "Montenegro" ,	value: "Montenegro" },
    {key: 115,	text: "Morocco" ,	value: "Morocco" },
    {key: 116,	text: "Mozambique" ,	value: "Mozambique" },
    {key: 117,	text: "Myanmar" ,	value: "Myanmar" },
    {key: 118,	text: "Namibia" ,	value: "Namibia" },
    {key: 119,	text: "Nauru" ,	value: "Nauru" },
    {key: 120,	text: "Nepal" ,	value: "Nepal" },
    {key: 121,	text: "Netherlands" ,	value: "Netherlands" },
    {key: 122,	text: "New Zealand" ,	value: "New Zealand" },
    {key: 123,	text: "Nicaragua" ,	value: "Nicaragua" },
    {key: 124,	text: "Niger" ,	value: "Niger" },
    {key: 125,	text: "Nigeria" ,	value: "Nigeria" },
    {key: 126,	text: "North Macedonia" ,	value: "North Macedonia" },
    {key: 127,	text: "Norway" ,	value: "Norway" },
    {key: 128,	text: "Oman" ,	value: "Oman" },
    {key: 129,	text: "Pakistan" ,	value: "Pakistan" },
    {key: 130,	text: "Palau" ,	value: "Palau" },
    {key: 131,	text: "Panama" ,	value: "Panama" },
    {key: 132,	text: "Papua New Guinea" ,	value: "Papua New Guinea" },
    {key: 133,	text: "Paraguay" ,	value: "Paraguay" },
    {key: 134,	text: "Peru" ,	value: "Peru" },
    {key: 135,	text: "Philippines" ,	value: "Philippines" },
    {key: 136,	text: "Poland" ,	value: "Poland" },
    {key: 137,	text: "Portugal" ,	value: "Portugal" },
    {key: 138,	text: "Qatar" ,	value: "Qatar" },
    {key: 139,	text: "Republic of Korea" ,	value: "Republic of Korea" },
    {key: 140,	text: "Republic of Moldova" ,	value: "Republic of Moldova" },
    {key: 141,	text: "Romania" ,	value: "Romania" },
    {key: 142,	text: "Russian Federation" ,	value: "Russian Federation" },
    {key: 143,	text: "Rwanda" ,	value: "Rwanda" },
    {key: 144,	text: "Saint Kitts and Nevis" ,	value: "Saint Kitts and Nevis" },
    {key: 145,	text: "Saint Lucia" ,	value: "Saint Lucia" },
    {key: 146,	text: "Saint Vincent and the Grenadines" ,	value: "Saint Vincent and the Grenadines" },
    {key: 147,	text: "Samoa" ,	value: "Samoa" },
    {key: 148,	text: "San Marino" ,	value: "San Marino" },
    {key: 149,	text: "Sao Tome and Principe" ,	value: "Sao Tome and Principe" },
    {key: 150,	text: "Saudi Arabia" ,	value: "Saudi Arabia" },
    {key: 151,	text: "Senegal" ,	value: "Senegal" },
    {key: 152,	text: "Serbia" ,	value: "Serbia" },
    {key: 153,	text: "Seychelles" ,	value: "Seychelles" },
    {key: 154,	text: "Sierra Leone" ,	value: "Sierra Leone" },
    {key: 155,	text: "Singapore" ,	value: "Singapore" },
    {key: 156,	text: "Slovakia" ,	value: "Slovakia" },
    {key: 157,	text: "Slovenia" ,	value: "Slovenia" },
    {key: 158,	text: "Solomon Islands" ,	value: "Solomon Islands" },
    {key: 159,	text: "Somalia" ,	value: "Somalia" },
    {key: 160,	text: "South Africa" ,	value: "South Africa" },
    {key: 161,	text: "South Sudan" ,	value: "South Sudan" },
    {key: 162,	text: "Spain" ,	value: "Spain" },
    {key: 163,	text: "Sri Lanka" ,	value: "Sri Lanka" },
    {key: 164,	text: "Sudan" ,	value: "Sudan" },
    {key: 165,	text: "Suriname" ,	value: "Suriname" },
    {key: 166,	text: "Sweden" ,	value: "Sweden" },
    {key: 167,	text: "Switzerland" ,	value: "Switzerland" },
    {key: 168,	text: "Syrian Arab Republic" ,	value: "Syrian Arab Republic" },
    {key: 169,	text: "Taiwan" ,	value: "Taiwan" },
    {key: 170,	text: "Tajikistan" ,	value: "Tajikistan" },
    {key: 171,	text: "Thailand" ,	value: "Thailand" },
    {key: 172,	text: "Timor-Leste" ,	value: "Timor-Leste" },
    {key: 173,	text: "Togo" ,	value: "Togo" },
    {key: 174,	text: "Tonga" ,	value: "Tonga" },
    {key: 175,	text: "Trinidad and Tobago" ,	value: "Trinidad and Tobago" },
    {key: 176,	text: "Tunisia" ,	value: "Tunisia" },
    {key: 177,	text: "Turkey" ,	value: "Turkey" },
    {key: 178,	text: "Turkmenistan" ,	value: "Turkmenistan" },
    {key: 179,	text: "Tuvalu" ,	value: "Tuvalu" },
    {key: 180,	text: "Uganda" ,	value: "Uganda" },
    {key: 181,	text: "Ukraine" ,	value: "Ukraine" },
    {key: 182,	text: "United Arab Emirates" ,	value: "United Arab Emirates" },
    {key: 183,	text: "United Kingdom of Great Britain and Northern Ireland" ,	value: "United Kingdom of Great Britain and Northern Ireland" },
    {key: 184,	text: "United Republic of Tanzania" ,	value: "United Republic of Tanzania" },
    {key: 185,	text: "United States of America" ,	value: "United States of America" },
    {key: 186,	text: "Uruguay" ,	value: "Uruguay" },
    {key: 187,	text: "Uzbekistan" ,	value: "Uzbekistan" },
    {key: 188,	text: "Vanuatu" ,	value: "Vanuatu" },
    {key: 189,	text: "Venezuela" ,	value: "Venezuela" },
    {key: 190,	text: "Vietnam" ,	value: "Vietnam" },
    {key: 191,	text: "Yemen" ,	value: "Yemen" },
    {key: 192,	text: "Zambia" ,	value: "Zambia" },
    {key: 193,	text: "Zimbabwe" ,	value: "Zimbabwe" }
]