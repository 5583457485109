import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type ProductIngredeients {
    ingredient_id: String
    ingredient_name: String
    unit_size: Int
    unit_type: String
    content: String
    shrinkage: String
  }

  extend type ProductSizes {
    sales_unit_name: String
    sales_unit_size: String
    sales_unit_type: String
    ean_barcode: String
    climate_impact: String
    createdById: String
    createdByName: String
    createdAt: String
    updatedById: String
    updatedByName: String
    updatedAt: String
  }

  extend type UpdateCreateProductIngredeients {
    ingredient_id: String
    ingredient_name: String
    supplier_id: String
    supplier_name: String
    unit_size: Int
    unit_type: String
    ean_barcode: String
    data_type: String
    ingredient_type: String
  }

  extend type SearchIngredientDto {
    ingredient_id: String
    ingredient_status: String
    tenant_id: String
  }

  extend type UpdateProductIngredeients {
    ingredient_id: String
    ingredient_name: String
    unit_size: Int
    unit_type: String
    content: String
    shrinkage: String
  }

  extend type UpdateProductSizes {
    _id: String
    sales_unit_name: String
    sales_unit_size: String
    sales_unit_type: String
    ean_barcode: String
    sales_unit_status: String
  }

  extend type NewSalesUnit {
    createdAt: DateTime
    createdById: String
    createdByName: String
    ean_barcode: String
    sales_unit_name: String
    sales_unit_size: String
    sales_unit_status: String
    sales_unit_type: String
  }

  extend type SupplierIngredients {
    ingredient_id: String
    ingredient_name: String
    unit_size: Number
    unit_type: String
    ean_barcode: String
    content: String
    shrinkage: String
    supplier_status: String
    ingredient_type: String
    data_type: String
  }

  extend type UpdateSuppliers {
    supplier_id: String
    supplier_name: String
    climate_impact: String
    ingredient_type: String
    data_type: String
    unit_size: Int
    unit_type: String
    ean_bar_code: String
  }

  extend type UpdateProducts {
    product_id: String
    product_name: String
  }

  extend type AssociateIngredeients {
    content: Int
    ingredientId: String
    ingredientName: String
    unit: String
  }

  extend type AssociateProcesses {
    content: Int
    processId: String
    processName: String
    unit: String
  }

  extend type CipSips {
    chemicalConcentration: Int
    chemicalConsumption: Int
    chemicalUse: Int
    energyConsumption: Int
    frequency: Int
    manufacturer: String
    modelNumber: String
    steamFlow: Int
    steamPressure: Int
  }

  extend type ProcessProcessingsDto {
    _id: String
    associateIngredeients: [AssociateIngredeients!]
    associateProcesses: [AssociateProcesses!]
    cipSip: [CipSips!]
    capacity: String
    citricAcid: Int
    electricity: Int
    facilityId: String
    facilityName: String
    heat: Int
    input: Int
    machineModelNo: String
    output: Int
    processNumber: Int
    processingName: String
    processingType: String
    sideStream: Int
    water: Int
  }

  extend type ProcessPackagingsDto {
    _id: String
    meaterialWeight: Int
    packageMaterial: String
    packageName: String
  }

  extend type ProcessPackagingMachinesDto {
    _id: String
    energy: String
    packagingMachine: String
    waste: String
  }

  extend type ProcessReceipiesDto {
    content: Int!
    ingredientId: String!
    ingredientName: String!
    prcentage: Int!
    unitSize: String!
  }

  extend type ProcessStoragesDto {
    _id: String
    areaUtilization: Int
    electricity: Int
    temperature: String
    warehouseId: String
    warehouseName: String
    warehouseSize: Int
    weight: Int
    storageDays: Int
  }

  extend type ProcessEnergyGridsDto {
    energyType: String
    kWh: Int
    percentage: Int
  }

  extend type ProcessInternalTransportsDto {
    _id: String
    averageDistance: Int
    transportType: String
  }

  extend type ProcessExternalTransportsDto {
    _id: String
    distance: Int
    from: String
    to: String
    transportType: String
  }

  extend type CultivationFieldFertilizerDto {
    _id: String
    fertilizerName: String
    fertilizerQuantity: Int
  }

  extend type CultivationFieldPersticideDto {
    _id: String
    pesticideName: String
    presticideQuantity: Int
  }

  extend type CultivationFieldDto {
    _id: String
    fertilizerList: [CultivationFieldFertilizerDto!]
    growthArea: Int
    harvestSeason: String
    harvestYear: String
    persticideList: [CultivationFieldPersticideDto!]
    yield: Int
  }

  extend type CultivationPackagingsDto {
    _id: String
    meaterialWeight: Int
    packageMaterial: String
    packageName: String
  }

  extend type CultivationPackagingMethodDto {
    _id: String
    energy: Int
    packagingMethod: Int
    waste: Int
  }

  extend type AssociateIngredeient {
    _id: String
    content: Int
    ingredientId: String
    ingredientName: String
    unit: String
  }

  extend type AssociateProcess {
    _id: String
    content: Int
    processId: String
    processName: String
    unit: String
  }

  extend type CipSip {
    _id: String
    chemicalConcentration: Int
    chemicalConsumption: Int
    chemicalUse: Int
    energyConsumption: Int
    frequency: Int
    manufacturer: String
    modelNumber: String
    steamFlow: Int
    steamPressure: Int
  }

  extend type CultivationProcessingDto {
    _id: String
    associateIngredeient: [AssociateIngredeient!]
    associateProcess: [AssociateProcess!]
    cipSip: [CipSip!]
    capacity: Int
    citricAcid: Int
    electricity: Int
    facilityId: String
    facilityName: String
    heat: Int
    input: Int
    machineModelNo: String
    output: Int
    processNumber: Int
    processingName: String
    processingType: String
    sideStream: Int
    water: Int
  }

  extend type CultivationStorageDto {
    _id: String
    areaUtilization: Int
    electricity: Int
    temperature: Int
    warehouseId: String
    warehouseName: String
    warehouseSize: String
    weight: Int
    storageDays: Int
  }

  extend type CultivationTransportationDto {
    _id: String
    averageDistance: Int
    transportType: String
  }

  extend type CultivationEnergyDto {
    kwh: Int
    precentage: Int
    type: String
  }

  extend type CreateClientProductDto {
    _id: Int
    productName: String
    salesUnitId: String
    salesUnitName: String
  }

  extend type UpdateClientProductDto {
    _id: Int!
    productName: String
    salesUnitId: String
    salesUnitName: String
  }
`;
