import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { Outlet, useNavigate } from "react-router-dom";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";

import { CustomButton, CommonTable, TitleBar } from "../../../components";
import { ProcessFlowCol, ProductTableContent } from "../../../config/constants";
import EditProcessModal from "../editProcess/EditProcessModal";
import ViewProcessDetailsModal from "../viewDetails/ViewProcessDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProcess,
  getProcessDataById,
  getProcessList,
} from "../../../store/actions/process";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

const ProcessDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleDetailsModal, setVisibleDetailsModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  let { processList } = useSelector((state: RootState) => state.process);

  useEffect(() => {
    dispatch(getProcessList());
  }, []);

  const deleteProcessDetails = (data: any) => {
    dispatch(deleteProcess(data));
    setDeleteData({});
  };

  const loadTableData = () => {
    return processList?.map((data: any, index) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell className="userBillingHisMainText">
            <p>{data["productName"]}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <p>{data["salesUnitName"]}</p>
          </Table.Cell>
          <Table.Cell className="userBillingHisMainText">
            <Grid>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <TbFileSearch
                  cursor={"pointer"}
                  onClick={() => {
                    dispatch(getProcessDataById(data?._id)).then((res) => {
                      if (res) {
                        setVisibleDetailsModal(true);
                      }
                    });
                  }}
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() =>
                    dispatch(getProcessDataById(data?._id)).then((res) => {
                      if (res) {
                        setVisibleEditModal(true);
                      }
                    })
                  }
                  size={24}
                  color="var(--mainColor)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16} key={index}>
                <MdDeleteOutline
                  cursor={"pointer"}
                  size={24}
                  color="var(--mainColor)"
                  onClick={() => {
                    const deleteProcessData = {
                      _id: data?._id,
                      process_status: "DISABLE",
                    };
                    setDeleteData(deleteProcessData);
                    setVisibleDeleteModal(true);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <TitleBar titleName={"Processing Details"} />
      <CustomButton
        theme="green"
        title="New Process"
        onClick={() => navigate("recipe/new")}
        customColumnStyle="topButtonView"
        iconWithText={true}
        iconName="plus"
      />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={ProcessFlowCol}>
            {loadTableData()}
          </CommonTable>
        </Grid.Column>
      </Grid>
      <EditProcessModal
        visibleModal={visibleEditModal}
        setVisibleModal={() => setVisibleEditModal(false)}
      />
      <ViewProcessDetailsModal
        visibleModal={visibleDetailsModal}
        setVisibleModal={() => setVisibleDetailsModal(false)}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
          setDeleteData({});
        }}
        approve={() => {
          setVisibleDeleteModal(false);
          deleteProcessDetails(deleteData);
        }}
        title="Delete Process"
        subTitle="Are you sure you want to delete your process data?"
      />
    </>
  );
};

export default ProcessDetails;
