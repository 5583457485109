import React from "react";
import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { errorView } from "../helpers/ErrorHandler";
import { store } from "../store/store";
import { logout } from "../store/actions/auth";
import { API_URL } from "../config/constants";
import { typeDefs } from "./types";
import { getTokenInLocal } from "../utils/cacheStorage";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message == "Unauthorized") {
        errorView(message);
        window.location.href = "/auth";
        store.dispatch(logout());
      } else {
        errorView(message);
      }
    });
  if (networkError) errorView(networkError);
});

const authLink = setContext((_, { headers }) => {
  const token = getTokenInLocal();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: API_URL,
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(uploadLink)]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  typeDefs,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});
