import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SupplierState {
  supplier?: [];
  supplierDetails: {
    _id: String;
    supplier_name: String;
    supplier_address: String;
    supplier_country: String;
    supplier_email: String;
    supplier_postal_code: String;
    supplier_other_info: String;
    supplier_phone: String;
    supplier_province: String;
    createdById: String;
    createdByName: String;
    supplier_ingredient: [
      {
        ingredient_id: String;
        ingredient_name: String;
        ingredient_type: String;
        data_type: String;
        unit_size: Number;
        unit_type: String;
        ean_barcode: String;
      }
    ];
  };
}

const initialState: SupplierState = {
  supplier: [],
  supplierDetails: {
    _id: "",
    supplier_name: "",
    supplier_address: "",
    supplier_country: "",
    supplier_email: "",
    supplier_postal_code: "",
    supplier_other_info: "",
    supplier_phone: "",
    supplier_province: "",
    createdById: "",
    createdByName: "",
    supplier_ingredient: [
      {
        ingredient_id: "",
        ingredient_name: "",
        ingredient_type: "",
        data_type: "",
        unit_size: 0,
        unit_type: "",
        ean_barcode: "",
      },
    ],
  },
};

export const auth = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    RESET: () => initialState,
    GET_SUPPLIER_DATA: (state, action) => {
      state.supplier = action.payload;
    },
    SET_SINGLE_SUPPLIER_DATA: (state, action) => {
      state.supplierDetails = action.payload;
    },
  },
});

export const { RESET, GET_SUPPLIER_DATA, SET_SINGLE_SUPPLIER_DATA } =
  auth.actions;
export default auth.reducer;
