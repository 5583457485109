import { useCallback, useContext, useMemo, useState } from "react";
import {
  Dimmer,
  Loader,
  Image,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Grid,
} from "semantic-ui-react";
import { useReactFlow } from "reactflow";

import { DrawingContext } from "../../../DrawingTool";
import { CustomButton, InputText, TitleView } from "../../../../../components";
import PackagingMethod from "./PackagingMethod";
import Packaging from "./Packaging";
import {
  useDeleteAllPackaging,
  useGetPackagingData,
  useGetPackagingTypes,
  useUpdatePackagingDetails,
} from "../../../../../api/processGate/packaging";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import { images } from "../../../../../assets/images";
import { useForm } from "react-hook-form";

export const ProcessGatePackagingBase = ({ modalData }: any) => {
  const { packagingId, processGateId, nodeItem } = modalData;

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  const { height } = useWindowDimensions();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [clickedPackagingUnit, setClickedPackagingUnit] = useState("baseUnit");
  const { deleteElements } = useReactFlow();

  //get packaging types
  const {
    data: packagingTypeData,
    status: packagingTypeLoad,
    fetchStatus: packagingFetch,
  } = useGetPackagingTypes();

  //get packaging data by id
  const { data, status, fetchStatus, refetch } =
    useGetPackagingData(packagingId);

  const updatePackagingName = (data: any) => {
    const dataOfPackageDetails = {
      data: {
        packagingName: data.packagingName,
      },
      processGateId,
      packagingId,
    };
    mutatePackingDetails(dataOfPackageDetails, {
      onSuccess: (data) => {
        if (packagingId) {
          refetch(packagingId);
        }
        savePackingNodeData(data._id, data.packagingName);
      },
    });
  };

  const { isLoading: isLoadingPackageDetails, mutate: mutatePackingDetails } =
    useUpdatePackagingDetails();

  //delete all packaging data
  const { isLoading, mutate } = useDeleteAllPackaging();

  //get packaging data
  const getPackageData = useCallback(
    (param: any) => {
      return data?.[param] || [];
    },
    [data]
  );

  const updatePackingDetails = (value: any, type: string) => {
    const packageDetails = {
      processGateId,
      packagingId,
      packagingType: type,
      unitSize: data?.unitSize || 0,
      numberOfBaseUnits: data?.numberOfBaseUnits || 0,
      numberOfPacks: data?.numberOfPacks || 0,
      numberOfCases: data?.numberOfCases || 0,
      palletWidth: data?.palletWidth || 0,
      palletLength: data?.palletLength || 0,
    };
    if (type === "numberOfCases") {
      const dataOfPackageDetails = {
        data: {
          ...packageDetails,
          [type]: value?.packageUnitValue,
          palletWidth: value?.palletWidth,
          palletLength: value?.palletLength,
        },
        packagingType: type,
        processGateId,
        packagingId,
      };
      mutatePackingDetails(dataOfPackageDetails, {
        onSuccess: (data) => {
          if (packagingId) {
            refetch(packagingId);
            return;
          }
          savePackingNodeData(data._id);
        },
      });
    } else {
      const dataOfPackageDetails = {
        data: {
          ...packageDetails,
          [type]: value,
        },
        packagingType: type,
        processGateId,
        packagingId,
      };
      mutatePackingDetails(dataOfPackageDetails, {
        onSuccess: (data) => {
          if (packagingId) {
            refetch(packagingId);
            return;
          }
          savePackingNodeData(data._id);
        },
      });
    }
  };

  //save transport node data
  const savePackingNodeData = (id: any, packagingName?: string) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        ...(packagingName && { label: `Packaging Details (${packagingName})` }),
        reference: {
          packagingId: id,
        },
        description: `Packaging Details`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing(chartEdges, updatedChartNodes);
  };

  //delete node data
  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const values = useMemo(() => {
    return {
      packagingName: data?.packagingName,
    };
  }, [nodeItem, data, processGateId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //handle loading
  if (
    (status == "loading" && fetchStatus == "fetching") ||
    (packagingTypeLoad == "loading" && packagingFetch == "fetching") ||
    isLoading ||
    isLoadingPackageDetails
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <div
        style={{
          height: height * 0.9 - 100,
          overflowX: "hidden",
        }}
      >
        <Accordion>
          <AccordionTitle
            active={activeIndex === 1}
            index={1}
            onClick={() => {
              setActiveIndex(activeIndex === 1 ? 0 : 1);
            }}
          >
            <TitleView title="Please add information about how you package products at each level following the GS1 guidelines. Use this image as a reference." />
          </AccordionTitle>
          <AccordionContent active={activeIndex === 1}>
            <Image src={images.PackingProcess} />
          </AccordionContent>
        </Accordion>
        <TitleView title="Packaging Details" />
        <form onSubmit={handleSubmit(updatePackagingName)}>
          <Grid>
            <Grid.Column computer={12} tablet={12} mobile={12}>
              <InputText
                register={register}
                labelName="Packaging Name"
                name="packagingName"
                errors={errors.packagingName}
                required
                errorMessage="Packaging Name is required"
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={4} mobile={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CustomButton theme="green" title={"Update"} type="submit" />
              </div>
            </Grid.Column>
          </Grid>
        </form>
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="single-unit"
          processGateId={processGateId}
          unitSizes={data?.unitSize}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "unitSize")
          }
          totalWeightOfItem={data?.totalWeightBaseUnit}
          packagingData={getPackageData("singleUnitPackaging") || []}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="sales-unit"
          unitSizes={data?.numberOfBaseUnits}
          totalWeightOfItem={data?.totalWeightSalesOfPack}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfBaseUnits")
          }
          processGateId={processGateId}
          packagingData={getPackageData("salesUnitPackaging") || []}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="bulk-unit"
          unitSizes={data?.numberOfPacks}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfPacks")
          }
          totalWeightOfItem={data?.totalWeightBulkPack}
          processGateId={processGateId}
          packagingData={getPackageData("bulkUnitPackaging") || []}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <Packaging
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="transport-unit"
          processGateId={processGateId}
          unitSizes={data?.numberOfCases}
          palletWidth={data?.palletWidth}
          palletLength={data?.palletLength}
          changeUnits={(value: number) =>
            updatePackingDetails(value, "numberOfCases")
          }
          totalWeightOfItem={data?.totalWeightTransportUnitPack}
          packagingData={getPackageData("transportUnitPackaging") || []}
          savePackingNodeData={savePackingNodeData}
          packagingTypeData={packagingTypeData}
          setIsSaveBtnClicked={setIsSaveBtnClicked}
          isSaveBtnClicked={isSaveBtnClicked}
          setClickedPackagingUnit={setClickedPackagingUnit}
          clickedPackagingUnit={clickedPackagingUnit}
        />
        <PackagingMethod
          packagingId={packagingId}
          packagingName={data?.packagingName}
          refetchData={refetch}
          packagingType="transport-unit"
          processGateId={processGateId}
          packagingData={getPackageData("packagingMethod")}
          savePackingNodeData={savePackingNodeData}
        />
      </div>
      <MainBottomButtonView
        cancelStatus={true}
        deleteStatus={packagingId}
        deleteButton={() => setVisibleDeleteModal(true)}
        cancelButton={() => setNodeItem(null)}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            packagingId,
            processGateId,
          };
          mutate(dataToDelete, {
            onSuccess(data) {
              onNodesDelete();
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete Process gate packaging`}
        subTitle="Are you sure you want to remove the Process gate packaging? Please consider you cannot recover these data after remove"
      />
    </div>
  );
};
