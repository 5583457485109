import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser {
  _id: string;
  name: string;
  email: string;
  photo: string;
  role: string;
  provider?: string;
  active?: boolean;
  verified?: boolean;
  createdAt: Date;
  updatedAt: Date;
  tenant_id: string;
  tenant_name: string;
  token: string;
}

interface AuthState {
  user?: IUser | null;
  token?: any;
}

const initialState: AuthState = {
  user: null,
  token: null,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOG_OUT: () => initialState,
    SET_USER_DATA: (state, action: PayloadAction<AuthState>) => {
      state.user = action.payload.user;
    },
    AUTH_TOKEN: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload;
    },
  },
});

export const { LOG_OUT, SET_USER_DATA, AUTH_TOKEN } = auth.actions;
export default auth.reducer;
