import { MdErrorOutline } from "react-icons/md";

import "./EmphasizeText.scss";

export const EmphasizeText = ({ children }: any) => {
  return (
    <div className="mainCont">
      <MdErrorOutline size={20} />
      <p className="textP">{children}</p>
    </div>
  );
};
