import React from "react";

import CultivationList from "../screens/cultivation/cultivationList/CultivationList";
import Cultivar from "../screens/cultivation/cultivar/Cultivar";
import Field from "../screens/cultivation/field/Field";
import CultivationProcessing from "../screens/cultivation/processing/Processing";
import CultivationPackaging from "../screens/cultivation/packing/Packing";
import CultivationEnergy from "../screens/cultivation/energy/Energy";
import CultivationStorage from "../screens/cultivation/storage/Storage";
import CultivationTransportation from "../screens/cultivation/transportation/Transportation";
import CultivationConfirmation from "../screens/cultivation/confirmation/Confirmation";
import { adminAccess, supplierAccess, userAccess } from "./useRoleAccess";

export const CultivationRoutes = [
  {
    path: "/cultivation",
    route: <CultivationList />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess, ...supplierAccess],
  },
  {
    path: "cultivar/:id",
    route: <Cultivar />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "field/:id",
    route: <Field />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "processing/:id",
    route: <CultivationProcessing />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "packaging/:id",
    route: <CultivationPackaging />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "energy/:id",
    route: <CultivationEnergy />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "storage/:id",
    route: <CultivationStorage />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "transportation/:id",
    route: <CultivationTransportation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
  {
    path: "confirmation/:id",
    route: <CultivationConfirmation />,
    protectRoutes: true,
    roleGrantAccess: [...adminAccess, ...userAccess],
  },
];
