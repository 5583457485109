import { Grid } from "semantic-ui-react";

import { CustomButton } from "../button/Button";
import { CommonContainer } from "../commonContainer/commonContainer";

import "./itemAdder.scss";

export const ItemAdderTitleView = ({
  computerMainView = 12,
  tabletMainView = 16,
  mobileMainView = 16,
  computerMain = 4,
  tabletMain = 16,
  mobileMain = 16,
  customItemAdderGridMain,
  customItemAdderGridWithInputs,
  titleData = [],
  isDrawing
}: any) => {
  return (
    <CommonContainer styles="adder">
      <Grid className={`itemAdderGridMainTitle ${customItemAdderGridMain}`}>
        <Grid.Column
          computer={computerMainView}
          tablet={tabletMainView}
          mobile={mobileMainView}
          className={`itemAdderGridWithInputsTitle ${customItemAdderGridWithInputs}`}
        >
          <Grid>
            {titleData.map((data: any, index: any) => {
              return (
                <Grid.Column
                  computer={data?.computer || 16}
                  tablet={data?.tablet || 16}
                  mobile={data?.mobile || 16}
                  className={
                    index == 0
                      ? isDrawing ? "paddingRemoveVertical" : "paddingRemoveLeft paddingRemoveVertical"
                      : "paddingRemoveVertical"
                  }
                >
                  <p>{data.title}</p>
                </Grid.Column>
              );
            })}
          </Grid>
        </Grid.Column>
        <Grid.Column
          computer={computerMain}
          tablet={tabletMain}
          mobile={mobileMain}
          className="itemButtonMainView"
        ></Grid.Column>
      </Grid>
    </CommonContainer>
  );
};
